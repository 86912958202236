import { endOfYesterday, startOfYesterday } from 'date-fns';
import { useEffect, useState } from 'react';

import AddressGroupSelection from '../../../../../common/components/Filter/Selection/AddressGroupSelection';
import NetworkSelection from '../../../../../common/components/Filter/Selection/NetworkSelection';
import TokenSelection from '../../../../../common/components/Filter/Selection/TokenSelection';
import { MpTextField } from '../../../../../components';
import { useDatePicker } from '../../../../../components/DatePicker';
import { useTranslation } from '../../../../../hooks';
import useGetSerachParams from '../../../../../hooks/useGetSerachParams';
import { selectAddressGroup } from '../../../../../reducer/addressGroupSlice';
import { selectAsset } from '../../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../../reducer/hooks';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';
import { FilterFieldsState } from '../types';

function useFilter() {
  const { searchParams, clearSearchParams } = useGetSerachParams(['address']);

  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);
  const { addressGroupNameMapping, addressGroupIdMapping } = useAppSelector(selectAddressGroup);

  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const dateConfig = {
    start: { defaultDate: startOfYesterday() },
    end: { defaultDate: endOfYesterday() },
  };

  const DateFields = {
    Date: useDatePicker(dateConfig),
  };

  const initialFields = {
    chainCode: '',
    // chainCodes: [],
    displayName: '',
    address: '',
    addressGroupNames: [],
    tokens: [],
    dateFrom: DateFields.Date.start,
    dateTo: DateFields.Date.end,
  };

  const [fields, setFields] = useState<FilterFieldsState>({
    ...initialFields,
    address: searchParams.address,
  });

  useEffect(() => {
    setFields((prev) => ({
      ...prev,
      dateFrom: DateFields.Date.start,
      dateTo: DateFields.Date.end,
    }));
  }, [DateFields.Date.start, DateFields.Date.end]);

  const fieldArr = [
    {
      name: t(TRANSLATE_KEY.displayName),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.displayName) })}
          value={fields.displayName}
          onChange={(e) => setFields((prev) => ({ ...prev, displayName: e.target.value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.address),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.address) })}
          value={fields.address}
          onChange={(e) => setFields((prev) => ({ ...prev, address: e.target.value }))}
        />
      ),
    },

    {
      name: t(TRANSLATE_KEY.network),
      // name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.network) }),
      component: (
        <NetworkSelection
          mode={'single'}
          value={fields.chainCode}
          onChange={(chainCode) => {
            setFields((prev) => ({ ...prev, chainCode, tokens: [] }));
          }}
          clearSelect={() => {
            setFields((prev) => ({ ...prev, chainCode: '' }));
          }}
        />
        // <GeneralSelection
        //   mode={'multiple'}
        //   label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.network) })}
        //   value={fields.chainCodes}
        //   onChange={(chainCodeArr) => {
        //     setFields((prev) => ({ ...prev, chainCodes: chainCodeArr }));
        //   }}
        //   enumData={clientSupportedAssetChainCodeMapping}
        //   isSearchable
        //   nameFn={(name) => CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING[name as ChainCode]}
        //   isNoSorting
        // />
      ),
    },
    {
      name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.token) }),
      component: (
        <TokenSelection
          mode={'multiple'}
          chainCode={fields.chainCode}
          value={fields.tokens}
          onChange={(tokens) => {
            setFields((prev) => ({ ...prev, tokens, assetBalanceAmount: '' }));
          }}
        />
      ),
    },
    {
      name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.addressGroup) }),
      component: (
        <AddressGroupSelection
          mode={'multiple'}
          value={fields.addressGroupNames}
          onChange={(value) => setFields((prev) => ({ ...prev, addressGroupNames: value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.date),
      component: <DateFields.Date.Picker type="date" />,
    },
  ];

  const onResetFields = () => {
    setFields(initialFields);
    DateFields.Date.resetDate(); // For backend performance
    clearSearchParams();
  };

  return {
    fieldArr,
    fields,
    onResetFields,
  };
}

export default useFilter;

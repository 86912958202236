import { Dispatch, SetStateAction } from 'react';

import { MpTextField } from '../../../../../../components';
import { useTranslation } from '../../../../../../hooks';
import { DEFAULT_COLOR_CODE, DEFAULT_SHAPE, TRANSLATE_PREFIX } from '../../../constants';
import { getAddressGroupIcon } from '../../../helpers';
import { TRANSLATE_KEY } from '../constants';
import ColorPicker from './ColorPicker';
import ShapePicker from './ShapePicker';

export const initialAddressGroupData = {
  name: '',
  shape: DEFAULT_SHAPE,
  colorCode: DEFAULT_COLOR_CODE,
};

function Content<T extends { name: string; shape: string; colorCode: string }>({
  addressGroupData,
  setAddressGroupData,
}: {
  addressGroupData: T;
  setAddressGroupData: Dispatch<SetStateAction<T>>;
}) {
  const { name, shape, colorCode } = addressGroupData;
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const addressGroupIcon = getAddressGroupIcon({
    shape,
    colorCode,
    style: { width: '24px', height: '24px' },
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          border: '1px solid #474747',
          backgroundColor: '#000000',
        }}
      >
        <div style={{ paddingLeft: '12px', display: 'flex', alignItems: 'center' }}>
          {addressGroupIcon}
        </div>
        <MpTextField
          sx={{ width: '100%', '.MuiInputBase-root': { border: 'initial', color: '#ffffff' } }}
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.groupName) })}
          maxLength={30}
          value={name}
          onChange={(e) => {
            const input = e.target.value;

            const isAcceptable = /^[a-z0-9_]*$/.test(input);

            if (!isAcceptable) {
              return;
            }

            setAddressGroupData((prev) => ({ ...prev, name: input }));
          }}
        />
      </div>

      <ShapePicker
        selectedShape={shape}
        onSelectShape={(shape) => setAddressGroupData((prev) => ({ ...prev, shape }))}
      />
      <ColorPicker
        selectedColorCode={colorCode}
        onSelectColorCode={(colorCode) => setAddressGroupData((prev) => ({ ...prev, colorCode }))}
      />
    </div>
  );
}

export default Content;

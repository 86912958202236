import './index.css';

import React from 'react';

import EyeClosedIconSrc from '../../../../../assets/icons/eye-closed.png';
import EyeIconSrc from '../../../../../assets/icons/eye-open.png';

interface IProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: 'text' | 'password' | 'one-time-code';
  iconSrc?: string;
  numberOnly?: boolean;
  digitLimit?: number;
  noIcon?: boolean;
  isValid?: boolean;
  hide?: boolean;
  autoComplete?: string;
  showEmailKeyboard?: boolean;
  showDecimalKeyboard?: boolean;
  showNumericKeyboard?: boolean;
  disabled?: boolean;
}

const LoginInput: React.FC<IProps> = (props: IProps) => {
  const {
    label,
    value,
    onChange,
    type = 'text',
    iconSrc,
    numberOnly,
    digitLimit = 6,
    noIcon,
    isValid,
    autoComplete,
    showEmailKeyboard,
    showDecimalKeyboard,
    showNumericKeyboard,
    disabled,
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);
  const [inputType, setInputType] = React.useState(type === 'password' ? 'password' : 'text');

  const getInputMode = () => {
    if (showEmailKeyboard) {
      return 'email';
    }

    if (showDecimalKeyboard) {
      return 'decimal';
    }

    if (showNumericKeyboard) {
      return 'numeric';
    }

    return 'text';
  };

  const getPattern = () => {
    if (showDecimalKeyboard || showNumericKeyboard) {
      return '[0-9]*';
    }

    return '';
  };

  const handleShowPasswordClick = () => {
    if (inputType === 'password') {
      setInputType('text');
    }

    if (inputType === 'text') {
      setInputType('password');
    }
  };

  const handleChange = (e: any) => {
    if (numberOnly) {
      // regex that allows only 0 to {digitLimit} digits of number
      const regex = new RegExp(`^[0-9]{0,${digitLimit}}$`);

      if (!regex.test(e.target.value)) {
        return;
      }

      return onChange(e);
    }

    onChange(e);
  };

  const IconWithDivideLine = () => {
    if (noIcon) return <></>;

    return (
      <>
        <div className={`icon-container`}>
          <div className={`icon ${type === 'password' && 'password'}`}>
            {iconSrc && <img src={iconSrc} alt="icon" />}
          </div>
        </div>
        <div className={`vertical-divide-line ${isValid ? 'valid' : ''}`}></div>
      </>
    );
  };

  const inputMode = getInputMode();
  const pattern = getPattern();

  if (props.hide) return <></>;

  return (
    <div className="login-input">
      <div className={`input-row-container ${isValid ? 'valid' : ''}`}>
        <IconWithDivideLine />

        <div className="input-container">
          <label
            style={value ? { display: 'none' } : undefined}
            // className={isFocused || value ? 'label-focus' : ''}
            htmlFor={`login-input-${label}`}
          >
            {label}
          </label>
          <input
            id={`login-input-${label}`}
            type={inputType}
            value={value}
            autoComplete={autoComplete}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            inputMode={inputMode}
            pattern={pattern}
            disabled={disabled}
            style={disabled ? { opacity: 0.7 } : undefined}
          />
        </div>
        <div className="show-container">
          {type === 'password' && (
            <img
              src={inputType === 'password' ? EyeClosedIconSrc : EyeIconSrc}
              alt="eye"
              onClick={handleShowPasswordClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginInput;

import { ReactNode } from 'react';

function UnderlinedButton({
  color,
  fontSize,
  onClick,
  node,
}: {
  color: string;
  fontSize: string;
  onClick: () => void;
  node: ReactNode;
}) {
  return (
    <div
      style={{
        color,
        fontSize,
        cursor: 'pointer',
        textDecoration: 'underline',
      }}
      onClick={onClick}
    >
      {node}
    </div>
  );
}

export default UnderlinedButton;

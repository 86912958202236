import BigNumber from 'bignumber.js';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Asset,
  CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING,
  ChainCode,
  ClientSupportedAssetQueryRes,
  MerchantSupportedAssetQueryRes,
} from '../api/query/types';
import { RootState } from './store';

export type MerchantSupportedAssetListState = {
  chainType: number;
  chainId: string;
  chainCode: ChainCode;
  nativeAssetName: string;
  latestBlockNumber: string;
  latestPendingBlockNumber: string;
  assetList: Asset[];
  networkDisplayName: (typeof CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING)[ChainCode];
};

export type NetworkInfo = {
  chainId: string;
  chainType: number;
  assetNameList: string[];
};

type ChainCodeNetworkInfoMapping = Record<string, NetworkInfo>;

export type AssetState = {
  shouldShowFirstLoginPage: boolean;
  clientSupportedAssetChainCodeMapping: ChainCodeNetworkInfoMapping;
  merchantSupportedAssetList: MerchantSupportedAssetListState[];
  merchantSupportedAssetChainCodeMapping: ChainCodeNetworkInfoMapping;
};

const initialState: AssetState = {
  shouldShowFirstLoginPage: false,
  clientSupportedAssetChainCodeMapping: {},
  merchantSupportedAssetList: [],
  merchantSupportedAssetChainCodeMapping: {},
};

export const getMerchantSupportedAssetSortingComparisonFn = (
  a: MerchantSupportedAssetListState,
  b: MerchantSupportedAssetListState
) => {
  if (a.chainType === b.chainType) {
    return BigNumber(a.chainId).minus(BigNumber(b.chainId)).toNumber();
  }

  return a.chainType - b.chainType;
};

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setShouldShowFirstLoginPage: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldShowFirstLoginPage = payload;
    },
    setClientSupportedAssetChainCodeMapping: (
      state,
      { payload }: PayloadAction<ClientSupportedAssetQueryRes[]>
    ) => {
      let clientSupportedAssetChainCodeMapping: ChainCodeNetworkInfoMapping = {};

      payload.forEach(({ assetName, chainId, chainType }) => {
        const chainCode = state.merchantSupportedAssetList.find(
          (merchantAsset) =>
            merchantAsset.chainId === chainId && merchantAsset.chainType === chainType
        )?.chainCode;

        if (chainCode) {
          clientSupportedAssetChainCodeMapping[chainCode] = {
            ...clientSupportedAssetChainCodeMapping[chainCode],
            chainId,
            chainType,
            assetNameList: clientSupportedAssetChainCodeMapping[chainCode]?.assetNameList
              ? [...clientSupportedAssetChainCodeMapping[chainCode].assetNameList, assetName]
              : [assetName],
          };
        }
      });

      state.clientSupportedAssetChainCodeMapping = clientSupportedAssetChainCodeMapping;
    },
    upsertClientSupportedAssetChainCodeMapping: (
      state,
      {
        payload,
      }: PayloadAction<{
        chainId: string;
        chainType: number;
        chainCode: string;
        assetNameList: string[];
      }>
    ) => {
      if (
        !payload.assetNameList.length &&
        state.clientSupportedAssetChainCodeMapping?.[payload.chainCode]
      ) {
        delete state.clientSupportedAssetChainCodeMapping[payload.chainCode];
        return;
      }

      state.clientSupportedAssetChainCodeMapping = {
        ...state.clientSupportedAssetChainCodeMapping,
        [payload.chainCode]: {
          ...state.clientSupportedAssetChainCodeMapping?.[payload.chainCode],
          chainId: payload.chainId,
          chainType: payload.chainType,
          assetNameList: payload.assetNameList,
        },
      };
    },
    setMerchantSupportedAssetListAndChainCodeMapping: (
      state,
      { payload }: PayloadAction<MerchantSupportedAssetQueryRes[]>
    ) => {
      const transformedArr =
        payload?.map(({ id, createdDate, lastModifiedDate, asset: assetList, ...rest }) => ({
          ...rest,
          assetList,
          networkDisplayName: CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING[rest.chainCode],
        })) || [];

      const sortedTransformedArr = transformedArr.sort(
        getMerchantSupportedAssetSortingComparisonFn
      );

      state.merchantSupportedAssetList = sortedTransformedArr;
      state.merchantSupportedAssetChainCodeMapping = sortedTransformedArr.reduce(
        (acc: Record<string, NetworkInfo>, { chainCode, assetList, chainId, chainType }) => {
          return {
            ...acc,
            [chainCode]: {
              assetNameList: assetList.map(({ assetName }) => assetName),
              chainId,
              chainType,
            },
          };
        },
        {}
      );
    },
  },
});

export default assetSlice.reducer;

export const selectAsset = (state: RootState) => state.asset;

export const {
  setShouldShowFirstLoginPage,
  setClientSupportedAssetChainCodeMapping,
  upsertClientSupportedAssetChainCodeMapping,
  setMerchantSupportedAssetListAndChainCodeMapping,
} = assetSlice.actions;

import '../../styles/index.css';

import { Dispatch, SetStateAction } from 'react';

import ApplyButton from './components/ApplyButton';
import ClearButton from './components/ClearButton';
import CollapseButton from './components/CollapseButton';

function ButtonGroup({
  isFiltering,
  isCollapsed,
  isAnimationEnabled,
  setIsCollapsed,
  onApply,
  onClear,
}: {
  isFiltering: boolean;
  isCollapsed: boolean;
  isAnimationEnabled: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  onApply: () => void;
  onClear: () => void;
}) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '16px',
      }}
    >
      <CollapseButton
        isFiltering={isFiltering}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />

      <div
        style={{ display: isCollapsed ? 'none' : 'flex', gap: '12px' }}
        className={
          isAnimationEnabled
            ? `filter-action-btn-container ${isCollapsed ? 'collapsed' : 'expanded'}`
            : undefined
        }
      >
        <ApplyButton onApply={onApply} />
        <ClearButton onClear={onClear} />
      </div>
    </div>
  );
}

export default ButtonGroup;

import { GridRenderCellParams } from '@mui/x-data-grid';
import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import { AddressListGetAllRes } from '../../../../api/addressManagement/addressList/types';
import { ReactComponent as CoinIcon } from '../../../../assets/icons/AddressListOperation/coins.svg';
import { ReactComponent as SettingIcon } from '../../../../assets/icons/AddressListOperation/settings.svg';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/common/documents.svg';
import AddressGroupCell from '../../../../common/components/Table/AddressGroupCell';
import { Tooltip } from '../../../../components/MuiGenerals';
import { usePermission, useTranslation } from '../../../../hooks';
import { useGenTableColumns } from '../../../../hooks/useGenTableColumns';
import useGetNetworkInfo from '../../../../hooks/useGetNetworkInfo';
import { selectAddressGroup } from '../../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { AddOrEditAddressFieldState } from '../components/Dialogs/components/Content';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function useTableColumns({
  onEditAddress,
}: {
  onEditAddress: (editDialogData: AddOrEditAddressFieldState) => void;
}) {
  const { hasPermission } = usePermission();
  const { addressGroupIdMapping } = useAppSelector(selectAddressGroup);
  const { getChainCode } = useGetNetworkInfo();
  const { t } = useTranslation(TRANSLATE_PREFIX);

  const renderOperationCell = (
    params: GridRenderCellParams<any, { rawData: AddressListGetAllRes }, any>
  ) => {
    const { walletName, walletAddress, walletGroupId, chainId, chainType } = params.row.rawData;

    const addressGroupName = addressGroupIdMapping[walletGroupId]?.name;

    const chainCode = getChainCode({ chainId, chainType });

    const onReportClick = () => {
      window.open(`/report?address=${walletAddress}`);
    };
    const onAddressBalanceClick = () => {
      window.open(`/addressBalance?address=${walletAddress}`);
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          gap: '12px',
        }}
      >
        {hasPermission(InspectionWalletFeatureCode.AddressManagement.AddressList.EditAddress) && (
          <Tooltip
            children={
              <SettingIcon
                width={24}
                height={24}
                className="operation-btn"
                onClick={() =>
                  onEditAddress({
                    displayName: walletName,
                    address: walletAddress,
                    addressGroupName,
                    chainCode,
                  })
                }
              />
            }
            title={t(TRANSLATE_KEY.editAddress)}
          />
        )}
        {hasPermission(InspectionWalletFeatureCode.AddressManagement.AddressBalance.prefix) && (
          <Tooltip
            children={
              <CoinIcon
                width={24}
                height={21}
                className="operation-btn"
                onClick={onAddressBalanceClick}
              />
            }
            title={t(TRANSLATE_KEY.addressBalance)}
          />
        )}
        {hasPermission(InspectionWalletFeatureCode.Report.Report.prefix) && (
          <Tooltip
            children={
              <DocumentIcon
                width={24}
                height={21}
                className="operation-btn"
                onClick={onReportClick}
              />
            }
            title={t(TRANSLATE_KEY.report)}
          />
        )}
      </div>
    );
  };
  const renderAddressGroupCell = (
    params: GridRenderCellParams<any, { rawData: AddressListGetAllRes }, any>
  ) => {
    const { walletGroupId } = params.row.rawData;

    return <AddressGroupCell id={walletGroupId} />;
  };

  const { columns } = useGenTableColumns({
    translatePrefix: TRANSLATE_PREFIX,
    columnConfigArr: [
      {
        translateKey: TRANSLATE_KEY.operation,
        renderCell: renderOperationCell,
        maxWidth: 130,
        minWidth: 130,
        permissionKey: [
          InspectionWalletFeatureCode.AddressManagement.AddressList.EditAddress,
          InspectionWalletFeatureCode.AddressManagement.AddressBalance.prefix,
          InspectionWalletFeatureCode.Report.Report.prefix,
        ],
      },
      { translateKey: TRANSLATE_KEY.displayName, minWidth: 150 },
      {
        translateKey: TRANSLATE_KEY.addressGroup,
        minWidth: 130,
        renderCell: renderAddressGroupCell,
      },
      { translateKey: TRANSLATE_KEY.address, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.network, minWidth: 130 },
      { translateKey: TRANSLATE_KEY.addAddressDate, minWidth: 150 },
    ],
  });

  return { columns };
}

export default useTableColumns;

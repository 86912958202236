import { GridRenderCellParams } from '@mui/x-data-grid';
import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import { AddressBalanceGetAllRes } from '../../../../api/addressManagement/addressBalance/types';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/common/documents.svg';
import AddressGroupCell from '../../../../common/components/Table/AddressGroupCell';
import { Tooltip } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import {
  renderColorText,
  useGenTableColumns
} from '../../../../hooks/useGenTableColumns';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function useTableColumns() {
  const { t } = useTranslation(TRANSLATE_PREFIX);

  const renderOperationCell = (
    params: GridRenderCellParams<any, { rawData: AddressBalanceGetAllRes }, any>
  ) => {
    const address = params.row.rawData.walletAddress;

    const onClick = () => {
      window.open(`/report?address=${address}`);
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Tooltip
          children={
            <DocumentIcon width={24} height={21} className="operation-btn" onClick={onClick} />
          }
          title={t(TRANSLATE_KEY.report)}
        />
      </div>
    );
  };
  const renderAddressGroupCell = (
    params: GridRenderCellParams<any, { rawData: AddressBalanceGetAllRes }, any>
  ) => {
    const { inspectionWallet } = params.row.rawData;

    return <AddressGroupCell id={inspectionWallet.walletGroupId} />;
  };

  const { columns } = useGenTableColumns({
    translatePrefix: TRANSLATE_PREFIX,
    columnConfigArr: [
      {
        translateKey: TRANSLATE_KEY.operation,
        renderCell: renderOperationCell,
        maxWidth: 100,
        permissionKey: InspectionWalletFeatureCode.Report.Report.prefix,
      },
      { translateKey: TRANSLATE_KEY.displayName, minWidth: 150 },
      {
        translateKey: TRANSLATE_KEY.addressGroup,
        minWidth: 130,
        renderCell: renderAddressGroupCell,
      },
      { translateKey: TRANSLATE_KEY.network, minWidth: 130 },
      { translateKey: TRANSLATE_KEY.token, minWidth: 130 },
      { translateKey: TRANSLATE_KEY.address, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.addressBalance, minWidth: 200 },
      {
        translateKey: TRANSLATE_KEY.latestCheckpointId,
        minWidth: 200,
        renderCell: renderColorText('#FFD100'),
      },
      { translateKey: TRANSLATE_KEY.latestCheckedBlockNumber, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.certifiedWithOnChainBalance, minWidth: 200 },
      {
        translateKey: TRANSLATE_KEY.lastCertifiedCheckpointId,
        minWidth: 200,
        renderCell: renderColorText('#FFD100'),
      },
      { translateKey: TRANSLATE_KEY.creationTime, minWidth: 150 },
      { translateKey: TRANSLATE_KEY.lastModifiedTime, minWidth: 150 },
      { translateKey: TRANSLATE_KEY.settlementTime, minWidth: 150 },
    ],
  });

  return { columns };
}

export default useTableColumns;

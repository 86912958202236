import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { importantStyle } from '../utils';
// import Button from "@mui/material/Button";
import { CheckIsCallingApiButton } from './Button';

const CustomTooltip = ({
  distanceFromAnchor = 2,
  ...props
}: TooltipProps & { distanceFromAnchor?: number }) => {
  return (
    <Tooltip
      {...props}
      slotProps={{
        popper: {
          sx: {
            '& .MuiTooltip-tooltip': {
              marginTop:
                !props.placement || props.placement?.includes('bottom')
                  ? importantStyle(0)
                  : undefined,
            },
          },
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, distanceFromAnchor],
              },
            },
          ],
        },
      }}
    />
  );
};

export {
  Box,
  CheckIsCallingApiButton as Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  CustomTooltip as Tooltip,
  ClearIcon,
  Tabs,
  Tab,
  Modal,
};
export type { SelectChangeEvent };

import { GridRenderCellParams } from '@mui/x-data-grid';
import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import { AddressBalanceGetAllRes } from '../../../../api/addressManagement/addressBalance/types';
import searchIcon from '../../../../assets/icons/TransactionDetails/search.png';
import AddressGroupCell from '../../../../common/components/Table/AddressGroupCell';
import { Tooltip } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import {
  renderColorText,
  useGenTableColumns
} from '../../../../hooks/useGenTableColumns';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function useTableColumns({ onDetailsClick }: { onDetailsClick: (id: string) => void }) {
  const { t } = useTranslation(TRANSLATE_PREFIX);

  const renderDetailsCell = (
    params: GridRenderCellParams<any, { rawData: AddressBalanceGetAllRes }, any>
  ) => {
    const id = params.row.rawData.id;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Tooltip
          children={
            <img
              src={searchIcon}
              alt="search"
              style={{ userSelect: 'none' }}
              className="operation-btn"
              onClick={() => onDetailsClick(id)}
            />
          }
          title={t(TRANSLATE_KEY.details)}
        />
      </div>
    );
  };
  const renderAddressGroupCell = (
    params: GridRenderCellParams<any, { rawData: AddressBalanceGetAllRes }, any>
  ) => {
    const { inspectionWallet } = params.row.rawData;

    return <AddressGroupCell id={inspectionWallet.walletGroupId} />;
  };

  const { columns } = useGenTableColumns({
    translatePrefix: TRANSLATE_PREFIX,
    columnConfigArr: [
      {
        translateKey: TRANSLATE_KEY.details,
        renderCell: renderDetailsCell,
        maxWidth: 100,
        permissionKey: InspectionWalletFeatureCode.Report.Transaction.Details,
      },
      { translateKey: TRANSLATE_KEY.transactionTime, minWidth: 150 },
      { translateKey: TRANSLATE_KEY.status, minWidth: 150 },
      { translateKey: TRANSLATE_KEY.displayName, minWidth: 150 },
      {
        translateKey: TRANSLATE_KEY.addressGroup,
        minWidth: 130,
        renderCell: renderAddressGroupCell,
      },
      { translateKey: TRANSLATE_KEY.address, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.network, minWidth: 130 },
      { translateKey: TRANSLATE_KEY.token, minWidth: 130 },
      { translateKey: TRANSLATE_KEY.direction, minWidth: 130 },
      { translateKey: TRANSLATE_KEY.amount, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.riskLevel, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.sendingAddress, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.receivingAddress, minWidth: 200 },
      {
        translateKey: TRANSLATE_KEY.minerFee,
        minWidth: 200,
      },
      { translateKey: TRANSLATE_KEY.postBalance, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.feeRecord, minWidth: 200 },
      {
        translateKey: TRANSLATE_KEY.checkpointId,
        minWidth: 200,
        renderCell: renderColorText('#FFD100'),
      },
      {
        translateKey: TRANSLATE_KEY.certifiedWithOnChainBalance,
        minWidth: 200,
      },
      {
        translateKey: TRANSLATE_KEY.transactionHash,
        minWidth: 200,
      },
      { translateKey: TRANSLATE_KEY.lastModifiedTime, minWidth: 150 },
      { translateKey: TRANSLATE_KEY.creationTime, minWidth: 150 },
      { translateKey: TRANSLATE_KEY.settlementTime, minWidth: 150 },
    ],
  });

  return { columns };
}

export default useTableColumns;

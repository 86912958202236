import { uniqueId } from 'lodash';
import { useState } from 'react';

function useRefreshPage() {
  const [refreshDependency, setRefreshDependency] = useState(uniqueId(new Date().toISOString()));

  const getNewRefreshDependency = () => {
    setRefreshDependency(uniqueId(new Date().toISOString()));
  };

  return { refreshDependency, getNewRefreshDependency };
}

export default useRefreshPage;

import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import { PAGE_SIZE } from '../../../constants/config';
import axios from '../../axiosInstance';
import { ApiConfig, IlistResult } from '../../types';
import { TransactionDetailsRes, TransactionGetAllRes } from './types';

const { transaction } = EPt;

const getAll = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: {
      ...rest,
      offset: page * pageSize,
      limit: pageSize,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<TransactionGetAllRes> | undefined>(
    transaction.portalGetAll,
    input
  );
};

const getExport = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: {
      ...rest,
      offset: page * pageSize,
      limit: pageSize,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<TransactionGetAllRes> | undefined>(
    transaction.portalExport,
    input
  );
};

const getDetails = async (params: { id: string }, config?: ApiConfig) => {
  const input = {
    params,
    ...config,
  };
  return await axios.get<any, TransactionDetailsRes | undefined>(transaction.details, input);
};

export default { getAll, getExport, getDetails };

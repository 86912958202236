import { selectAsset } from '../reducer/assetSlice';
import { useAppSelector } from '../reducer/hooks';

function useGetNetworkInfo() {
  const { merchantSupportedAssetList, merchantSupportedAssetChainCodeMapping } =
    useAppSelector(selectAsset);
  const getNetworkDisplayName = ({
    chainId,
    chainType,
  }: {
    chainId: string;
    chainType: number;
  }) => {
    return (
      merchantSupportedAssetList.find(
        (merchantSupportedAsset) =>
          merchantSupportedAsset.chainId === chainId &&
          merchantSupportedAsset.chainType === chainType
      )?.networkDisplayName || ''
    );
  };
  const getChainCode = ({ chainId, chainType }: { chainId: string; chainType: number }) => {
    return (
      merchantSupportedAssetList.find(
        (merchantSupportedAsset) =>
          merchantSupportedAsset.chainId === chainId &&
          merchantSupportedAsset.chainType === chainType
      )?.chainCode || ''
    );
  };

  const getAssetDecimal = ({
    chainId,
    chainType,
    assetName,
  }: {
    chainId: string;
    chainType: number;
    assetName: string;
  }) => {
    return (
      merchantSupportedAssetList
        .find(
          (merchantSupportedAsset) =>
            merchantSupportedAsset.chainId === chainId &&
            merchantSupportedAsset.chainType === chainType
        )
        ?.assetList.find((asset) => asset.assetName === assetName)?.decimals || 0
    );
  };

  return {
    getNetworkDisplayName,
    getChainCode,
    getAssetDecimal,
    merchantSupportedAssetChainCodeMapping,
    merchantSupportedAssetList,
  };
}

export default useGetNetworkInfo;

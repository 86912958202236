export enum ReportTab {
  Daily = 'Daily',
  CustomPeriodic = 'CustomPeriodic',
  Summary = 'Summary',
}

export type ExportBtnConfig = {
  onClick: () => void;
  permission: string;
};

import { CSSProperties, ReactNode } from 'react';

import { Breakpoint } from '@mui/system';

import { importantStyle } from '../../utils';
import { Dialog, DialogContent, DialogTitle } from '../MuiGenerals';
import ActionSection from './components/ActionSection';
import CrossButton from './components/CrossButton';

export interface IdialogInOneProps {
  title: string;
  customTitle?: any;
  self: {
    open: boolean;
    onClose: any;
  };
  content: ReactNode | string;
  onConfirm: () => void;
  onCancel: () => void;
  isConfirmHidden?: boolean;
  isCancelHidden?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  actionButtons?: ReactNode;
  isLoadingDialog?: boolean;
  size?: Breakpoint;
  isReversedActionSection?: boolean;
  isFullScreen?: boolean;
  isShowCrossButton?: boolean;
  actionBtnContainerStyling?: CSSProperties;
  dialogHeight?: {
    height?: string;
    maxHeight?: string;
  };
  dialogWidth?: {
    width?: string;
    maxWidth?: string;
  };
  maxContentHeight?: string;
  isBlackBackdrop?: boolean;
  isTitleCentered?: boolean;
  rest?: any;
}

export default function DialogInOne(props: IdialogInOneProps) {
  const {
    size = 'md',
    self,
    title,
    customTitle,
    content,
    onConfirm,
    onCancel,
    isConfirmHidden,
    isCancelHidden,
    cancelButtonText,
    confirmButtonText,
    actionButtons,
    rest,
    isLoadingDialog,
    isReversedActionSection,
    isFullScreen,
    isShowCrossButton,
    actionBtnContainerStyling,
    dialogHeight,
    dialogWidth,
    maxContentHeight,
    isBlackBackdrop,
    isTitleCentered,
  } = props;

  return (
    <Dialog
      open={self.open}
      onClose={self.onClose}
      fullWidth={true}
      maxWidth={size}
      PaperProps={{
        ...(rest?.PaperProps || {}),
        sx: {
          backgroundColor: '#222222',
          height: dialogHeight?.height || 'auto',
          width: dialogWidth?.width,
          maxHeight: dialogHeight?.maxHeight,
          maxWidth: dialogWidth?.maxWidth,
          ...(isFullScreen && {
            width: importantStyle('100dvw'),
            maxWidth: importantStyle('100dvw'),
            minWidth: importantStyle('100dvw'),
            height: importantStyle('100dvh'),
            maxHeight: importantStyle('100dvh'),
            minHeight: importantStyle('100dvh'),
            margin: 0,
          }),
          ...rest?.PaperProps?.sx,
        },
      }}
      {...rest}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: isBlackBackdrop ? '#000000' : undefined,
        },
        ...rest?.sx,
      }}
    >
      {isReversedActionSection && (
        <ActionSection
          actionButtons={actionButtons}
          isConfirmHidden={isConfirmHidden}
          isCancelHidden={isCancelHidden}
          onConfirm={onConfirm}
          onCancel={onCancel}
          sx={actionBtnContainerStyling}
        />
      )}

      {(customTitle || title) && (
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: isTitleCentered ? 'center' : 'space-between',
            fontSize: importantStyle('14px'),
            color: importantStyle('#ffffff'),
            fontWeight: importantStyle(700),
          }}
        >
          <CrossButton
            onClick={() => self.onClose()}
            style={{
              position: 'absolute',
              right: '24px',
              top: '20px',
            }}
          />
          {!customTitle && title}
          {customTitle && customTitle}
        </DialogTitle>
      )}

      <DialogContent
        sx={{
          padding: `${isLoadingDialog ? '1rem 2.5rem' : '0 24px'}`,
          paddingTop: !customTitle && !title ? '16px' : undefined,
          position: 'relative',
          ...(maxContentHeight && { maxHeight: maxContentHeight }),
        }}
      >
        {isShowCrossButton && !customTitle && !title && (
          <CrossButton
            onClick={() => self.onClose()}
            style={{
              position: 'absolute',
              right: '24px',
              top: '20px',
            }}
          />
        )}
        {content}
      </DialogContent>

      {!isReversedActionSection && (
        <ActionSection
          actionButtons={actionButtons}
          isConfirmHidden={isConfirmHidden}
          isCancelHidden={isCancelHidden}
          onConfirm={onConfirm}
          onCancel={onCancel}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          sx={actionBtnContainerStyling}
        />
      )}
    </Dialog>
  );
}

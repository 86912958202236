import { useEffect } from 'react';

import { useAppSelector } from '../reducer/hooks';
import { selectProfile } from '../reducer/profileSlice';
import useTranslation from './useTranslation';

const useLanguageFromProfileStore = () => {
  const { i18n } = useTranslation();

  const { lang } = useAppSelector(selectProfile);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
};

export default useLanguageFromProfileStore;

import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import axios from '../../axiosInstance';
import { ApiConfig } from '../../types';
import { AssetGetAllRes } from './types';

const { addressManagement } = EPt;

const getAllAsset = async (params?: unknown, config?: ApiConfig) => {
  const input = {
    params,
    ...config,
  };
  return await axios.get<any, AssetGetAllRes[] | undefined>(addressManagement.assetGetAll, input);
};

const updateClientSupportedAsset = async (
  params: { chainType: number; chainId: string; assetNameList: string[] },
  config?: ApiConfig
) => {
  return await axios.post<any, any>(addressManagement.assetUpdate, params, config);
};

export default { getAllAsset, updateClientSupportedAsset };

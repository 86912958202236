import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import { PAGE_SIZE } from '../../../constants/config';
import axios from '../../axiosInstance';
import { ApiConfig, IlistResult } from '../../types';
import {
  ReportCustomPeriodicTabGetAllRes,
  ReportDailyTabGetAllRes,
  ReportSummaryTabGetAllRes
} from './types';

const { report } = EPt;

const dailyTabGetAll = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: {
      ...rest,
      offset: page * pageSize,
      limit: pageSize,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<ReportDailyTabGetAllRes> | undefined>(
    report.portalGetByDaily,
    input
  );
};

const dailyTabExport = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: {
      ...rest,
      offset: page * pageSize,
      limit: pageSize,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<ReportDailyTabGetAllRes> | undefined>(
    report.portalGetByDailyExport,
    input
  );
};

const customPeriodicTabGetAll = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: {
      ...rest,
      offset: page * pageSize,
      limit: pageSize,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<ReportCustomPeriodicTabGetAllRes> | undefined>(
    report.portalGetByAddress,
    input
  );
};

const customPeriodicTabExport = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: {
      ...rest,
      offset: page * pageSize,
      limit: pageSize,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<ReportCustomPeriodicTabGetAllRes> | undefined>(
    report.portalGetByAddressExport,
    input
  );
};

const summaryTabGetAll = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: {
      ...rest,
      offset: page * pageSize,
      limit: pageSize,
    },
    ...config,
  };
  return await axios.get<any, IlistResult<ReportSummaryTabGetAllRes> | undefined>(
    report.portalGetByAddressGroup,
    input
  );
};

const summaryTabExport = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: {
      ...rest,
      offset: page * pageSize,
      limit: pageSize,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<ReportSummaryTabGetAllRes> | undefined>(
    report.portalGetByAddressGroupExport,
    input
  );
};

export default {
  dailyTabGetAll,
  dailyTabExport,
  customPeriodicTabGetAll,
  customPeriodicTabExport,
  summaryTabGetAll,
  summaryTabExport,
};

import { useState } from 'react';

import APIs from '../../../../../../api';
import ActionButtons from '../../../../../../common/components/Dialog/components/ActionButtons';
import ConfirmDeleteDialog from '../../../../../../common/components/Dialog/ConfirmDeleteDialog';
import { DialogInOne } from '../../../../../../components';
import UnderlinedButton from '../../../../../../components/Button/UnderlinedButton';
import { useAlerting, useTranslation } from '../../../../../../hooks';
import useVerifyField from '../../../../../../hooks/useVerifyField';
import { selectAddressGroup } from '../../../../../../reducer/addressGroupSlice';
import { selectAsset } from '../../../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../../../reducer/hooks';
import { TRANSLATE_PREFIX } from '../../../constants';
import Content, {
  AddOrEditAddressFieldState,
  initialAddOrEditAddressFields,
} from '../components/Content';
import { TRANSLATE_KEY } from '../constants';

function DialogEditAddress({
  onClose,
  onRefresh,
  data,
}: {
  onClose: () => void;
  onRefresh: () => void;
  data: AddOrEditAddressFieldState | undefined;
}) {
  const [fields, setFields] = useState(data || initialAddOrEditAddressFields);

  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const { verifyHasEmptyField, verifyIsNoChangesMade } = useVerifyField('addressList');

  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);
  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const onConfirm = async () => {
    const { chainCode, addressGroupName, address: walletAddress, displayName: walletName } = fields;

    const alertMessage =
      verifyIsNoChangesMade({ prevData: data, curData: fields }) ||
      verifyHasEmptyField([
        { value: chainCode, fieldTranslateKey: TRANSLATE_KEY.network, mode: 'selection' },
        { value: walletAddress, fieldTranslateKey: TRANSLATE_KEY.address, mode: 'input' },
        {
          value: addressGroupName,
          fieldTranslateKey: TRANSLATE_KEY.addressGroup,
          mode: 'selection',
        },
        { value: walletName, fieldTranslateKey: TRANSLATE_KEY.displayName, mode: 'input' },
      ]);

    if (alertMessage) {
      return alerting('warning', alertMessage);
    }

    const { chainId, chainType } = clientSupportedAssetChainCodeMapping[chainCode];

    const { id: walletGroupId } = addressGroupNameMapping[addressGroupName];

    const res = await APIs.addressManagement.addressList.updateAddress({
      chainId,
      chainType,
      walletGroupId,
      walletAddress,
      walletName,
    });

    if (!res) {
      return;
    }

    alerting('success', tc('edit_success_message', { itemName: t(TRANSLATE_KEY.address) }));
    onRefresh();
    onClose();
  };

  const onDelete = async () => {
    if (!data) {
      return alerting('warning', tc('internal_error_message'));
    }

    const { chainCode, address: walletAddress } = data;

    const { chainId, chainType } = clientSupportedAssetChainCodeMapping[chainCode];

    const res = await APIs.addressManagement.addressList.deleteAddress({
      chainType,
      chainId,
      walletAddress,
    });

    if (!res) {
      return;
    }

    alerting('success', tc('delete_success_message', { itemName: t(TRANSLATE_KEY.address) }));
    onRefresh();
    onClose();
  };

  return (
    <DialogInOne
      title={t(TRANSLATE_KEY.editAddress)}
      self={{ open: true, onClose }}
      content={
        <>
          <ConfirmDeleteDialog
            title={t(TRANSLATE_KEY.deleteAddress)}
            isOpen={isDeleteDialogOpen}
            onDelete={onDelete}
            onClose={() => {
              setIsDeleteDialogOpen((prev) => !prev);
            }}
          />
          <Content fields={fields} setFields={setFields} isEdit />
        </>
      }
      isShowCrossButton
      isBlackBackdrop
      isTitleCentered
      dialogWidth={{ width: '512px' }}
      isCancelHidden
      isConfirmHidden
      actionButtons={
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              width: '100%',
            }}
          >
            <ActionButtons onConfirm={onConfirm} onCancel={onClose} />
          </div>
          <UnderlinedButton
            color="#ff0000"
            fontSize="14px"
            onClick={() => {
              setIsDeleteDialogOpen(true);
            }}
            node={tc('remove_this_item', { itemName: t(TRANSLATE_KEY.address) })}
          />
        </>
      }
      actionBtnContainerStyling={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '40px 24px 24px',
      }}
      onConfirm={() => {}}
      onCancel={() => {}}
    />
  );
}

export default DialogEditAddress;

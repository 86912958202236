import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ensureAccessToken } from './api/axiosInstance';
// import i18nProvider from './components/i18nProvider';
import { LayoutBar } from './components/Layout';
import { Box } from './components/MuiGenerals';
import { useRoutePermission } from './hooks';
import { selectAsset } from './reducer/assetSlice';
import { useAppSelector } from './reducer/hooks';
import { selectProfile } from './reducer/profileSlice';
import { COLOR_THEME } from './style/colorTheme';
import { versionNumber } from './utils/constant';
import AddressBalance from './views/AddressManagement/AddressBalance';
import AddressGroup from './views/AddressManagement/AddressGroup';
import AddressList from './views/AddressManagement/AddressList';
import Asset from './views/AddressManagement/Asset';
import NoPermissionElement from './views/Auth/NoPermissionElement';
import Home from './views/Home';
import Report from './views/Report/Report';
import Transaction from './views/Report/Transaction';
import AccountSetting from './views/User/AccountSetting';
import ChangePasswordPage from './views/User/ChangePasswordPage';

//###add-new-page
const rawList = {
  accountSetting: <AccountSetting />,
  changePassword: <ChangePasswordPage />,

  // Address Management
  asset: <Asset />,
  addressGroup: <AddressGroup />,
  addressList: <AddressList />,
  addressBalance: <AddressBalance />,
  transaction: <Transaction />,
  report: <Report />,
};

const App = () => {
  useEffect(() => console.log('versionNumber', versionNumber), []);

  const { hasRoutePermission } = useRoutePermission();
  const { timezone, lang } = useAppSelector(selectProfile);
  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);

  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;

  const list: { [key: string]: any } =
    Object.entries(rawList).map(([key, comp]) => {
      const hasPermission = hasRoutePermission(key);
      // const hasPermission = true;

      const element = hasPermission ? comp : <NoPermissionElement />;

      return [key, element];
    }) || {};

  const isTokenReady = useToken();

  if (isLogin && !isTokenReady) return <></>;

  return (
    <Box
      sx={{
        main: {
          backgroundColor: COLOR_THEME.Page.Background,
          color: COLOR_THEME.Page.Text,
        },
      }}
    >
      <LayoutBar>
        <Routes>
          {!isLogin && <Route path="*" element={<Navigate replace to="/login" />} />}

          {isLogin && isTokenReady && (
            <>
              {/* {!Object.entries(clientSupportedAssetObj).length && (
                <Route path="*" element={<Navigate replace to="/asset" />} />
              )} */}
              <Route path="*" element={<Navigate replace to="" />} />
              <Route path="" element={<Home />} />

              {list.map(([path, element]: [string, JSX.Element], i: number) => (
                <Route key={i} path={path} element={React.cloneElement(element, { timezone })} />
              ))}
            </>
          )}
        </Routes>
      </LayoutBar>
    </Box>
  );
};
function useToken() {
  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isLogin) {
      return;
    }

    async function fetch() {
      const isTokenValid = await ensureAccessToken();
      if (isTokenValid) {
        setIsReady(true);
      }
    }
    fetch();
  }, [isLogin]);

  return isReady;
}
export default App;

import { useTranslation as useTranslate } from 'react-i18next';

import en from '../components/i18nProvider/en';
import { AllEnums, enumTranslateKeys } from '../helper/getEnumTranslationEntries';
import { enumLanguage } from '../utils/constant';

export type Iprefix = keyof typeof en;
export type IcommonKey = keyof typeof en.common;
export type IenumKey = keyof typeof en.enumConstants;
export type IcountryCodeKey = keyof typeof en.countryCodes;

export default function useTranslation(prefix?: Iprefix) {
  // const translate = useTranslate();
  const { t: i18nTranslate, i18n } = useTranslate(undefined, {});

  const getTranslation = ({
    key,
    namespace,
    fixedLang,
    params,
  }: {
    key: string;
    namespace: string;
    fixedLang?: keyof typeof enumLanguage;
    params: Record<string, string>;
  }) => {
    if (fixedLang) {
      return i18n.getFixedT(fixedLang)(key, { ns: namespace, ...params });
    }

    return i18nTranslate(key, { ns: namespace, ...params });
  };

  const currentLanguage = i18n.language;

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const getTranslateKey = (key: string) => {
    let namespace = prefix;
    const [ns, ...rest] = key.split('.');

    if (rest.length > 0) {
      namespace = ns as any;
      key = rest.join('.');
    }

    return { namespace, finalKey: key };
  };

  const displayExistEnumOnly = (
    namespace: AllEnums,
    key: string,
    params = {},
    fixedLang?: keyof typeof enumLanguage
  ) => {
    const notAssignedOrNotFoundKey = key ? `{{${key}}}` : '';

    const isEnumFound = Object.keys(en[namespace] || {}).includes(key as string);

    return isEnumFound
      ? getTranslation({ key, namespace, params, fixedLang })
      : notAssignedOrNotFoundKey;
  };

  const genericTranslate = (key: string, params = {}, fixedLang?: keyof typeof enumLanguage) => {
    return enumTranslateKeys.find((enumTranslateKey) => enumTranslateKey === prefix)
      ? displayExistEnumOnly(prefix as AllEnums, key, params, fixedLang)
      : getTranslation({ key, namespace: prefix || '', params, fixedLang });
  };

  const translate = (key: string, params?: {}) => {
    const { namespace, finalKey } = getTranslateKey(key);
    return i18nTranslate(finalKey, { ns: namespace, ...params });
  };

  const t = (key: string, params = {}) => genericTranslate(key, params);

  const tc = (key: IcommonKey, params = {}) => getTranslation({ key, namespace: 'common', params });

  const te = (namespace: AllEnums, key: string, params = {}) =>
    displayExistEnumOnly(namespace, key, params);

  const tb = (expectedBol: unknown) => {
    if (expectedBol === true) {
      return te('enumConstants', 'yes');
    }
    if (expectedBol === false) {
      return te('enumConstants', 'no');
    }
    return '';
  };
  const en_t = (key: string, params = {}) => genericTranslate(key, params, 'en');

  const en_tc = (key: IcommonKey, params = {}) =>
    getTranslation({ key, namespace: 'common', params, fixedLang: 'en' });

  const en_te = (namespace: AllEnums, key: string, params = {}) =>
    displayExistEnumOnly(namespace, key, params, 'en');

  const en_tb = (expectedBol: unknown) => {
    if (expectedBol === true) {
      return en_te('enumConstants', 'yes');
    }
    if (expectedBol === false) {
      return en_te('enumConstants', 'no');
    }
    return '';
  };

  const tcc = (countryCode: string) =>
    en.countryCodes.find(({ isoCode3 }) => countryCode === isoCode3)?.country || '';

  return {
    translate,
    i18n,
    currentLanguage,
    changeLanguage,
    t,
    tc,
    te,
    tcc,
    tb,
    en_t,
    en_tc,
    en_te,
    en_tb,
  };
}

import { ReactNode } from 'react';

import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import SearchBar, { SearchBarProps } from '../SearchBar';
import ButtonSection from './components/ButtonSection';

function SearchBarTemplate({
  input,
  onSearch,
  label,
  filter,
  buttonConfigArr,
  noItemText,
  children,
}: SearchBarProps & {
  buttonConfigArr?: {
    node: ReactNode;
    onClick: () => void;
    width: string;
    permission?: string;
  }[];
  noItemText?: string;
  children?: ReactNode;
}) {
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div
      style={{
        paddingRight: isMobile ? '1rem' : undefined,
        paddingLeft: isMobile ? '1rem' : undefined,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <SearchBar label={label} onSearch={onSearch} input={input} filter={filter} />
        {buttonConfigArr && <ButtonSection buttonConfigArr={buttonConfigArr} />}
      </div>

      <hr style={{ opacity: 0.2, borderBottomWidth: '0px', margin: '20px 0' }} />

      {children}
      <div style={{ fontSize: '14px' }}>{noItemText}</div>
    </div>
  );
}

export default SearchBarTemplate;

import { useContext } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { LoginContext } from '../';
import backBtnIcon from '../../../../assets/icons/LoginForm/back-btn.png';

function LoginForm({
  title,
  subtitle,
  submitBtn,
  onBackBtnClick,
  children,
}: {
  title: string;
  subtitle?: string;
  submitBtn: { wording: string; onSubmit: () => Promise<void> };
  onBackBtnClick?: () => void;
  children: React.ReactNode;
}) {
  const { setIsLoading, isLoading } = useContext(LoginContext);

  return (
    <form
      className="login-form"
      onSubmit={async () => {
        setIsLoading(true);
        await submitBtn.onSubmit();
        setIsLoading(false);
      }}
    >
      <div className="title-container">
        {onBackBtnClick && (
          <img src={backBtnIcon} alt="back" className="back-btn" onClick={onBackBtnClick} />
        )}
        <div className="title">{title}</div>
      </div>
      <div className="subtitle-container">
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
      <div className="fields-container">{children}</div>
      <button
        type="submit"
        className="submit-button"
        onClick={async (e) => {
          e.preventDefault();
          setIsLoading(true);
          await submitBtn.onSubmit();
          setIsLoading(false);
        }}
        style={isLoading ? { opacity: 0.7 } : undefined}
        disabled={isLoading}
      >
        {submitBtn.wording}
        {isLoading && (
          <CircularProgress
            sx={{
              color: '#578FFE',
              marginLeft: '12px',
            }}
            size={16}
          />
        )}
      </button>
    </form>
  );
}

export default LoginForm;

import addressManagement from './addressManagement';
import auth from './auth';
import dashboard from './dashboard';
import merchantPortal from './merchantPortal';
import query from './query';
import report from './report';

const APIs = {
  auth,
  addressManagement,
  merchantPortal,
  query,
  dashboard,
  report,
};

export default APIs;

export const TRANSLATE_KEY = {
  address: 'dialog.address',
  addAddress: 'dialog.add_address',
  network: 'dialog.network',
  asset: 'dialog.asset',
  addressGroup: 'dialog.address_group',
  displayName: 'dialog.display_name',
  editAddress: 'dialog.edit_address',
  deleteAddress: 'dialog.delete_address',
  networkNoticeText: 'dialog.network_notice_text',
  notSupportedAssetNotice: 'dialog.not_supported_asset_notice',
  addressGroupNoticeText: 'dialog.address_group_notice_text',

  index: 'dialog.index',
  status: 'dialog.status',
  errorMessage: 'dialog.error_message',
  importAddressResult: 'dialog.import_address_result',
  successCount: 'dialog.success_count',
  duplicatedCount: 'dialog.duplicated_count',
  failRecords: 'dialog.fail_records',
  downloadLogFile: 'dialog.download_log_file',
} as const;

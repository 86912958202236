import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import APIs from '../../../../api';
import CustomDataGrid from '../../../../components/Layout/CustomDataGrid';
import Filter from '../../../../components/Layout/Filter';
import { removeEmptyStringApiParams } from '../../../../helper';
import { useAlerting, useTranslation } from '../../../../hooks';
import useGetNetworkInfo from '../../../../hooks/useGetNetworkInfo';
import useLoading from '../../../../hooks/useLoading';
import useRefreshPage from '../../../../hooks/useRefreshPage';
import { selectAddressGroup } from '../../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { downloadFiles, getFullApiResponse } from '../../../../utils';
import { ExportBtnConfig } from '../types';
import { EXPORT_FILE_NAME, OMIT_KEYS, TRANSLATE_PREFIX } from './constants';
import useFilter from './hooks/useFilter';
import useTableColumns from './hooks/useTableColumns';
import { useTransformData } from './hooks/useTransformData';
import { ApiParamsState } from './types';

function SummaryTab({
  setExportBtnConfig,
}: {
  setExportBtnConfig: Dispatch<SetStateAction<ExportBtnConfig>>;
}) {
  const { fieldArr, onResetFields, fields } = useFilter();
  const [page, setPage] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);
  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);
  const { merchantSupportedAssetChainCodeMapping, getAssetDecimal } = useGetNetworkInfo();
  const { transformData } = useTransformData();
  const { alerting } = useAlerting();

  const { columns } = useTableColumns();

  const initialApiParams = useMemo(() => getApiParams(), []);

  const [apiParams, setApiParams] = useState<ApiParamsState>(initialApiParams);
  const { refreshDependency, getNewRefreshDependency } = useRefreshPage();

  function getApiParams(): ApiParamsState {
    const {
      // chainCodes,
      chainCode,
      tokens: assetNameList,
      addressGroupNames,

      dateFrom,
      dateTo,
    } = fields;

    const walletGroupId = addressGroupNames.map(
      (addressGroupName) => addressGroupNameMapping[addressGroupName].id
    );

    const { chainId = '', chainType = '' } =
      merchantSupportedAssetChainCodeMapping[chainCode] || {};

    return {
      walletGroupId,
      chainId: chainId,
      chainType: String(chainType),
      assetNameList,
      dateFrom,
      dateTo,
    };
  }

  const queryRes = useQuery({
    queryKey: [TRANSLATE_PREFIX, apiParams, page, isFilterApplied, refreshDependency],
    queryFn: () => {
      if (!isFilterApplied) {
        return;
      }

      showLoading(TRANSLATE_PREFIX);

      const removedEmptyStringParams = removeEmptyStringApiParams(apiParams);

      return APIs.report.report.summaryTabGetAll({ ...removedEmptyStringParams, page });
    },
    onSettled: () => {
      hideLoading(TRANSLATE_PREFIX);
    },
  });

  const { rows = [], count = 0 } = queryRes.data || {};

  const content = transformData({ mode: 'table', data: rows });

  const onApply = () => {
    const apiParams = getApiParams();

    setApiParams(apiParams);
    getNewRefreshDependency();
    setPage(0);
    setIsFilterApplied(true);
  };
  const onClear = () => {
    onResetFields();
    setApiParams(initialApiParams);
  };

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const removedEmptyStringParams = removeEmptyStringApiParams(apiParams);

    const apiFn = (page: number, pageSize: number, signal: any) =>
      APIs.report.report.summaryTabExport(
        { ...removedEmptyStringParams, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = OMIT_KEYS.export;

    const res = transformData({ mode: 'export', data: rawRes, omitKeys });
    const config = {};
    downloadFiles(EXPORT_FILE_NAME, res, config);
  };

  const getIsFiltering = () => {
    if (!isFilterApplied) {
      return false;
    }

    return Object.values(apiParams).some((value) => (Array.isArray(value) ? value.length : value));
  };

  const isFiltering = getIsFiltering();

  useEffect(() => {
    setExportBtnConfig({
      onClick: onExport,
      permission: InspectionWalletFeatureCode.Report.Report.Summary.Export,
    });
  }, [count, apiParams]);

  return (
    <>
      <Filter
        isFiltering={isFiltering}
        fieldArr={fieldArr}
        onApply={onApply}
        onClear={onClear}
        isDefaultExpanded
      />
      <CustomDataGrid
        rows={content}
        rowCount={count}
        columns={columns}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        isLoading={isLoading}
        isApplyFilterRequired={!isFilterApplied}
      />
    </>
  );
}

export default SummaryTab;

import { useEffect, useState } from 'react';

import { Direction, RiskLevel } from '@wallet-manager/node-types/dist/src/postgres/const';

import AddressGroupSelection from '../../../../common/components/Filter/Selection/AddressGroupSelection';
import NetworkSelection from '../../../../common/components/Filter/Selection/NetworkSelection';
import TokenSelection from '../../../../common/components/Filter/Selection/TokenSelection';
import { MpTextField } from '../../../../components';
import { useDatePicker } from '../../../../components/DatePicker';
import GeneralSelection from '../../../../components/Selection/GeneralSelection';
import { useTranslation } from '../../../../hooks';
import useGetSerachParams from '../../../../hooks/useGetSerachParams';
import { selectAddressGroup } from '../../../../reducer/addressGroupSlice';
import { selectAsset } from '../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { EnumBoolean } from '../../../../utils/constant';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';
import { FilterFieldsState } from '../types';

export const initialFields = {
  chainCode: '',
  // chainCodes: [],
  displayName: '',
  address: '',
  transactionHash: '',
  addressGroupNames: [],
  tokens: [],
  direction: '',
  feeRecord: '',
  riskLevel: '',
  checkPoint: false,
  createdDateFrom: '',
  createdDateTo: '',
  lastModifiedDateFrom: '',
  lastModifiedDateTo: '',
  settlementDateFrom: '',
  settlementDateTo: '',
};

function useFilter() {
  const { searchParams, clearSearchParams } = useGetSerachParams(['addressGroupName']);

  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);
  const { addressGroupNameMapping, addressGroupIdMapping } = useAppSelector(selectAddressGroup);

  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const DateFields = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
    SettlementTime: useDatePicker(),
  };

  const [fields, setFields] = useState<FilterFieldsState>({
    ...initialFields,
    addressGroupNames: searchParams.addressGroupName ? [searchParams.addressGroupName] : [],
  });

  useEffect(() => {
    setFields((prev) => ({
      ...prev,
      createdDateFrom: DateFields.CreationTime.start,
      createdDateTo: DateFields.CreationTime.end,
      lastModifiedDateFrom: DateFields.LastModifiedTime.start,
      lastModifiedDateTo: DateFields.LastModifiedTime.end,
      settlementDateFrom: DateFields.SettlementTime.start,
      settlementDateTo: DateFields.SettlementTime.end,
    }));
  }, [
    DateFields.CreationTime.start,
    DateFields.CreationTime.end,
    DateFields.LastModifiedTime.start,
    DateFields.LastModifiedTime.end,
    DateFields.SettlementTime.start,
    DateFields.SettlementTime.end,
  ]);

  const fieldArr = [
    {
      name: t(TRANSLATE_KEY.creationTime),
      component: <DateFields.CreationTime.Picker type="dateTime" />,
    },
    {
      name: t(TRANSLATE_KEY.lastModifiedTime),
      component: <DateFields.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      name: t(TRANSLATE_KEY.settlementTime),
      component: <DateFields.SettlementTime.Picker type="dateTime" />,
    },
    {
      name: t(TRANSLATE_KEY.transactionHash),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.transactionHash) })}
          value={fields.transactionHash}
          onChange={(e) => setFields((prev) => ({ ...prev, transactionHash: e.target.value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.address),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.address) })}
          value={fields.address}
          onChange={(e) => setFields((prev) => ({ ...prev, address: e.target.value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.displayName),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.displayName) })}
          value={fields.displayName}
          onChange={(e) => setFields((prev) => ({ ...prev, displayName: e.target.value }))}
        />
      ),
    },

    {
      name: t(TRANSLATE_KEY.network),
      // name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.network) }),
      component: (
        <NetworkSelection
          mode={'single'}
          value={fields.chainCode}
          onChange={(chainCode) => {
            setFields((prev) => ({ ...prev, chainCode, tokens: [] }));
          }}
          clearSelect={() => {
            setFields((prev) => ({ ...prev, chainCode: '' }));
          }}
        />
        // <GeneralSelection
        //   mode={'multiple'}
        //   label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.network) })}
        //   value={fields.chainCodes}
        //   onChange={(chainCodeArr) => {
        //     setFields((prev) => ({ ...prev, chainCodes: chainCodeArr }));
        //   }}
        //   enumData={clientSupportedAssetChainCodeMapping}
        //   isSearchable
        //   nameFn={(name) => CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING[name as ChainCode]}
        //   isNoSorting
        // />
      ),
    },
    {
      name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.token) }),
      component: (
        <TokenSelection
          mode={'multiple'}
          chainCode={fields.chainCode}
          value={fields.tokens}
          onChange={(tokens) => {
            setFields((prev) => ({ ...prev, tokens, assetBalanceAmount: '' }));
          }}
        />
      ),
    },
    {
      name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.addressGroup) }),
      component: (
        <AddressGroupSelection
          mode={'multiple'}
          value={fields.addressGroupNames}
          onChange={(value) => setFields((prev) => ({ ...prev, addressGroupNames: value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.direction),
      component: (
        <GeneralSelection
          mode={'single'}
          label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.direction) })}
          value={fields.direction}
          onChange={(direction) => {
            setFields((prev) => ({ ...prev, direction }));
          }}
          enumData={Direction}
          enumTranslatePrefix={'enumDirection'}
          isNoSorting
          clearSelect={() => {
            setFields((prev) => ({ ...prev, direction: '' }));
          }}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.feeRecord),
      component: (
        <GeneralSelection
          mode={'single'}
          label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.feeRecord) })}
          value={fields.feeRecord}
          onChange={(feeRecord) => {
            setFields((prev) => ({ ...prev, feeRecord }));
          }}
          enumData={EnumBoolean}
          isNoSorting
          clearSelect={() => {
            setFields((prev) => ({ ...prev, feeRecord: '' }));
          }}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.riskLevel),
      component: (
        <GeneralSelection
          mode={'single'}
          label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.riskLevel) })}
          value={fields.riskLevel}
          onChange={(riskLevel) => {
            setFields((prev) => ({ ...prev, riskLevel }));
          }}
          enumData={RiskLevel}
          enumTranslatePrefix={'enumRiskLevel'}
          isNoSorting
          clearSelect={() => {
            setFields((prev) => ({ ...prev, riskLevel: '' }));
          }}
        />
      ),
    },
    // {
    //   name: t(TRANSLATE_KEY.checkPoint),
    //   component: (
    //     <CustomSwitch
    //       onChange={(_, checked) => setFields((prev) => ({ ...prev, checkPoint: checked }))}
    //       checked={fields.checkPoint}
    //     />
    //   ),
    // },

    // TODO: add checkpoint filter back
  ];

  const onResetFields = () => {
    setFields(initialFields);
    DateFields.CreationTime.clearDate();
    DateFields.LastModifiedTime.clearDate();
    DateFields.SettlementTime.clearDate();
    clearSearchParams();
  };

  return {
    fieldArr,
    fields,
    onResetFields,
  };
}

export default useFilter;

import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import axios from '../axiosInstance';
import { ApiConfig } from '../types';
import { ClientSupportedAssetQueryRes } from './types';

const { query } = EPt;

const getMerchantSupportedAsset = async (config?: ApiConfig) => {
  const input = {
    ...config,
  };
  return await axios.get<any, any>(query.supportedAsset, input);
};
const getClientSupportedAsset = async (config?: ApiConfig) => {
  const input = {
    ...config,
  };
  return await axios.get<any, ClientSupportedAssetQueryRes[] | undefined>(
    query.clientSupportedAsset,
    input
  );
};

export default { getMerchantSupportedAsset, getClientSupportedAsset };

import { useListMappingTransform, useTranslation } from '../../../../../../../../hooks';
import { TRANSLATE_PREFIX } from '../../../../../constants';
import { ImportAddressLog } from '../../../../../hooks/useImportCsv';
import { TRANSLATE_KEY } from '../../../constants';

function useTransformData() {
  const { te, en_te } = useTranslation();

  const mappingFn = (mode: 'table' | 'export') => (item: ImportAddressLog, index: number) => {
    const teByMode = mode === 'export' ? en_te : te;

    const mapping: Array<[string, unknown]> = [
      [TRANSLATE_KEY.index, index],
      [TRANSLATE_KEY.status, teByMode('enumImportAddressStatus', item.status)],
      [TRANSLATE_KEY.displayName, item.display_name],
      [TRANSLATE_KEY.addressGroup, item.address_group],
      [TRANSLATE_KEY.address, item.address],
      [TRANSLATE_KEY.network, item.network],
      [TRANSLATE_KEY.errorMessage, item.error_message],
    ];

    return mapping;
  };

  const transformData = useListMappingTransform({ translatePrefix: TRANSLATE_PREFIX, mappingFn });

  return { transformData };
}

export default useTransformData;

import { ChangeEvent } from 'react';

import TextField from '@mui/material/TextField';

import {
  containsOnlyInteger,
  containsOnlyNumbers,
  containsOnlySignedInteger,
  containsOnlySignedNumbers,
  importantStyle
} from '../../utils';
import { inputCharacterMaxLength } from '../../utils/constant';
import { MpTextFieldProps } from './MpTextField';

export type MpTextFieldNumberProps = MpTextFieldProps & {
  maxNumber?: number;
  noCheckMaxNumber?: boolean;
  integerOnly?: boolean;
  decimalCount?: number;
  allowSignedNumber?: 'onlyNegative' | 'all';
  maxInputLength?: number;
};

export default function MpTextFieldNumberOnly(props: MpTextFieldNumberProps) {
  const {
    innerRef,
    onChange,
    maxNumber = Number.MAX_SAFE_INTEGER,
    noCheckMaxNumber,
    integerOnly = false,
    decimalCount,
    allowSignedNumber,
    maxInputLength,
    showDecimalKeyboard,
    showNumericKeyboard,
    sx,
    ...rest
  } = props;

  const newProps = {
    ...rest,
    ref: innerRef,
    inputProps: {
      maxLength: maxInputLength || inputCharacterMaxLength,
      ...((showDecimalKeyboard || showNumericKeyboard) && {
        inputMode: (showNumericKeyboard ? 'numeric' : 'decimal') as any,
        pattern: '[0-9]*',
      }),
      ...props.inputProps,
    },
    sx: {
      ...sx,
      '.MuiInputLabel-root': {
        ...sx?.['.MuiInputLabel-root'],

        ':hover': {
          ...sx?.['.MuiInputLabel-root']?.[':hover'],
        },
      },
      '.MuiInputLabel-shrink': {
        display: 'none',
        ...sx?.['.MuiInputLabel-shrink'],
      },
      '.MuiInputBase-root': {
        boxSizing: 'border-box',
        border: '1px solid #474747',
        backgroundColor: importantStyle('#000000'),
        // borderRadius: '6px',

        borderRadius: 'initial',
        padding: importantStyle(0),

        ...sx?.['.MuiInputBase-root'],

        '::before': {
          content: 'none',
          ...sx?.['.MuiInputBase-root']?.['::before'],
        },
        '::after': {
          content: 'none',
          ...sx?.['.MuiInputBase-root']?.['::after'],
        },
        input: {
          padding: importantStyle('12.5px 12px'),
          ...sx?.['.MuiInputBase-root']?.input,
        },
      },
    },
  };

  const getNumberCheckingFn: () => (str: string) => boolean = () => {
    if (allowSignedNumber) {
      const isOnlyNegative = allowSignedNumber === 'onlyNegative';

      return integerOnly
        ? containsOnlySignedInteger(isOnlyNegative)
        : containsOnlySignedNumbers(isOnlyNegative);
    }

    return integerOnly ? containsOnlyInteger : containsOnlyNumbers;
  };

  const numberCheckingFn = getNumberCheckingFn();

  const onChangeOnlyNumber = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!numberCheckingFn(e.target.value)) {
      return;
    }

    const inputDecimalCount = e.target.value.split('.')[1]?.length;

    if (decimalCount && inputDecimalCount > decimalCount) {
      return;
    }

    if (!noCheckMaxNumber && Number(e.target.value) > maxNumber) {
      return;
    }

    if (onChange) {
      onChange(e);
    }
  };

  return <TextField {...newProps} onChange={onChangeOnlyNumber} />;
}

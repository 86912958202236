export const TRANSLATE_KEY = {
  transactionDetails: 'detailsDialog.transaction_details',
  creationTime: 'detailsDialog.creation_time',
  settlementTime: 'detailsDialog.settlement_time',
  minerFee: 'detailsDialog.miner_fee',
  status: 'detailsDialog.status',
  transactionAmount: 'detailsDialog.transaction_amount',
  send: 'detailsDialog.send',
  receive: 'detailsDialog.receive',
  chainInformation: 'detailsDialog.chain_information',
  transactionHash: 'detailsDialog.transaction_hash',
  viewOnEtherscan: 'detailsDialog.view_on_etherscan',
  blockHash: 'detailsDialog.block_hash',
  network: 'detailsDialog.network',
  currency: 'detailsDialog.currency',
  blockConfirmation: 'detailsDialog.block_confirmation',
  blockTransactionTime: 'detailsDialog.block_transaction_time',
  blockNumber: 'detailsDialog.block_number',
  addressInformation: 'detailsDialog.address_information',
  address: 'detailsDialog.address',
  direction: 'detailsDialog.direction',
  postBalanceOfWalletAddress: 'detailsDialog.post_balance_of_wallet_address',
  displayName: 'detailsDialog.display_name',
  clientId: 'detailsDialog.client_id',
  walletTag: 'detailsDialog.wallet_tag',
};

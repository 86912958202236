const PALETTE = {
  // side menu color
  Primary: '#222222',
  // filter and table background
  Secondary: '#333746',
  SecondaryVariant: '#181818',

  // In this project, button primary is linear gradient color which MUI Theme does not accept normally.
  // so primary button will be done inside specific component (e.g. SearchButton / ResetButton)
  ButtonPrimary: '#578FFE',
  ButtonSecondary: '#7F9AC4',
  Button3: '#222222',

  PrimaryText: '#FFD100',
  // filter title, button (export/create) border + text, table header, table cell text color
  SecondaryText: '#FFFFFF',
  // side menu text color, primary button text color, some top bar text color
  Button3Text: '#FFFFFF',

  SideMenuPrimaryText: '#EDA30E',
  SideMenuSecondaryText: '#FFFFFF',

  SelectionColor: '#EDA30E',

  // table row background color
  Accent1: '#333333',
  Accent2: '#838DA4',

  White: '#FFFFFF',

  ChartColor1: '#8a64ff',
  ChartColor2: '#00F5FF',
  ChartColor3: '#5700D4',
  ChartColor4: '#005FF5',
  ChartColor5: '#63FFBE',
  ChartColor6: '#AFDA75',

  DashBoardColor1: '#20D3FD',
  GreyColor: '#B0B0B0',
  DotColor: '#448DDD',
};

const COLOR_THEME = {
  Page: {
    Background: PALETTE.SecondaryVariant,
    Text: PALETTE.SecondaryText,
  },
  Menu: {
    Background: PALETTE.Primary,
    HoverItem: PALETTE.Primary,
    Primary: {
      Text: PALETTE.SideMenuPrimaryText,
    },
    Secondary: {
      Text: PALETTE.SideMenuSecondaryText,
    },
  },
  Loading: {
    Background: PALETTE.Primary,
    CircularProgress: PALETTE.ButtonPrimary,
  },
  MUI: {
    Primary: {
      Main: PALETTE.ButtonPrimary,
      Text: PALETTE.Button3Text,
    },
    Secondary: {
      Main: PALETTE.ButtonSecondary,
      Text: PALETTE.Button3Text,
    },
    Warning: {
      Main: PALETTE.Accent1,
      Text: PALETTE.SecondaryText,
    },
    Info: {
      Main: PALETTE.SecondaryText,
      Text: PALETTE.Primary,
    },
    MuiList: {
      Text: PALETTE.SelectionColor,
      EventText: PALETTE.Button3Text,
      ListItem: {
        Background: PALETTE.Primary,
        Text: PALETTE.SecondaryText,
      },
      FocusVisible: {
        Selected: {
          Text: PALETTE.Button3Text,
        },
      },
    },
    MuiInputBase: {
      Text: PALETTE.Accent2,
      Background: PALETTE.Primary,
      BorderColor: PALETTE.Accent2,
    },
    MuiCalendarPicker: {
      Background: PALETTE.Primary,
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
      MuiPickerDayRoot: {
        Background: PALETTE.Secondary,
        Selected: {
          Background: PALETTE.Accent1,
        },
      },
    },
    MuiPopper: {
      Background: PALETTE.Secondary,
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiDataGrid: {
      Text: PALETTE.SecondaryText,
      Column: {
        Headers: {
          Background: PALETTE.Primary,
        },
      },
      Row: {
        Background: PALETTE.Accent1,
        Cell: {
          Border: PALETTE.Secondary,
        },
      },
      Footer: {
        Background: PALETTE.Secondary,
      },
    },
    MuiPaginationItem: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Accent1,
    },
    MuiPaper: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Accent1,
    },
    MuiClockPicker: {
      Text: PALETTE.White,
      SecondaryText: PALETTE.SecondaryText,
      ClockIndexColor: PALETTE.PrimaryText,

      Clock: {
        Background: PALETTE.Secondary,
        // Background: PALETTE.Accent1,
      },
    },
    MuiTabs: {
      PrimaryText: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    RaMenuItemLink: {
      Text: PALETTE.PrimaryText,
    },
    MuiDateTimePickerTabs: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiMonthPicker: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiDialog: {
      Text: PALETTE.SecondaryText,
    },
    MuiDialogActions: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiFormLabel: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiTypography: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiButtonBase: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
    },
    MuiSvgIcon: {
      Text: PALETTE.SecondaryText,
    },
    MuiInputLabel: {
      Text: PALETTE.SecondaryText,
    },
    MuiInputAdornment: {
      Text: PALETTE.SecondaryText,
    },
    MuiCard: {
      Background: PALETTE.Secondary,
    },
    MuiSkeleton: {
      Background: PALETTE.Secondary,
    },
  },
  CustomSx: {
    LayoutBox: {
      Background: PALETTE.Secondary,
    },
    DatagridContainer: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Secondary,
      ScrollThumb: PALETTE.Accent1,
      LabelText: PALETTE.Accent2,
      ButtonText: PALETTE.Accent2,
      ButtonBorder: PALETTE.Accent2,
    },
    SearchFirstDataGridOverlay: {
      Background: PALETTE.SecondaryVariant,
      Text: PALETTE.SecondaryText,
    },
    GridFilter: { Background: PALETTE.Secondary },
    FilterB: { Background: PALETTE.Secondary },
    TableRow: { Background: PALETTE.Secondary },
    ComponentHelper: {
      AddressCell: {
        Text: PALETTE.PrimaryText,
      },
    },
    Chart: {
      DoughnutChart: {
        ChartColor: [
          PALETTE.ChartColor1,
          PALETTE.ChartColor2,
          PALETTE.ChartColor3,
          PALETTE.ChartColor4,
          PALETTE.ChartColor5,
          PALETTE.ChartColor6,
        ],
      },
      LineChart: {
        LineColor: PALETTE.DashBoardColor1,
        CartesianGridColor: PALETTE.GreyColor,
        TooltipContentBackground: PALETTE.SecondaryVariant,
        DotColor: PALETTE.DotColor,
      },
    },
    Dashboard: {
      programAgentBalance: {
        Title: {
          Color: PALETTE.Button3Text,
        },
        Amount: {
          Color: PALETTE.DashBoardColor1,
        },
      },
      label: PALETTE.GreyColor,
    },
  },
  Layout: {
    DialogWithOuterBox: {
      Background: PALETTE.Secondary,
    },
    CustomPagination: {
      Background: PALETTE.Primary,
      Text: PALETTE.SecondaryText,
      Selected: {
        Text: PALETTE.PrimaryText,
      },
    },
    FilterTable: {
      Background: PALETTE.Secondary,
    },
    NoRowsOverlay: {
      Text: PALETTE.SecondaryText,
      Background: PALETTE.Accent1,
    },
    LayoutBar: {
      Text: PALETTE.SecondaryText,
      SecondaryText: PALETTE.SecondaryText,
      LogoutBtn: {
        Text: PALETTE.Button3Text,
        Background: PALETTE.Button3,
      },
      Dropdown: {
        Background: PALETTE.Secondary,
        Text: PALETTE.SecondaryText,
      },
    },
    GridBoxSection: {
      Text: PALETTE.PrimaryText,
    },
  },
  Views: {
    CustomerApplication: {
      DialogOperation: {
        Text: PALETTE.PrimaryText,
      },
    },
    CreditAdjustmentRequest: {
      DialogOperation: {
        Text: PALETTE.PrimaryText,
      },
    },
    AccountSetting: {
      Text: PALETTE.PrimaryText,
      SecondaryText: PALETTE.SecondaryText,
      LanguageSelection: {
        Background: PALETTE.Primary,
        Text: PALETTE.Button3Text,
      },
    },
  },
  Components: {
    LoadingProgressDialog: {
      Text: PALETTE.SecondaryText,
    },
    UseTabs: {
      Text: PALETTE.SecondaryText,
    },
    ExportBtn: {
      HoverBorderColor: PALETTE.SecondaryText,
      HoverBackground: PALETTE.Button3,
    },
  },
};

export { PALETTE, COLOR_THEME };

import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import axios from '../axiosInstance';
import { ApiConfig } from '../types';
import { DashboardRes } from './types';

const { query } = EPt;

const getDashboard = async (config?: ApiConfig) => {
  const input = {
    ...config,
  };

  return await axios.get<any, DashboardRes>(query.dashboard, input);
};

export default { getDashboard };

export const TRANSLATE_KEY = {
  displayName: 'customPeriodicTab.display_name',
  addressGroup: 'customPeriodicTab.address_group',
  address: 'customPeriodicTab.address',
  network: 'customPeriodicTab.network',
  token: 'customPeriodicTab.token',
  date: 'customPeriodicTab.date',
  openingBalance: 'customPeriodicTab.opening_balance',
  inAmount: 'customPeriodicTab.in_amount',
  outAmount: 'customPeriodicTab.out_amount',
  txFee: 'customPeriodicTab.tx_fee',
  closingBalance: 'customPeriodicTab.closing_balance',
};

export const TRANSLATE_PREFIX = 'report';

export const OMIT_KEYS = { export: ['rawData'], table: [] };

export const EXPORT_FILE_NAME = 'custom_periodic_report';

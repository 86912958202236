import { Route, Routes } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import App from './App';
import { Alerter } from './components';
import LoadingModal from './components/LoadingModal';
import { useReduxSync } from './hooks';
import useLanguageFromProfileStore from './hooks/useLanguageFromProfileStore';
import { theme } from './utils/theme';
// import { useAppSelector } from './reducer/hooks';
// import { selectProfile } from './reducer/profileSlice';
import { Redirect } from './views/Auth';
import IndividualLogin from './views/Auth/IndividualLogin';
import RedirectLogin from './views/Auth/RediectLogin';

const LoginRouter = () => {
  useReduxSync();
  useLanguageFromProfileStore();

  return (
    <>
      <Alerter />
      <LoadingModal />
      <ThemeProvider theme={createTheme(theme)}>
        <Routes>
          <Route path="login" element={<IndividualLogin />} />
          <Route path="redirect" element={<Redirect />} />
          <Route path="redirectLogin" element={<RedirectLogin />} />

          <Route path="*" element={<App />} />
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default LoginRouter;

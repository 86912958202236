import { ReactNode } from 'react';

import { usePermission } from '../../../hooks';
import ButtonSection from './components/ButtonSection';
import TabSlider from './components/TabSlider';

function PageTopBar({
  tabConfigArr,
  buttonConfigArr,
}: {
  tabConfigArr?: {
    isSelected: boolean;
    node: ReactNode;
    onClick: () => void;
    width: string;
    permission?: string;
  }[];
  buttonConfigArr: {
    node: ReactNode;
    onClick: () => void;
    width: string;
    permission?: string;
    isNoPadding?: boolean;
  }[];
}) {
  const { hasPermissionMultipleEither } = usePermission();

  const allButtonRequirePermission = buttonConfigArr.every(({ permission }) => permission);
  const allTabRequirePermission = tabConfigArr?.every(({ permission }) => permission);

  const showSection =
    !allButtonRequirePermission ||
    allTabRequirePermission === false ||
    hasPermissionMultipleEither(buttonConfigArr.map(({ permission }) => permission || '')) ||
    hasPermissionMultipleEither(tabConfigArr?.map(({ permission }) => permission || '') || []);

  return (
    <>
      {showSection && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: tabConfigArr ? 'space-between' : 'flex-end',
              alignItems: 'center',
              width: '100%',
              gap: '24px',
              flexWrap: 'wrap',
            }}
          >
            {tabConfigArr && <TabSlider tabConfigArr={tabConfigArr} />}

            <ButtonSection buttonConfigArr={buttonConfigArr} />
          </div>
          <hr style={{ opacity: 0.2, borderBottomWidth: '0px', margin: '8px 0 16px' }} />
        </>
      )}
    </>
  );
}

export default PageTopBar;

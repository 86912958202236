import useTranslation, { Iprefix } from './useTranslation';

export default function useListMappingTransform<T>({
  translatePrefix,
  mappingFn,
}: {
  translatePrefix: Iprefix;
  mappingFn: (mode: 'table' | 'export') => (item: T, index: number) => [string, unknown][];
}) {
  const { en_t } = useTranslation(translatePrefix);

  const getListMappingTransform = ({
    mode,
    data,
    omitKeys = [],
  }: {
    mode: 'table' | 'export';
    data: T[];
    omitKeys?: string[];
  }) => {
    return data
      .map((item, index) => mappingFn(mode)(item, index).filter(([key]) => !omitKeys.includes(key)))
      .map(transformDataByMode(mode));
  };

  const transformDataByMode = (mode: 'table' | 'export') => (item: any, index: number) => {
    const initValue = mode === 'table' ? { id: index + 1 } : {};

    const reduceFn = (acc: any, [recursiveKey, value]: [string, any]) => {
      let newObjKey = '';

      if (mode === 'export') {
        newObjKey = en_t(recursiveKey, {});
      } else {
        newObjKey = recursiveKey;
      }

      // const newObjKey = mode === 'table' ? recursiveKey : exportKey;

      let resultVal;

      resultVal = value;

      // Export Enum Without Translation
      // if (mode === 'export' && !/^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(value)) {
      //   const selectedLangEnumMappingsArr = getEnumTranslationEntries(
      //     translateMapping[selectedLang]
      //   );
      //   const englishEnumMappingsArr = getEnumTranslationEntries(en);

      //   for (const [selectedLangKey, selectedLangVal] of selectedLangEnumMappingsArr) {
      //     if (selectedLangVal === value) {
      //       resultVal = englishEnumMappingsArr.find(([engKey]) => selectedLangKey === engKey)?.[1];
      //       break;
      //     }
      //   }
      // }

      return { ...acc, [newObjKey]: resultVal };
    };
    return item.reduce(reduceFn, initValue);
  };

  return getListMappingTransform;
}

import { AddressListGetAllRes } from '../../../../api/addressManagement/addressList/types';
import { useListMappingTransform, useTranslation } from '../../../../hooks';
import useGetNetworkInfo from '../../../../hooks/useGetNetworkInfo';
import { toDisplayTime } from '../../../../utils';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

export const useTransformData = () => {
  const { t, tc } = useTranslation('enumConstants');

  const mappingFn = (_mode: 'table' | 'export') => (item: AddressListGetAllRes) => {
    const mapping: Array<[string, unknown]> = [
      ['rawData', item],
      [TRANSLATE_KEY.displayName, item.walletName],
      [TRANSLATE_KEY.addressGroup, item.walletGroupId],
      [TRANSLATE_KEY.address, item.walletAddress],
      [
        TRANSLATE_KEY.network,
        getNetworkDisplayName({ chainId: item.chainId, chainType: item.chainType }),
      ],
      [TRANSLATE_KEY.addAddressDate, toDisplayTime(item.createdDate)],
    ];

    return mapping;
  };

  const transformData = useListMappingTransform({
    translatePrefix: TRANSLATE_PREFIX,
    mappingFn,
  });

  const { getNetworkDisplayName } = useGetNetworkInfo();

  return { transformData };
};

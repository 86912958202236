export const TRANSLATE_KEY = {
  addressGroup: 'summaryTab.address_group',
  network: 'summaryTab.network',
  token: 'summaryTab.token',
  openingBalance: 'summaryTab.opening_balance',
  date: 'summaryTab.date',
  inAmount: 'summaryTab.in_amount',
  outAmount: 'summaryTab.out_amount',
  txFee: 'summaryTab.tx_fee',
  closingBalance: 'summaryTab.closing_balance',
};

export const TRANSLATE_PREFIX = 'report';

export const OMIT_KEYS = { export: ['rawData'], table: [] };

export const EXPORT_FILE_NAME = 'summary_report';

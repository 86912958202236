import { CSSProperties } from 'react';

import { ReactComponent as CrossIconSvg } from '../../assets/icons/Dialog/dialog-cross.svg';

const CrossIcon = ({
  width = '10px',
  height = '10px',
  style,
  onClick,
}: {
  width?: string;
  height?: string;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  return <CrossIconSvg width={width} height={height} style={style} onClick={onClick} />;
};

export default CrossIcon;

import { CSSProperties } from 'react';

import CrossIcon from '../../Icon/CrossIcon';

const CrossButton = ({ onClick, style }: { onClick: () => void; style?: CSSProperties }) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '2px', ...style }}
      onClick={onClick}
    >
      <CrossIcon />
    </div>
  );
};

export default CrossButton;

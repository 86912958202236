import { ReactComponent as CicleIcon } from '../../../../assets/icons/AddressGroupShapes/circle.svg';
import { ReactComponent as FilledClubIcon } from '../../../../assets/icons/AddressGroupShapes/club-filled.svg';
import { ReactComponent as ClubIcon } from '../../../../assets/icons/AddressGroupShapes/club.svg';
import { ReactComponent as FilledDiamondIcon } from '../../../../assets/icons/AddressGroupShapes/diamond-filled.svg';
import { ReactComponent as DiamondIcon } from '../../../../assets/icons/AddressGroupShapes/diamond.svg';
import { ReactComponent as FireIcon } from '../../../../assets/icons/AddressGroupShapes/fire.svg';
import { ReactComponent as FlagIcon } from '../../../../assets/icons/AddressGroupShapes/flag.svg';
import { ReactComponent as FilledHeartIcon } from '../../../../assets/icons/AddressGroupShapes/heart-filled.svg';
import { ReactComponent as HeartIcon } from '../../../../assets/icons/AddressGroupShapes/heart.svg';
import { ReactComponent as LightningIcon } from '../../../../assets/icons/AddressGroupShapes/lightning.svg';
import { ReactComponent as FilledMusicPlateIcon } from '../../../../assets/icons/AddressGroupShapes/music-plate-filled.svg';
import { ReactComponent as MusicPlateIcon } from '../../../../assets/icons/AddressGroupShapes/music-plate.svg';
import { ReactComponent as PentagonIcon } from '../../../../assets/icons/AddressGroupShapes/pentagon.svg';
import { ReactComponent as FilledSpadeIcon } from '../../../../assets/icons/AddressGroupShapes/spade-filled.svg';
import { ReactComponent as SpadeIcon } from '../../../../assets/icons/AddressGroupShapes/spade.svg';
import { ReactComponent as SquareIcon } from '../../../../assets/icons/AddressGroupShapes/square.svg';
import { ReactComponent as StarIcon } from '../../../../assets/icons/AddressGroupShapes/star.svg';
import { ReactComponent as FilledSuitcaseIcon } from '../../../../assets/icons/AddressGroupShapes/suitcase-filled.svg';
import { ReactComponent as SuitcaseIcon } from '../../../../assets/icons/AddressGroupShapes/suitcase.svg';
import { ReactComponent as FilledTeaIcon } from '../../../../assets/icons/AddressGroupShapes/tea-filled.svg';
import { ReactComponent as TeaIcon } from '../../../../assets/icons/AddressGroupShapes/tea.svg';
import { ReactComponent as TriangleIcon } from '../../../../assets/icons/AddressGroupShapes/triangle.svg';
import { ReactComponent as FilledWalletIcon } from '../../../../assets/icons/AddressGroupShapes/wallet-filled.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/AddressGroupShapes/wallet.svg';
import { SVGIconType } from '../types';

export const SHAPE_OBJ: Record<string, { Icon: SVGIconType; IconFilled?: SVGIconType }> = {
  circle: { Icon: CicleIcon }, // 1
  triangle: { Icon: TriangleIcon }, // 2
  square: { Icon: SquareIcon }, // 3
  pentagon: { Icon: PentagonIcon }, // 4
  star: { Icon: StarIcon }, // 5
  suitcase: { Icon: SuitcaseIcon, IconFilled: FilledSuitcaseIcon }, // 6
  musicPlate: { Icon: MusicPlateIcon, IconFilled: FilledMusicPlateIcon }, // 7
  fire: { Icon: FireIcon }, // 8
  lightning: { Icon: LightningIcon }, // 9
  wallet: { Icon: WalletIcon, IconFilled: FilledWalletIcon }, // 10
  flag: { Icon: FlagIcon }, // 11
  tea: { Icon: TeaIcon, IconFilled: FilledTeaIcon }, // 12
  club: { Icon: ClubIcon, IconFilled: FilledClubIcon }, // 13
  diamond: { Icon: DiamondIcon, IconFilled: FilledDiamondIcon }, // 14
  heart: { Icon: HeartIcon, IconFilled: FilledHeartIcon }, // 15
  spade: { Icon: SpadeIcon, IconFilled: FilledSpadeIcon }, // 16
} as const;

export const DEFAULT_SHAPE = Object.keys(SHAPE_OBJ)[0];

export const COLOR_CODE_ARR = [
  '#D70000', // 1
  '#CC5200', // 2
  '#E1C700', // 3
  '#00D412', // 4
  '#00D9D2', // 5
  '#0059DD', // 6
  '#9D00D7', // 7
  '#E6E6E6', // 8
  '#FF7B7B', // 9
  '#FFB77F', // 10
  '#F6FF97', // 11
  '#73FFAD', // 12
  '#AFD1FF', // 13
  '#8F6DFF', // 14
  '#FF73CE', // 15
  '#8D8D8D', // 16
];

export const DEFAULT_COLOR_CODE = COLOR_CODE_ARR[0];

export const TRANSLATE_KEY = {
  group: 'group',
  createNewGroup: 'create_new_group',
  editGroup: 'edit_group',
  viewBalance: 'view_balance',
  viewTransaction: 'view_transaction',
};

export const TRANSLATE_PREFIX = 'addressGroup';

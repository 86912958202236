import { TransactionDetailsRes } from '../../../../../../api/report/transaction/types';
import ActionButtons from '../../../../../../common/components/Dialog/components/ActionButtons';
import { DialogInOne } from '../../../../../../components';
import Content from './Content';

function DialogDetails({
  data,
  onClose,
}: {
  data: TransactionDetailsRes | undefined;
  onClose: () => void;
}) {
  if (!data) return <></>;

  return (
    <DialogInOne
      title={''}
      self={{ open: true, onClose }}
      content={<Content data={data} />}
      isShowCrossButton
      isBlackBackdrop
      // isTitleCentered
      dialogWidth={{ width: '716px' }}
      isCancelHidden
      isConfirmHidden
      actionButtons={<ActionButtons onCancel={onClose} />}
      actionBtnContainerStyling={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        padding: '40px 24px 24px',
      }}
      onConfirm={() => {}}
      onCancel={() => {}}
    />
  );
}

export default DialogDetails;

import { isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import APIs from '../../../../api';
import { useAlerting, useFileUpload, useTranslation } from '../../../../hooks';
import useLoading from '../../../../hooks/useLoading';
import { selectAddressGroup } from '../../../../reducer/addressGroupSlice';
import { selectAsset } from '../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

export type ImportAddressLog = {
  status: 'fail' | 'duplicated' | 'success';
  address: string;
  network: string;
  address_group: string;
  display_name: string;
  error_message?: string;
};

function useImportExcel({ getNewRefreshDependency }: { getNewRefreshDependency: () => void }) {
  const { onFileChange, fileContent, setFileContent } = useFileUpload();
  const { t } = useTranslation(TRANSLATE_PREFIX);
  const { showLoading, hideLoading } = useLoading();

  const [importResultLogData, setImportResultLogData] = useState<ImportAddressLog[]>([]);
  const [isImportResultDialogOpen, setIsImportResultDialogOpen] = useState(false);

  const { alerting } = useAlerting();

  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);
  const { merchantSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);

  const importExcel = useCallback(async () => {
    const [keyArr, ...contentArr] = fileContent;

    if (
      JSON.stringify(keyArr) !==
      JSON.stringify(['display_name', 'address_group', 'address', 'network'])
    ) {
      return alerting('warning', t(TRANSLATE_KEY.importAddressFormatErrorMessage));
    }

    const transformedContentArr: {
      display_name: string;
      address_group: string;
      address: string;
      network: string;
    }[] = contentArr.map((dataArr: string[]) =>
      keyArr.reduce(
        (acc: Record<string, string>, cur: string, index: number) => ({
          ...acc,
          [cur]: dataArr[index],
        }),
        {}
      )
    );

    setImportResultLogData([]);

    showLoading(TRANSLATE_PREFIX);

    for (const data of transformedContentArr) {
      const walletGroupId = addressGroupNameMapping[data.address_group]?.id;

      const { chainId, chainType } = merchantSupportedAssetChainCodeMapping[data.network] || {};

      if (!walletGroupId) {
        setImportResultLogData((prev) => [
          ...prev,
          {
            status: 'fail',
            ...data,
            error_message: t(TRANSLATE_KEY.importAddressAddressGroupErrorMessage),
          },
        ]);
        continue;
      }

      if (!chainId || isNil(chainType)) {
        setImportResultLogData((prev) => [
          ...prev,
          {
            status: 'fail',
            ...data,
            error_message: t(TRANSLATE_KEY.importAddressNetworkErrorMessage),
          },
        ]);
        continue;
      }

      const res = await APIs.addressManagement.addressList.createAddress(
        {
          walletName: data.display_name,
          walletAddress: data.address,
          walletGroupId,
          chainId,
          chainType,
        },
        { isSkipErrorHandling: true }
      );

      if (res?.error) {
        if (res.error.code === -51) {
          setImportResultLogData((prev) => [
            ...prev,
            {
              status: 'duplicated',
              ...data,
              error_message: res.error.message,
            },
          ]);
          continue;
        }

        setImportResultLogData((prev) => [
          ...prev,
          {
            status: 'fail',
            ...data,
            error_message: res.error.message,
          },
        ]);
        continue;
      }

      setImportResultLogData((prev) => [
        ...prev,
        {
          status: 'success',
          ...data,
        },
      ]);
    }

    hideLoading(TRANSLATE_PREFIX);

    setIsImportResultDialogOpen(true);

    setFileContent(undefined);
  }, [fileContent]);

  useEffect(() => {
    if (!fileContent) {
      return;
    }

    importExcel();
  }, [fileContent]);

  return {
    onFileChange,
    setFileContent,
    importResultLogData,
    isImportResultDialogOpen,
    onImportResultDialogClose: () => {
      setIsImportResultDialogOpen(false);
      setImportResultLogData([]);
      getNewRefreshDependency();
    },
  };
}

export default useImportExcel;

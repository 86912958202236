import { CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING, ChainCode } from '../../../../api/query/types';
import GeneralSelection from '../../../../components/Selection/GeneralSelection';
import { useTranslation } from '../../../../hooks';
import { selectAsset } from '../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function NetworkSelection({
  label,
  ...props
}: { label?: string } & (
  | {
      mode: 'single';
      value: string;
      onChange: (value: string) => void;
      clearSelect: () => void;
    }
  | {
      mode: 'multiple';
      value: string[];
      onChange: (value: string[]) => void;
    }
)) {
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);

  return (
    <GeneralSelection
      label={label || tc('phSelection', { fieldName: t(TRANSLATE_KEY.network) })}
      enumData={clientSupportedAssetChainCodeMapping}
      nameFn={(name) => CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING[name as ChainCode]}
      isNoSorting
      searchMode="includes"
      {...props}
    />
  );
}

export default NetworkSelection;

import { DataGrid, DataGridProps } from '@mui/x-data-grid';

import { useTranslation } from '../../hooks';
import { importantStyle } from '../../utils';
import { dataGridDefaults } from '../../utils/constant';
import CustomPagination from './CustomPagination';
import NoRowsOverlay from './NoRowsOverlay';

function CustomDataGrid({
  isLoading,
  isApplyFilterRequired = false,
  ...props
}: DataGridProps & { isLoading: boolean; isApplyFilterRequired?: boolean }) {
  const { t } = useTranslation('component');

  const filterNotAppliedWording = isApplyFilterRequired
    ? t('apply_filter_to_view_records')
    : undefined;

  return (
    <DataGrid
      {...dataGridDefaults}
      sx={{
        '.MuiDataGrid-main ': {
          '>div:first-of-type:not(.MuiDataGrid-columnHeaders)': {
            width: importantStyle('100%'),
          },
        },
        border: '1px solid #474747',
        '.MuiDataGrid-columnHeaders': {
          borderBottom: 0,
        },
        '.MuiDataGrid-columnHeader': {
          padding: '0 20px',
          borderRight: '1px solid #474747',
        },
        '.MuiDataGrid-columnHeadersInner div div:last-of-type': {
          borderRight: 0,
        },

        '.MuiDataGrid-row': {
          border: 0,

          borderBottom: '1px solid #474747',
          boxSizing: 'border-box',
        },

        '.MuiDataGrid-cell': {
          padding: '0 20px',

          border: importantStyle(0),
        },
        '.MuiDataGrid-virtualScrollerContent div:last-of-type': {
          border: 0,
        },
      }}
      components={{
        NoRowsOverlay,
        Footer: filterNotAppliedWording ? () => <></> : CustomPagination,
      }}
      componentsProps={{
        footer: { totalRecords: props.rowCount },
        noRowsOverlay: { isLoading, customWording: filterNotAppliedWording },
      }}
      {...props}
    />
  );
}

export default CustomDataGrid;

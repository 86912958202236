import { useState } from 'react';

import APIs from '../../../../../../api';
import ActionButtons from '../../../../../../common/components/Dialog/components/ActionButtons';
import ConfirmDeleteDialog from '../../../../../../common/components/Dialog/ConfirmDeleteDialog';
import { DialogInOne } from '../../../../../../components';
import UnderlinedButton from '../../../../../../components/Button/UnderlinedButton';
import { useAlerting, useTranslation } from '../../../../../../hooks';
import useVerifyField from '../../../../../../hooks/useVerifyField';
import {
  deleteAddressGroup,
  upsertAddressGroup,
} from '../../../../../../reducer/addressGroupSlice';
import { useAppDispatch } from '../../../../../../reducer/hooks';
import { TRANSLATE_PREFIX } from '../../../constants';
import { AddressGroupData } from '../../../types';
import Content, { initialAddressGroupData } from '../components/Content';
import { TRANSLATE_KEY } from '../constants';

function DialogEditGroup({
  onClose,
  data,
  onRefresh,
}: {
  onClose: () => void;
  data: AddressGroupData | undefined;
  onRefresh: () => void;
}) {
  const [addressGroupData, setAddressGroupData] = useState<AddressGroupData>(
    data || { ...initialAddressGroupData, id: '' }
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { alerting } = useAlerting();

  const { verifyIsNoChangesMade, verifyHasEmptyField } = useVerifyField('addressGroup');
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const onConfirm = async () => {
    const { name: walletGroupName, ...rest } = addressGroupData;

    const alertMessage =
      verifyIsNoChangesMade({ prevData: data, curData: addressGroupData }) ||
      verifyHasEmptyField([
        { value: addressGroupData.name, mode: 'input', fieldTranslateKey: TRANSLATE_KEY.groupName },
      ]);

    if (alertMessage) {
      return alerting('warning', alertMessage);
    }

    const icon = JSON.stringify(rest);

    const res = await APIs.addressManagement.addressGroup.updateAddressGroup({
      walletGroupName,
      icon,
      walletGroupId: data?.id || '',
    });

    if (!res) {
      return;
    }

    dispatch(upsertAddressGroup({ ...addressGroupData, id: data?.id || '' }));

    alerting('success', tc('edit_success_message', { itemName: t(TRANSLATE_KEY.group) }));
    onRefresh();
    onClose();
  };

  const onDelete = async () => {
    const res = await APIs.addressManagement.addressGroup.deleteAddressGroup({
      walletGroupId: data?.id || '',
    });

    if (!res) {
      return;
    }

    dispatch(deleteAddressGroup({ name: addressGroupData.name, id: data?.id || '' }));

    alerting('success', tc('delete_success_message', { itemName: t(TRANSLATE_KEY.group) }));
    onRefresh();
    onClose();
  };

  return (
    <DialogInOne
      title={t(TRANSLATE_KEY.editGroup)}
      self={{ open: true, onClose }}
      content={
        <>
          <ConfirmDeleteDialog
            title={t(TRANSLATE_KEY.deleteGroup)}
            isOpen={isDeleteDialogOpen}
            onDelete={onDelete}
            onClose={() => {
              setIsDeleteDialogOpen((prev) => !prev);
            }}
          />
          <Content addressGroupData={addressGroupData} setAddressGroupData={setAddressGroupData} />
        </>
      }
      isShowCrossButton
      isBlackBackdrop
      isTitleCentered
      dialogWidth={{ width: '512px' }}
      isCancelHidden
      isConfirmHidden
      actionButtons={
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              width: '100%',
            }}
          >
            <ActionButtons onConfirm={onConfirm} onCancel={onClose} />
          </div>
          <UnderlinedButton
            color="#ff0000"
            fontSize="14px"
            onClick={() => {
              setIsDeleteDialogOpen(true);
            }}
            node={tc('remove_this_item', { itemName: t(TRANSLATE_KEY.group) })}
          />
        </>
      }
      actionBtnContainerStyling={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '40px 24px 24px',
      }}
      onConfirm={() => {}}
      onCancel={() => {}}
    />
  );
}

export default DialogEditGroup;

import { useTranslation } from '../../../../hooks';
import useValidation from '../../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../../hooks/useValidation/types';
import { loginEmailValidator } from '../../../../utils/validators/loginEmailValidator';
import { requiredValidator } from '../../../../utils/validators/requiredValidator';
import { LoginFields, LoginFieldsKeys } from '../types';

interface IProps {
  fields: LoginFields;
}

const useEnterEmailValidation = (props: IProps) => {
  const { fields } = props;

  const { translate, t } = useTranslation('login');

  const validationConfig: ValidationConfigProps = [
    {
      key: LoginFieldsKeys.Email,
      value: fields[LoginFieldsKeys.Email].trim(),
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('enterEmail.companyEmail'),
          })
        ),
        loginEmailValidator(translate('validation.loginEmailInvalid')),
      ],
    },
    {
      key: LoginFieldsKeys.ClientId,
      value: fields[LoginFieldsKeys.ClientId].trim(),
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('enterEmail.clientId'),
          })
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useEnterEmailValidation;

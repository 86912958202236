import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import { readVerificationToken } from '../../utils';
import axios from '../axiosInstance';
import { LoginRes } from './types';

export enum RequestType {
  unknown = -1,
  login = 1,
}

const { auth: endPoint } = EPt;

const checkUser = async (
  params: { email: string; clientId: string; merchantId: string },
  config = {}
) => {
  const email = params.email.trim();

  const input = { params: { ...params, email }, ...config };

  const res = await axios.get<never, any>(endPoint.checkUser, input);
  return res;
};

const requestVerifyToken = async (
  params: { email: string; clientId: string; merchantId: string; requestType: RequestType },
  config = {}
) => {
  const email = params.email.trim();

  const input = { ...params, email, ...config };

  const res = await axios.post<never, { token: string } | undefined>(
    endPoint.requestVerifyToken,
    input
  );
  return res;
};

const requestEmailVerificationCode = async (params: { lang: string }, config = {}) => {
  const verificationToken = readVerificationToken();

  const input = { ...params, ...config };

  const res = await axios.post<never, any>(endPoint.requestEmailCode, input, {
    headers: {
      Authorization: `Bearer ${verificationToken}`,
    },
  });
  return res;
};
const verifyEmailVerificationCode = async (params: { code: string }, config = {}) => {
  const verificationToken = readVerificationToken();

  const input = { ...params, ...config };

  const res = await axios.post<never, { verifyResult: boolean; token: string } | undefined>(
    endPoint.verifyEmailCode,
    input,
    {
      headers: {
        Authorization: `Bearer ${verificationToken}`,
      },
    }
  );
  return res;
};

const login = async (config = {}) => {
  const verificationToken = readVerificationToken();
  const input = { ...config };

  const res = await axios.post<never, LoginRes | undefined>(endPoint.login, input, {
    headers: {
      Authorization: `Bearer ${verificationToken}`,
    },
  });

  return res;
};

const refreshToken = async (refreshToken: string, config = {}) => {
  return await axios.post<never, any>(endPoint.refreshToken, { refreshToken }, {
    ...config,
    ignoreToken: true,
  } as any);
};

export default {
  checkUser,
  requestVerifyToken,
  requestEmailVerificationCode,
  verifyEmailVerificationCode,
  login,
  refreshToken,
};

import './index.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography } from '@mui/material';

import { forceLogout } from '../../../api/axiosInstance';
import { useAutoLogout, useIsActive, useTranslation } from '../../../hooks';
import { useGetAddressGroup } from '../../../hooks/useAddressGroup';
import { useGetSupportedAsset } from '../../../hooks/useAsset';
import useMedia from '../../../hooks/useMedia';
import { useAppDispatch, useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import { selectProfile } from '../../../reducer/profileSlice';
import { selectStuff, setIsRefreshingToken } from '../../../reducer/stuffSlice';
import { COLOR_THEME } from '../../../style/colorTheme';
import {
  enumMapping,
  getIsRedirectLoginPath,
  importantStyle
} from '../../../utils';
import { enumTimezone } from '../../../utils/constant';
import { Content, ContentWithSidebar } from '../../../utils/styling';
import { useZusMenuCollapsedStore } from '../../../zustand/store';
import LoadingDialogProvider from '../LoadingDialogProvider';
import Menu from '../Menu';
import SideMenu from '../SideMenu';
import TopRightBar from './TopRightBar';

const Enum = {
  timezones: enumMapping(enumTimezone),
};
const sx: Record<string, any> = {
  AppBarSpan: {
    display: 'flex',
    backgroundColor: 'white',
  },
  SideBarParent: {
    minHeight: '100vh',
    zIndex: 2,
  },
  topRightBar: {
    position: 'absolute',
    height: '28px',
    top: '1rem',
    right: '.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& Button.MuiButton-text': {
      fontSize: '1rem',
    },
  },
  topLeftBar: {
    position: 'absolute',
    top: '1rem',
    left: '345px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '1px 8px',
  },
  email: {
    color: COLOR_THEME.Layout.LayoutBar.Text,
    fontSize: '1rem',
  },
  pageName: {
    fontSize: '1rem',
    color: importantStyle('#ffffff'),
  },
} as const;

export default function LayoutBar(props: any) {
  const { t, te, tc } = useTranslation('menu');

  const isMobileView = useAppSelector(selectIsMobileView);

  // const { shouldShowFirstLoginPage } = useAppSelector(selectAsset);

  const [isSideMenuCollapse, setIsSideMenuCollapse] = useState(isMobileView);
  const [isFirstClickMobileMenu, setIsFirstClickMobileMenu] = useState(true);
  // const [isShowSideMenu, setIsShowSideMenu] = useState(false);
  const zusMenuCollapsed = useZusMenuCollapsedStore();

  const dispatch = useAppDispatch();
  const { appName, email, timezone, jwtDetails } = useAppSelector(selectProfile);
  const { programAgentId } = jwtDetails || {};

  const { viewPermission } = useAppSelector(selectStuff);
  const { isRefreshingToken } = useAppSelector(selectStuff);
  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;

  const redirect = useNavigate();

  useMedia();
  useAutoLogout();
  useIsActive();

  const { isFetchingSupportedAsset } = useGetSupportedAsset({ shouldFetch: true });

  useGetAddressGroup({ shouldFetch: true });

  // useFetchProgram({ shouldFetch: isLogin });
  // useAgentRoles({ shouldFetch: isLogin });
  // useSystemCurrency({ shouldFetch: isLogin });
  // useAgentCurrency({ shouldFetch: isLogin });
  // useAgentProgramCurrency({ shouldFetch: isLogin });

  // useAccountSetting() // only call once

  // prevent isRefreshingToken dead lock
  useEffect(() => {
    const TIMEOUT_SECOND = 10 * 1000;

    let timeoutId: ReturnType<typeof setTimeout>;

    if (isRefreshingToken) {
      timeoutId = setTimeout(() => {
        dispatch(setIsRefreshingToken(false));
      }, TIMEOUT_SECOND);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRefreshingToken]);

  const logoutFn = () => {
    forceLogout('', { from: 'menu-button' });
  };

  const onCollapseClick = () => {
    setIsSideMenuCollapse(!isSideMenuCollapse);
  };

  const getTopMenuHeaderStyle = () => {
    return {
      left: '8px',
      // transition: `left ${
      //   isSideMenuCollapse ? '0.2s 0.1s' : '0.1s 0s'
      // } cubic-bezier(0.0, 0.0, 0.2, 1)`,
    };
  };

  // adapt to side menu position
  const topMenuHeaderStyle = getTopMenuHeaderStyle();

  useEffect(() => {
    // if (isMobileView) {
    //   setIsShowSideMenu(false);
    if (isMobileView) {
      setIsSideMenuCollapse(true);
    }
    // } else {
    //   setIsShowSideMenu(true);
    // }
  }, [isMobileView]);

  useEffect(() => {
    zusMenuCollapsed.setIsMenuCollapsed(isSideMenuCollapse);
  }, [isSideMenuCollapse]);

  if (getIsRedirectLoginPath()) return <>{props.children}</>;

  if (isFetchingSupportedAsset) return <></>;

  return (
    <>
      <LoadingDialogProvider isLoadingDialogOpen={isRefreshingToken} />
      {/* a container tried to prevent overflow when menu is open */}
      <div
        // style={{
        //   ...(isShowSideMenuInMobileView && isMobileView
        //     ? { height: '100dvh', overflow: 'hidden' }
        //     : {}),
        // }}
        className={!isSideMenuCollapse && isMobileView ? 'content-with-sidebar-container' : ''}
      >
        <ContentWithSidebar>
          {isLogin && (
            <>
              {/* Side Menu */}
              {/* {isShowSideMenu && ( */}
              <Box
                style={{
                  ...sx.SideBarParent,
                  // position: 'relative',
                }}
              >
                {/* {!shouldShowFirstLoginPage && ( */}
                <SideMenu
                  isCollapse={isSideMenuCollapse}
                  onCollapseClick={onCollapseClick}
                  isMobileView={isMobileView}
                  isFirstClickMobileMenu={isFirstClickMobileMenu}
                  // isShowCollapseButton={!isMobileView}
                >
                  <Menu
                    closeMobileSideMenu={() => {
                      if (!isMobileView) return;

                      // setIsShowSideMenu(false);
                      setIsSideMenuCollapse(true);
                    }}
                  />
                </SideMenu>
                {/* )} */}
              </Box>
              {/* )} */}

              {/* Black overlay for mobile side menu */}
              {isMobileView && !isSideMenuCollapse && (
                <div
                  // style={{
                  //   position: 'absolute',
                  //   width: '100vw',
                  //   height: '100dvh',
                  //   background: 'rgba(0,0,0,0.8)',
                  //   zIndex: 1,
                  //   transition: 'opacity 0.2s cubic-bezier(0.0, 0.0, 0.2, 1)',
                  // }}
                  className="side-menu-overlay"
                  onClick={() => {
                    setIsSideMenuCollapse(true);
                  }}
                />
              )}

              {isMobileView && (
                <Box style={{ ...sx.topLeftBar, ...topMenuHeaderStyle }}>
                  {/* side menu icon for mobile */}

                  <Box
                    sx={{
                      width: '27px',
                      height: '27px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transform: 'translateY(-1px)',
                      svg: {
                        width: '100%',
                        height: '100%',
                      },
                    }}
                    onClick={() => {
                      // setIsShowSideMenu(!isShowSideMenu);
                      setIsSideMenuCollapse(false);
                      setIsFirstClickMobileMenu(false);
                    }}
                  >
                    <MenuIcon />
                  </Box>

                  {/* Program Agent Id  */}
                  {/* <Typography variant="caption" sx={sx.appName}>
                  {programAgentId}
                </Typography> */}
                  <Typography variant="caption" sx={sx.pageName}>
                    {viewPermission && t(viewPermission)}
                  </Typography>
                </Box>
              )}

              <TopRightBar />
            </>
          )}
          <Content
            style={
              isMobileView
                ? { paddingLeft: '0', paddingRight: '0', overflowX: 'hidden' }
                : { position: 'relative' }
            }
          >
            <Typography
              variant="caption"
              sx={{
                ...sx.pageName,
                position: 'absolute',
                zIndex: 99,
                top: 22,
                left: 32,
                display: isMobileView ? 'none' : undefined,
              }}
            >
              {viewPermission && t(viewPermission)}
            </Typography>
            {props.children}
          </Content>
        </ContentWithSidebar>
      </div>
    </>
  );
}

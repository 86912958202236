import logo from '../../../../assets/logo/abcc_wallet_login_logo.png';

const Logo = () => {
  return (
    <div className="logo-container">
      <div
        style={{
          width: '100%',
          height: '78px',
          background: `url(${logo}) no-repeat center center / cover`,
        }}
      />
    </div>
  );
};

export default Logo;

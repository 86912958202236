import { useNavigate } from 'react-router-dom';

import logo from '../assets/logo/side_menu_logo.png';

interface IProps {
  closeMobileSideMenu: () => void;
}

const Logo = ({ closeMobileSideMenu }: IProps) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={() => {
        closeMobileSideMenu();
        navigate('/');
      }}
    >
      <div style={{ width: '80%', height: '49px', userSelect: 'none' }}>
        <img src={logo} alt="logo" style={{ width: '100%' }} />
      </div>

      {/* <div style={{ marginLeft: '12px', textAlign: 'left', fontFamily: 'Poppins' }}>
        <div
          style={{
            marginBottom: '10px',
            fontSize: '42px',
            lineHeight: '26px',
            userSelect: 'none',
          }}
        >
          AMS
        </div>
        <div style={{ fontSize: '10px', letterSpacing: '2px', userSelect: 'none' }}>
          Agent Management System
        </div>
      </div> */}
    </div>
  );
};

export default Logo;

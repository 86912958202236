import { useNavigate } from 'react-router-dom';

import CustomColorButton from '../../../../components/Button/CustomColorButton';
import { useTranslation } from '../../../../hooks';
import {
  selectAsset,
  setShouldShowFirstLoginPage
} from '../../../../reducer/assetSlice';
import { useAppDispatch, useAppSelector } from '../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../reducer/mediaSlice';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function ButtonBar({ selectedAssets }: { selectedAssets: Record<string, string[]> }) {
  const { clientSupportedAssetChainCodeMapping, shouldShowFirstLoginPage } =
    useAppSelector(selectAsset);
  const navigate = useNavigate();

  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(selectIsMobileView);

  const onNext = () => {
    dispatch(setShouldShowFirstLoginPage(false));
    navigate('/');
  };

  const getIsNextBtnDisabled = () => {
    const dbAssetList = Object.entries(clientSupportedAssetChainCodeMapping);
    const selectedAssetList = Object.entries(selectedAssets);

    const isSameArrSize = dbAssetList.length === selectedAssetList.length;

    const isAllAssetsSame =
      dbAssetList.every(([dbChainCode, { assetNameList }]) => {
        return assetNameList.every((dbAsset) =>
          selectedAssetList
            .find(([selectedChainCode]) => dbChainCode === selectedChainCode)?.[1]
            .includes(dbAsset)
        );
      }) && isSameArrSize;

    return !isAllAssetsSame || !dbAssetList.length;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '8px',
        marginBottom: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div>{t(TRANSLATE_KEY.instructionText)}</div>
        {/* <div style={{ display: 'flex', gap: '16px' }}> */}
        {shouldShowFirstLoginPage && (
          <CustomColorButton
            sx={{
              height: '32px',
              width: isMobile ? '75px' : '135px',
              fontSize: '14px',
            }}
            onClick={onNext}
            color={{ background: '#FFD100', text: '#000000' }}
            variant={'contained'}
            node={tc('next')}
            disabled={getIsNextBtnDisabled()}
            // disabled={!Object.entries(clientSupportedAssetObj).length}
          />
        )}

        {/* <CustomColorButton
          sx={{
            height: '32px',
            width: '95px',
          }}
          onClick={onResetOrDefault}
          color={{ border: '#474747', text: '#ffffff' }}
          node={isFirstLogin ? 'Default' : 'Reset'}
          variant={'outlined'}
        />  </div> */}
      </div>
      {shouldShowFirstLoginPage && (
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'flex-end' }}
        >
          <div style={{ color: '#ff0000' }}>*Please confirm the selected asset(s)</div>
        </div>
      )}
    </div>
  );
}

export default ButtonBar;

import { TransactionDetailsRes } from '../../../../../../api/report/transaction/types';
import AddressInformation from './components/AddressInformationSection';
import ChainInformation from './components/ChainInformation';
import TransactionDetailsSection from './components/TransactionDetailsSection';

function Content({ data }: { data: TransactionDetailsRes }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <TransactionDetailsSection data={data} />
      <AddressInformation data={data} />
      <ChainInformation data={data} />
    </div>
  );
}

export default Content;

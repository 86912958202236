import '../../../src/App.module.scss';

import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { ReactComponent as HomeIcon } from '../../assets/icons/SideMenu/home.svg';
import { usePermission, useTranslation } from '../../hooks';
import { routeList } from '../../hooks/useRoutePermission';
import { useAppSelector } from '../../reducer/hooks';
import { selectIsMobileView } from '../../reducer/mediaSlice';
import { COLOR_THEME } from '../../style/colorTheme';
import { hexToRGB, importantStyle } from '../../utils';
import { versionNumber } from '../../utils/constant';
import { useZusListStore } from '../../zustand/store';
import Logo from '../Logo';
import MenuItem from './MenuItem';

const sx = {
  logo: {
    padding: '68px 20px 32px',
  },
  expanded: {
    color: COLOR_THEME.Menu.Secondary.Text,
    boxShadow: 'none',
    background: COLOR_THEME.Menu.Background,
  },
  notExpanded: {
    color: COLOR_THEME.Menu.Secondary.Text,
    boxShadow: 'none',
    background: COLOR_THEME.Menu.Background,
  },
  accordionSummary: {
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionDetails: {
    padding: '5px',
    a: {
      color: `rgba(${hexToRGB(COLOR_THEME.Menu.Secondary.Text)}, 0.7)`,
      '&.RaMenuItemLink-active': {
        color: COLOR_THEME.Menu.Primary.Text,
      },
    },
    'div:hover': {
      a: {
        backgroundColor: importantStyle(COLOR_THEME.Menu.HoverItem),
        color: COLOR_THEME.Menu.Secondary.Text,
        '&.RaMenuItemLink-active': {
          color: COLOR_THEME.Menu.Primary.Text,
        },
      },
    },
    '.divide-line-menu': {
      width: 'calc(var(--side-menu-width) * 0.9)',
      height: '1px',
      background: '#6c768e',
      position: 'absolute',
      bottom: '-12px',
      left: 'calc(50% - 5px)',
      transform: 'translateX(-50%)',
      transition: 'opacity 0.2s cubic-bezier(0, 0, 0.2, 1)',
      opacity: 1,
    },
  },
};

const getAccordionStyle = (isTitleColored: boolean) => {
  const titleColor = isTitleColored
    ? COLOR_THEME.Menu.Primary.Text
    : COLOR_THEME.Menu.Secondary.Text;

  return {
    '&.MuiAccordion-root:before': { content: 'none' },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(270deg)',
      '>svg': { color: importantStyle(titleColor) },
      '&.Mui-expanded': {
        transform: 'rotate(360deg)',
      },
    }, // color for menu stack title svg
    '& .MuiAccordionSummary-content': {
      h6: { color: importantStyle(titleColor) },
    }, // color for menu stack title
    '& .MuiAccordionSummary-root.Mui-expanded': {
      maxHeight: '30px',
      margin: '5px 0px',
    },
    minWidth: '250px;',
  };
};

export default function Menu(props: any) {
  const { t } = useTranslation('menu');
  // const t = (key: string) => translate(`menu.${key}`);
  const [selectedMenus, setSelectedMenus] = useState<string[]>([]);

  const isMobile = useAppSelector(selectIsMobileView);
  const { currentLanguage } = useTranslation();
  const navigate = useNavigate();

  const L = routeList;

  const getCurrentActiveMenu = (type: string) => {
    const currentPath = window.location.pathname.replace('/', '');

    for (const [_, info] of Object.entries(menuInfos)) {
      for (const routeArr of info.routeInfo) {
        if (routeArr[1] === currentPath) {
          if (type === 'parent') return info.title;
          if (type === 'children') return routeArr[0];
        }
      }
    }
  };

  const filterRouteInfoByMedia = (routeInfo: any) => {
    if (!isMobile) return routeInfo;

    const mobileHiddenRoute = [
      // Setting
      'distributorAgentList',

      // Program Agent Management
      'programAgentWallet',
      'creditSwapRecord',

      // Client Management
      'clientApplication',

      // Report
      'programAgentReport',
      'programAgentReportDetail',
      'distributorAgentReport',
      'distributorAgentReportDetail',
      'clientReport',
      'clientReportDetail',
      'creditAllocationRecord',
      'monthlyRebateReport',
      'customerSpendingReport',
    ];

    return routeInfo.filter((routeArr: any) => {
      const route = routeArr[0];

      if (mobileHiddenRoute.includes(route)) return false;

      return true;
    });
  };

  //###add-new-page
  const menuInfos = {
    addressManagement: {
      title: t('addressManagement'),
      routeInfo: [L.asset, L.addressGroup, L.addressList, L.addressBalance],
    },
    report: {
      title: t('report'),
      routeInfo: [L.transaction, L.report],
    },
  };

  // check which item is currently active and expand the menu stack
  useEffect(() => {
    const currentActiveMenuStack = getCurrentActiveMenu('parent');

    setSelectedMenus([currentActiveMenuStack || '']);
  }, []);

  const isHomePage = window.location.pathname.substring(1) === '';

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          fontSize: '11px',
          top: '22px',
          left: '16px',
        }}
      >
        {versionNumber}
      </div>
      <div style={sx.logo}>
        <Logo closeMobileSideMenu={props.closeMobileSideMenu} />
      </div>
      <div
        style={{
          height: '100%',
          overflowY: 'auto',
          maxHeight: 'calc((100dvh - 163px) * 0.8)',
        }}
      >
        {/* Dashboard */}
        <MenuItem
          sx={{ paddingBottom: '12px' }}
          icon={
            <HomeIcon
              fill={isHomePage ? '#EDA30E' : '#222222'}
              style={{ color: isHomePage ? '#EDA30E' : undefined }}
            />
          }
          title={t('homepage')}
          permission={L['homepage'][2]}
          isActive={isHomePage}
          onClick={() => {
            navigate(L['homepage'][0]);
            props.closeMobileSideMenu();
          }}
          isLargeItem
        />

        {/* MenuStacks */}
        <>
          {
            // loop through menuInfos and render each menu stack
            Object.entries(menuInfos).map(([key, info], i, arr) => {
              return (
                <MenuStack
                  key={key}
                  selectedMenus={selectedMenus}
                  setSelectedMenus={setSelectedMenus}
                  title={info.title}
                  list={filterRouteInfoByMedia(info.routeInfo)}
                  closeMobileSideMenu={props.closeMobileSideMenu}
                  isLastStack={i === arr.length - 1}
                />
              );
            })
          }
        </>

        {/* Version Number */}
      </div>
    </div>
    // </RaMenu>
  );
}

export function MenuStack(props: any) {
  const { title, list, selectedMenus, setSelectedMenus, isLastStack } = props;

  const location = useLocation();

  // const [selectedPagePathname, setSelectedPagePathname] = useState(location.pathname.substring(1));

  const selectedPagePathname = location.pathname.substring(1);

  const { hasPermission } = usePermission();
  const zusListStore = useZusListStore();
  const { t } = useTranslation('menu');
  const navigate = useNavigate();

  const resetZusList = () => zusListStore.clear();

  const expanded = selectedMenus.includes(title);

  const toggle = () => {
    setSelectedMenus((prev: string[]) => {
      if (prev.includes(title)) return prev.filter((menu) => menu !== title);
      return [...prev, title];
    });
  };

  const canRender = (key: string) => {
    if (key === '*****' || key === '') return true; //for temporary use
    return hasPermission(key);
  };

  const genItem = ([text, to, permission]: any = []) => {
    const handleMenuItemClick = () => {
      navigate(to);
      if (selectedPagePathname !== to) {
        resetZusList();
        // setSelectedPagePathname(to);
        props.closeMobileSideMenu && props.closeMobileSideMenu();
      }
    };

    return (
      canRender(permission) && (
        <MenuItem
          key={uniqueId(text)}
          title={t(text)}
          onClick={handleMenuItemClick}
          isActive={selectedPagePathname === to}
        />
      )
    );
  };

  const subMenus = list.map(genItem);
  const isHidden = subMenus.filter((dom: any) => dom).length === 0;

  const accordionStyle = getAccordionStyle(
    list?.find(([_text, to]: any) => to === selectedPagePathname) && !expanded
  );

  if (isHidden) return <></>;

  return (
    <Accordion
      sx={{
        color: expanded ? sx.expanded : sx.notExpanded,
        paddingLeft: '10px',
        ...accordionStyle,
      }}
      expanded={expanded}
      onChange={toggle}
    >
      <AccordionSummary
        expandIcon={<ExpandCircleDownIcon />}
        aria-controls={title}
        sx={sx.accordionSummary}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={sx.accordionDetails}>
        <Typography variant="h6" sx={{ position: 'relative' }}>
          {list.map(genItem)}
          {!isLastStack && <div className="divide-line-menu"></div>}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export enum LoginFieldsKeys {
  Email = 'email',
  Password = 'password',
  EmailVerificationCode = 'emailVerificationCode',
  NewPassword = 'newPassword',
  ConfirmNewPassword = 'confirmNewPassword',
  // optional field, only show when url have no programAgentId
  ClientId = 'clientId',
}

export interface LoginFields {
  [LoginFieldsKeys.Email]: string;
  [LoginFieldsKeys.Password]: string;
  [LoginFieldsKeys.EmailVerificationCode]: string;
  [LoginFieldsKeys.NewPassword]: string;
  [LoginFieldsKeys.ConfirmNewPassword]: string;
  [LoginFieldsKeys.ClientId]: string;
}

export enum LoginPageShareContextKeys {
  IsDeviceRegistered = 'isDeviceRegistered',
}
export interface LoginPageShareContext {
  [LoginPageShareContextKeys.IsDeviceRegistered]: boolean;
}

export enum PageKeys {
  EnterEmail = 'EnterEmail',
  EmailVerification = 'EmailVerification',
  ResetPassword = 'ResetPassword',
  EnterPassword = 'EnterPassword',
  EnterPasswordWithVerify = 'EnterPasswordWithVerify',
  ForgotPassword = 'ForgotPassword',
}

import CustomColorButton from '../../../../../Button/CustomColorButton';

function ApplyButton({ onApply }: { onApply: () => void }) {
  return (
    <CustomColorButton
      sx={{ width: '95px', height: '32px', fontSize: '14px' }}
      variant="contained"
      node={'Apply'}
      color={{ text: '#000000', background: '#ffffff' }}
      onClick={onApply}
    />
  );
}

export default ApplyButton;

import { isNil } from 'lodash';
import { CSSProperties, RefObject } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

import { hexToRGB, importantStyle } from '../../utils';
import { inputCharacterMaxLength } from '../../utils/constant';

export type TextFieldStyling = CSSProperties & {
  '.MuiInputLabel-root'?: CSSProperties & {
    ':hover'?: CSSProperties;
  };
  '.MuiInputLabel-shrink'?: CSSProperties;
  '.MuiInputBase-root'?: CSSProperties & {
    input?: CSSProperties & { '&.Mui-disabled'?: CSSProperties };
    '::before'?: CSSProperties;
    '::after'?: CSSProperties;
  };
};

export type MpTextFieldProps = TextFieldProps & {
  maxLength?: number;
  innerRef?: RefObject<HTMLDivElement>;
  showEmailKeyboard?: boolean;
  showDecimalKeyboard?: boolean;
  showNumericKeyboard?: boolean;
  sx?: TextFieldStyling;
};

export default function MpTextField(props: MpTextFieldProps) {
  const {
    maxLength,
    innerRef,
    showEmailKeyboard,
    showDecimalKeyboard,
    showNumericKeyboard,
    sx,
    ...rest
  } = props;

  const getInputMode = () => {
    if (showEmailKeyboard) {
      return 'email';
    }

    if (showDecimalKeyboard) {
      return 'decimal';
    }

    if (showNumericKeyboard) {
      return 'numeric';
    }

    return 'text';
  };

  const getPattern = () => {
    if (showDecimalKeyboard || showNumericKeyboard) {
      return '[0-9]*';
    }

    return '';
  };

  const inputMode = getInputMode() as any;
  const pattern = getPattern();

  const newProps: TextFieldProps = {
    ...rest,
    ref: innerRef,
    inputProps: {
      maxLength: isNil(maxLength) ? inputCharacterMaxLength : maxLength,
      inputMode,
      pattern,
      ...props.inputProps,
    },
    sx: {
      ...sx,
      '.MuiInputLabel-root': {
        ...sx?.['.MuiInputLabel-root'],

        ':hover': {
          ...sx?.['.MuiInputLabel-root']?.[':hover'],
        },
      },
      '.MuiInputLabel-shrink': {
        display: 'none',
        ...sx?.['.MuiInputLabel-shrink'],
      },
      '.MuiInputBase-root': {
        boxSizing: 'border-box',
        border: '1px solid #474747',
        backgroundColor: importantStyle('#000000'),
        // borderRadius: '6px',

        borderRadius: 'initial',
        padding: importantStyle(0),

        ...sx?.['.MuiInputBase-root'],

        '::before': {
          content: 'none',
          ...sx?.['.MuiInputBase-root']?.['::before'],
        },
        '::after': {
          content: 'none',
          ...sx?.['.MuiInputBase-root']?.['::after'],
        },
        input: {
          padding: importantStyle('12.5px 12px'),
          '&.Mui-disabled': {
            color: `rgba(${hexToRGB('#838da4')}, 0.6)`,
            WebkitTextFillColor: `rgba(${hexToRGB('#838da4')}, 0.6)`,
            ...sx?.['.MuiInputBase-root']?.input?.['&.Mui-disabled'],
          },
          ...sx?.['.MuiInputBase-root']?.input,
        },
      },
    },
  };

  return <TextField {...newProps} />;
}

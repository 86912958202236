import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import axios from '../../axiosInstance';
import { ApiConfig } from '../../types';
import { AddressGroupGetAllApiRes } from './types';

const { addressManagement } = EPt;

const getAllAddressGroup = async (params?: unknown, config?: ApiConfig) => {
  const input = {
    params,
    ...config,
  };
  return await axios.get<any, AddressGroupGetAllApiRes[] | undefined>(
    addressManagement.addressGroupGetAll,
    input
  );
};
const createAddressGroup = async (
  params: { walletGroupName: string; icon: string },
  config?: ApiConfig
) => {
  return await axios.post<any, { walletGroupId: number }>(
    addressManagement.addressGroupCreate,
    params,
    config
  );
};
const updateAddressGroup = async (
  params: { walletGroupId: string; walletGroupName: string; icon: string },
  config?: ApiConfig
) => {
  return await axios.post<any, any>(addressManagement.addressGroupUpdate, params, config);
};
const deleteAddressGroup = async (params: { walletGroupId: string }, config?: ApiConfig) => {
  return await axios.post<any, any>(addressManagement.addressGroupDelete, params, config);
};

export default { getAllAddressGroup, createAddressGroup, updateAddressGroup, deleteAddressGroup };

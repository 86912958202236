export const TRANSLATE_KEY = {
  operation: 'operation',
  report: 'report',
  displayName: 'display_name',
  addressGroup: 'address_group',
  address: 'address',
  network: 'network',
  token: 'token',
  addressBalance: 'address_balance',
  latestCheckpointId: 'latest_checkpoint_id',
  latestCheckedBlockNumber: 'latest_checked_block_number',
  certifiedWithOnChainBalance: 'certified_with_on_chain_balance',
  lastCertifiedCheckpointId: 'last_certified_checkpoint_id',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  settlementTime: 'settlement_time',
  amount: 'amount',
  excludeZero: 'exclude_zero',
};

export const TRANSLATE_PREFIX = 'addressBalance';

export const EXCEL_TEMPLATE_FILENAME = 'import_address_template';

export const OMIT_KEYS = { export: ['rawData'], table: [] };

export const EXPORT_FILE_NAME = 'address_balance';

import BigNumber from 'bignumber.js';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router';

import rightArrowIcon from '../../assets/icons/HomePage/right-arrow-redirect.png';
import { isStrictNaN } from '../../utils';

export type SummaryBlockProps = {
  title: string;
  redirectPath: string;
  data: string | number | undefined;
  extraData?: string;
};

function SummaryBlock({ title, redirectPath, data, extraData }: SummaryBlockProps) {
  const redirect = useNavigate();

  const getDisplayData = () => {
    if (isNil(data) || isStrictNaN(data)) {
      return data;
    }

    return BigNumber(data).toFormat();
  };

  const displayData = getDisplayData();

  const displayExtraData = extraData && '(' + extraData + ')';

  return (
    <div
      style={{
        width: '353px',
        height: '130px',
        backgroundColor: '#222222',
        boxSizing: 'border-box',
        border: '1px rgba(255, 255, 255, 0.1) solid ',
        padding: '12px 16px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ fontWeight: 'bolder', fontSize: '14px', opacity: 0.5 }}>{title}</div>
        <img
          style={{ cursor: 'pointer' }}
          onClick={() => redirect(redirectPath)}
          src={rightArrowIcon}
        />
      </div>

      <div style={{ marginTop: '28px' }}>
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{displayData}</div>
        <div style={{ fontSize: '10px', opacity: 0.5, lineHeight: 1 }}>{displayExtraData}</div>
      </div>
    </div>
  );
}

export default SummaryBlock;

import CustomColorButton from '../../../../../Button/CustomColorButton';

function ClearButton({ onClear }: { onClear: () => void }) {
  return (
    <CustomColorButton
      sx={{ width: '95px', height: '32px', fontSize: '14px' }}
      variant="outlined"
      node={'Clear'}
      color={{ text: '#ffffff', border: '#474747' }}
      onClick={onClear}
    />
  );
}

export default ClearButton;

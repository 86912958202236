import GeneralSelection from '../../../../components/Selection/GeneralSelection';
import { useTranslation } from '../../../../hooks';
import { selectAddressGroup } from '../../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { getAddressGroupIcon } from '../../../../views/AddressManagement/AddressGroup/helpers';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function AddressGroupSelection({
  label,
  ...props
}: {
  label?: string;
} & (
  | {
      mode: 'single';
      value: string;
      onChange: (value: string) => void;
      clearSelect: () => void;
    }
  | {
      mode: 'multiple';
      value: string[];
      onChange: (value: string[]) => void;
    }
)) {
  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);

  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  return (
    <GeneralSelection
      sx={{
        formControl: {
          '& .MuiInputBase-root': { '& .MuiSelect-select': { display: 'flex', gap: '6px' } },
        },
      }}
      label={label || tc('phSelection', { fieldName: t(TRANSLATE_KEY.addressGroup) })}
      enumData={addressGroupNameMapping}
      displayNodeFn={(groupName) => ({
        displayNode: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              width: 'fit-content',
            }}
          >
            {getAddressGroupIcon({
              ...addressGroupNameMapping[groupName],
              style: { height: '18px', width: '18px' },
            })}

            <div>{groupName}</div>
          </div>
        ),
        searchTarget: groupName,
      })}
      searchMode="includes"
      {...props}
    />
  );
}

export default AddressGroupSelection;

import { useEffect, useState } from 'react';

import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import APIs from '../../../api';
import useSearchBar from '../../../components/Layout/SearchBar/hooks';
import SearchBarTemplate from '../../../components/Layout/SearchBarTemplate';
import { usePermission, useTranslation } from '../../../hooks';
import {
  getMerchantSupportedAssetSortingComparisonFn,
  selectAsset,
  setClientSupportedAssetChainCodeMapping,
} from '../../../reducer/assetSlice';
import { useAppDispatch, useAppSelector } from '../../../reducer/hooks';
import AssetSelectionRow from './components/AssetSelectionRow';
import ButtonBar from './components/ButtonBar';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from './constants';

function Asset() {
  const [searchField, setSearchField] = useState('');
  const { merchantSupportedAssetList, clientSupportedAssetChainCodeMapping } =
    useAppSelector(selectAsset);
  const dispatch = useAppDispatch();
  const { hasPermission } = usePermission();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const {
    getSearchResult,
    displayDataArr: displayMerchantSupportedAssetList,
    getNoItemText,
  } = useSearchBar({
    mode: 'includes',
    dbDataArr: merchantSupportedAssetList,
    searchInput: searchField,
    transformDataToSearchTargetFns: [
      // ({ assetList }) => assetList.map(({ assetName }) => assetName),
      ({ networkDisplayName }) => networkDisplayName,
    ],
    sortingComparisonFn: getMerchantSupportedAssetSortingComparisonFn,
  });

  useEffect(() => {
    APIs.addressManagement.asset.getAllAsset().then((res) => {
      if (!res) {
        return;
      }

      dispatch(setClientSupportedAssetChainCodeMapping(res));
    });
  }, []);

  const initSelectedAssets = Object.fromEntries(
    Object.entries(clientSupportedAssetChainCodeMapping).map(([chainCode, { assetNameList }]) => [
      chainCode,
      assetNameList,
    ])
  );

  const [selectedAssets, setSelectedAssets] =
    useState<Record<string, string[]>>(initSelectedAssets);

  const onSearch = () => {
    getSearchResult();
  };

  const noItemText = getNoItemText(t(TRANSLATE_KEY.network));

  return (
    <SearchBarTemplate
      label={tc('ph_search_bar', { fieldName: t(TRANSLATE_KEY.network) })}
      onSearch={onSearch}
      input={{
        value: searchField,
        setValue: (value) => setSearchField(value),
      }}
      noItemText={noItemText}
    >
      <ButtonBar selectedAssets={selectedAssets} />

      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {displayMerchantSupportedAssetList.map((merchantSupportedAsset) => (
          <AssetSelectionRow
            key={merchantSupportedAsset.networkDisplayName}
            value={selectedAssets[merchantSupportedAsset.chainCode] || []}
            setValue={(arr) => {
              if (!arr.length) {
                setSelectedAssets((prev) =>
                  Object.fromEntries(
                    Object.entries(prev).filter(
                      ([chainCode]) => merchantSupportedAsset.chainCode !== chainCode
                    )
                  )
                );

                return;
              }

              setSelectedAssets((prev) => ({ ...prev, [merchantSupportedAsset.chainCode]: arr }));
            }}
            isDisabled={!hasPermission(InspectionWalletFeatureCode.AddressManagement.Asset.Update)}
            {...merchantSupportedAsset}
          />
        ))}
      </div>
    </SearchBarTemplate>
  );
}

export default Asset;

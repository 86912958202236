export const TRANSLATE_KEY = {
  daily: 'daily',
  customPeriodic: 'custom_periodic',
  summary: 'summary',
};

export const TRANSLATE_PREFIX = 'report';

export const OMIT_KEYS = { export: ['rawData'], table: [] };

export const EXPORT_FILE_NAME = 'report';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from '../../../hooks';
import { importantStyle } from '../../../utils';
import GeneralSelection from '../../Selection/GeneralSelection';
import { MpTextField } from '../../TextField';
import SearchButton from './components/SearchButton';
import SearchIcon from './components/SearchIcon';

export type SearchBarProps = {
  label: string;
  onSearch: () => void;
  input: { value: string; setValue: (value: string) => void };
  filter?: {
    items: Record<string, string>;
    value: string;
    setValue: (value: string) => void;
  };
};

function SearchBar({ label, onSearch, input, filter }: SearchBarProps) {
  const { t } = useTranslation('searchBar');

  return (
    <form
      style={{ display: 'flex', alignItems: 'center', maxWidth: '516px', width: '100%' }}
      onSubmit={onSearch}
    >
      <SearchIcon />
      <MpTextField
        label={label}
        sx={{
          maxWidth: filter ? '320px' : undefined,
          width: '100%',
        }}
        InputProps={{
          sx: {
            height: '32px',
            borderRadius: importantStyle(0),
            borderLeftWidth: importantStyle(0),
            borderRightWidth: importantStyle(0),
          },
        }}
        InputLabelProps={{ sx: { transform: 'translate(12px, 7.2px) scale(1)' } }}
        value={input.value}
        onChange={(e) => input.setValue(e.target.value)}
      />
      {filter && (
        <GeneralSelection
          mode={'single'}
          arrowIcon={ExpandMoreIcon}
          sx={{
            formControl: {
              width: '40%',
              maxWidth: '160px',

              '& .MuiInputBase-root': {
                height: '32px',
                backgroundColor: importantStyle('#000000'),
                border: '1px solid #474747',
                borderRadius: 0,
                borderRight: 0,
                '& .MuiSelect-select': {
                  padding: 0,
                  textAlign: 'right',
                  paddingRight: '24px',
                },
                svg: { right: '4px' },
              },
            },
          }}
          value={filter.value}
          onChange={(value) => filter.setValue(value)}
          enumData={filter.items}
          clearSelect={() => {}}
          nameFn={(name) => t(name)}
        />
      )}
      <SearchButton onSearch={onSearch} />
    </form>
  );
}

export default SearchBar;

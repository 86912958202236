import { useTranslation } from '../../../../../../hooks';
import { COLOR_CODE_ARR, TRANSLATE_PREFIX } from '../../../constants';
import { TRANSLATE_KEY } from '../constants';

function ColorPicker({
  selectedColorCode,
  onSelectColorCode,
}: {
  selectedColorCode: string;
  onSelectColorCode: (colorCode: string) => void;
}) {
  const { t } = useTranslation(TRANSLATE_PREFIX);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <div>{t(TRANSLATE_KEY.chooseColor)}</div>
      <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '32px', rowGap: '16px' }}>
        {COLOR_CODE_ARR.map((colorCode) => {
          const isSelected = colorCode === selectedColorCode;

          return (
            <div
              key={colorCode}
              style={{
                cursor: 'pointer',
                boxSizing: 'border-box',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: colorCode,
                border: isSelected ? '2px solid #ffffff' : undefined,
                boxShadow: isSelected ? 'inset rgba(0, 0, 0, 0.4) 0px 0px 6px' : undefined,
              }}
              onClick={() => {
                onSelectColorCode(colorCode);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ColorPicker;

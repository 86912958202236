import { DialogInOne } from '../../../components';
import { useTranslation } from '../../../hooks';
import ActionButtons from './components/ActionButtons';

function ConfirmDeleteDialog({
  title,
  isOpen,
  onDelete,
  onClose,
}: {
  title: string;
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
}) {
  const { tc } = useTranslation();

  return (
    <DialogInOne
      title={title}
      self={{
        open: isOpen,
        onClose,
      }}
      content={
        <div style={{ textAlign: 'center', padding: '12px 0' }}>{tc('confirm_delete_notice')}</div>
      }
      actionButtons={
        <ActionButtons
          onConfirm={() => {
            onClose();
            onDelete();
          }}
          onCancel={onClose}
        />
      }
      actionBtnContainerStyling={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        padding: '24px 24px 24px',
      }}
      isConfirmHidden
      isCancelHidden
      isBlackBackdrop
      isTitleCentered
      isShowCrossButton
      onConfirm={() => {}}
      onCancel={() => {}}
      dialogWidth={{ maxWidth: '512px' }}
    />
  );
}

export default ConfirmDeleteDialog;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { useAppDispatch, useAppSelector } from '../reducer/hooks';
import { selectPermissions } from '../reducer/profileSlice';
import { setView } from '../reducer/stuffSlice';

//###add-new-page
// [translateKey, route, permission]
export const routeList: { [key: string]: string[] } = [
  ['homepage', '/', ''],
  ['accountSetting', 'accountSetting', ''],
  ['changePassword', 'accountSetting', ''],
  // TODO: add back permission later

  ['programList', 'programList', AgentPortalFeatureCode.ProgramAgentManagement.ProgramList.prefix],
  [
    'distributorAgentList',
    'distributorAgentList',
    AgentPortalFeatureCode.Setting.DistributorAgentList.prefix,
  ],
  [
    'clientApplication',
    'clientApplication',
    AgentPortalFeatureCode.ClientManagement.ClientApplication.prefix,
  ],
  [
    'programAgentReportDetail',
    'programAgentReportDetail',
    AgentPortalFeatureCode.Report.ProgramAgentReportDetail.prefix,
  ],

  // Asset Management Category
  ['asset', 'asset', InspectionWalletFeatureCode.AddressManagement.Asset.prefix],
  [
    'addressGroup',
    'addressGroup',
    InspectionWalletFeatureCode.AddressManagement.AddressGroup.prefix,
  ],
  ['addressList', 'addressList', InspectionWalletFeatureCode.AddressManagement.AddressList.prefix],
  [
    'addressBalance',
    'addressBalance',
    InspectionWalletFeatureCode.AddressManagement.AddressBalance.prefix,
  ],
  ['transaction', 'transaction', InspectionWalletFeatureCode.Report.Transaction.prefix],
  ['report', 'report', InspectionWalletFeatureCode.Report.Report.prefix],
].reduce((acc, [key, snakeCase, premission]) => {
  return { ...acc, [key]: [snakeCase, key, premission] };
}, {});

export default function useRoutePermission() {
  const pathname = useLocation().pathname.replace('/', '');
  const dispatch = useAppDispatch();
  const permissionList = useAppSelector(selectPermissions);
  const hasPermission = (key: string) => permissionList.includes(String(key).toLowerCase());
  const viewPermission = getViewPermission(pathname);

  useEffect(() => {
    dispatch(setView(viewPermission));
  }, [viewPermission]);

  const hasRoutePermission = (key: string) => {
    if (key === 'homepage' || key === 'accountSetting' || key === 'changePassword') return true;

    const [_, __, permission] = routeList[key] || [];
    return hasPermission(permission);
  };

  return { hasRoutePermission, viewPermission };
}

export function getViewPermission(pathname: string) {
  const [permission] = Object.values(routeList).find(([_, path]) => path === pathname) || [
    'homepage',
  ];
  return permission;
}

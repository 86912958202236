import { uniqueId } from 'lodash';

import { useTranslation } from '../../../../../../hooks';
import { useAppSelector } from '../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../reducer/mediaSlice';
import { TRANSLATE_PREFIX } from '../../../constants';

function LabeledDataContainer({
  configArr,
}: {
  configArr: { label: string; data: string | number; isColored?: boolean }[];
}) {
  const { t } = useTranslation(TRANSLATE_PREFIX);

  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div
      style={{
        display: 'grid',
        columnGap: '56px',
        rowGap: '12px',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
        fontSize: '10px',
      }}
    >
      {configArr.map(({ label, data, isColored }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          key={uniqueId(label)}
        >
          <div style={{ opacity: 0.5 }}>{t(label)}</div>
          <div style={{ color: isColored ? '#00FF59' : undefined }}>{data}</div>
        </div>
      ))}
    </div>
  );
}

export default LabeledDataContainer;

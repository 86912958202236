import { useState } from 'react';

import APIs from '../../../../../../api';
import ActionButtons from '../../../../../../common/components/Dialog/components/ActionButtons';
import { DialogInOne } from '../../../../../../components';
import { useAlerting, useTranslation } from '../../../../../../hooks';
import useVerifyField from '../../../../../../hooks/useVerifyField';
import { selectAddressGroup } from '../../../../../../reducer/addressGroupSlice';
import { selectAsset } from '../../../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../../../reducer/hooks';
import { TRANSLATE_PREFIX } from '../../../constants';
import Content, { initialAddOrEditAddressFields } from '../components/Content';
import { TRANSLATE_KEY } from '../constants';

function DialogAddAddress({ onClose, onRefresh }: { onClose: () => void; onRefresh: () => void }) {
  const [fields, setFields] = useState(initialAddOrEditAddressFields);

  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const { verifyHasEmptyField } = useVerifyField('addressList');

  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);
  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);

  const onConfirm = async () => {
    const { chainCode, addressGroupName, address: walletAddress, displayName: walletName } = fields;

    const alertMessage = verifyHasEmptyField([
      { value: chainCode, fieldTranslateKey: TRANSLATE_KEY.network, mode: 'selection' },
      { value: walletAddress, fieldTranslateKey: TRANSLATE_KEY.address, mode: 'input' },
      { value: addressGroupName, fieldTranslateKey: TRANSLATE_KEY.addressGroup, mode: 'selection' },
      { value: walletName, fieldTranslateKey: TRANSLATE_KEY.displayName, mode: 'input' },
    ]);

    if (alertMessage) {
      return alerting('warning', alertMessage);
    }

    const { chainId, chainType } = clientSupportedAssetChainCodeMapping[chainCode];

    const { id: walletGroupId } = addressGroupNameMapping[addressGroupName];

    const res = await APIs.addressManagement.addressList.createAddress({
      chainId,
      chainType,
      walletGroupId,
      walletAddress,
      walletName,
    });

    if (!res) {
      return;
    }

    alerting('success', tc('add_success_message', { itemName: t(TRANSLATE_KEY.address) }));
    onRefresh();
    onClose();
  };

  return (
    <DialogInOne
      title={t(TRANSLATE_KEY.addAddress)}
      self={{ open: true, onClose }}
      content={<Content fields={fields} setFields={setFields} />}
      isShowCrossButton
      isBlackBackdrop
      isTitleCentered
      dialogWidth={{ width: '512px' }}
      isCancelHidden
      isConfirmHidden
      actionButtons={<ActionButtons onConfirm={onConfirm} onCancel={onClose} />}
      actionBtnContainerStyling={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        padding: '40px 24px 24px',
      }}
      onConfirm={() => {}}
      onCancel={() => {}}
    />
  );
}

export default DialogAddAddress;

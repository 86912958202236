export type DashboardRes = {
  walletGroupCount: number;
  addressCount: number;
  transferTransactionCount: number;
  transferTransactionDate: string;
  reportDate: string;
};

export type ClientSupportedAssetQueryRes = {
  id: string;
  merchantId: string;
  clientId: string;
  chainType: number;
  chainId: string;
  assetName: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
};

export interface MerchantSupportedAssetQueryRes {
  id: string;
  chainType: number;
  chainId: string;
  chainCode: ChainCode;
  nativeAssetName: string;
  createdDate: string;
  lastModifiedDate: string;
  latestBlockNumber: string;
  latestPendingBlockNumber: string;
  asset: Asset[];
}

export const CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING = {
  AVAXC: 'Avalanche C-Chain',
  FUJIC: 'Fuji C-chain',
  ADA: 'Cardano',
  ADAT: 'Cardano Testnet',
  LTC: 'Litecoin',
  LTCT: 'Litecoin Testnet',
  SOL: 'Solana',
  SOLD: 'Solana Devnet',
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  RIN: 'Rinkeby',
  BSC: 'Binance',
  BSCT: 'Binance Testnet',
  XRPT: 'Ripple Testnet',
  MATIC: 'Polygon',
  MUM: 'Mumbai',
  SEP: 'Sepolia',
  TRX: 'Tron',
  XRP: 'Ripple',
  OKC: 'OKX Chain',
  OKCT: 'OKX Chain Testnet',
  BCH: 'Bitcoin Cash',
  BCHT: 'Bitcoin Cash Testnet',
  DOGE: 'Dogecoin',
  DOGET: 'Dogecoin Testnet',
  ARB: 'Arbitrum One',
  ARBT: 'Arbitrum Sepolia',
  AMOY: 'Amoy',
};

export type ChainCode = keyof typeof CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING;

export interface Asset {
  assetName: string;
  address: string;
  minReserveBalance: string;
  decimals: number;
  saveStatus: boolean;
}

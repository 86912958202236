import { TransactionDetailsRes } from '../../../../../../../api/report/transaction/types';
import { ReactComponent as ChartIcon } from '../../../../../../../assets/icons/TransactionDetails/chart.svg';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/icons/TransactionDetails/copy.svg';
import CopyableText from '../../../../../../../components/CopyableText';
import { Tooltip } from '../../../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../../../hooks';
import useGetNetworkInfo from '../../../../../../../hooks/useGetNetworkInfo';
import { useAppSelector } from '../../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../../reducer/mediaSlice';
import {
  amountDivideDecimals,
  toDisplayTime
} from '../../../../../../../utils';
import { TRANSLATE_PREFIX } from '../../../../constants';
import LabeledDataContainer from '../../components/LabeledDataContainer';
import { TRANSLATE_KEY } from '../constants';

function ChainInformation({ data }: { data: TransactionDetailsRes }) {
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);
  const { t: te } = useTranslation('enumConstants');
  const isMobile = useAppSelector(selectIsMobileView);

  const { getNetworkDisplayName } = useGetNetworkInfo();

  const networkDisplayName = getNetworkDisplayName({
    chainId: data.chainId,
    chainType: data.chainType,
  });

  const toDisplayAmount = (amount: string) => amountDivideDecimals(amount, data.asset.decimal);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ fontSize: '14px', fontWeight: '700' }}>{t(TRANSLATE_KEY.chainInformation)}</div>
      <div style={{ backgroundColor: '#333333', padding: '12px 24px' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
            rowGap: isMobile ? undefined : '12px',
            fontSize: '10px',
          }}
        >
          <div style={{ opacity: 0.5 }}>{t(TRANSLATE_KEY.transactionHash)}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: isMobile ? undefined : 'flex-end',
              alignItems: 'center',
              gap: '8px',
              maxWidth: '100%',
            }}
          >
            <CopyableText
              text={data.txHash}
              iconWithTooltip={<CopyIcon />}
              iconWithoutTooltip={
                <Tooltip title={t(TRANSLATE_KEY.viewOnEtherscan)} distanceFromAnchor={6}>
                  <ChartIcon
                    style={{ marginLeft: '8px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(`https://etherscan.io/tx/${data.txHash}`);
                    }}
                  />
                </Tooltip>
              }
            />
          </div>

          {isMobile && <br />}

          <div style={{ opacity: 0.5 }}>{t(TRANSLATE_KEY.blockHash)}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: isMobile ? undefined : 'flex-end',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Tooltip title={data.blockHash}>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {data.blockHash}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <LabeledDataContainer
        configArr={[
          {
            label: TRANSLATE_KEY.network,
            data: networkDisplayName,
          },
          {
            label: TRANSLATE_KEY.currency,
            data: data.assetName,
          },
          { label: TRANSLATE_KEY.blockConfirmation, data: data.block.confirmation },
          {
            label: TRANSLATE_KEY.blockTransactionTime,
            data: toDisplayTime(data.block.blockTime),
          },
          {
            label: TRANSLATE_KEY.blockNumber,
            data: data.block.blockNumber,
          },
        ]}
      />
    </div>
  );
}

export default ChainInformation;

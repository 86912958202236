import { useState } from 'react';

import CustomDataGrid from '../../../../../../../components/Layout/CustomDataGrid';
import { useTranslation } from '../../../../../../../hooks';
import { useGenTableColumns } from '../../../../../../../hooks/useGenTableColumns';
import { downloadFiles } from '../../../../../../../utils';
import {
  IMPORT_ADDRESS_LOG_FILENAME,
  IMPORT_RESULT_DIALOG_TABLE_PAGE_SIZE,
  TRANSLATE_PREFIX,
} from '../../../../constants';
import { ImportAddressLog } from '../../../../hooks/useImportCsv';
import { TRANSLATE_KEY } from '../../constants';
import useTransformData from './hooks/useTransformData';

function ImportLogTable({ data }: { data: ImportAddressLog[] }) {
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);
  const { transformData } = useTransformData();

  const [page, setPage] = useState(0);

  const successCount = data.filter(({ status }) => status === 'success').length;
  const duplicatedCount = data.filter(({ status }) => status === 'duplicated').length;

  const failData = data.filter(({ status }) => status === 'fail');

  const failDataWithPaging = failData.slice(
    page * IMPORT_RESULT_DIALOG_TABLE_PAGE_SIZE,
    page * IMPORT_RESULT_DIALOG_TABLE_PAGE_SIZE + IMPORT_RESULT_DIALOG_TABLE_PAGE_SIZE
  );

  const count = failData.length;

  const content = transformData({ mode: 'table', data: failDataWithPaging });

  const { columns } = useGenTableColumns({
    translatePrefix: TRANSLATE_PREFIX,
    columnConfigArr: [
      {
        translateKey: TRANSLATE_KEY.index,
        minWidth: 130,
      },
      {
        translateKey: TRANSLATE_KEY.status,
        minWidth: 130,
      },
      { translateKey: TRANSLATE_KEY.displayName, minWidth: 150 },
      {
        translateKey: TRANSLATE_KEY.addressGroup,
        minWidth: 130,
      },
      { translateKey: TRANSLATE_KEY.address, minWidth: 200 },
      { translateKey: TRANSLATE_KEY.network, minWidth: 130 },
      { translateKey: TRANSLATE_KEY.errorMessage, minWidth: 150 },
    ],
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <div>
        <div>{t(TRANSLATE_KEY.successCount)}</div>
        <div>{successCount}</div>
      </div>
      <div>
        <div>{t(TRANSLATE_KEY.duplicatedCount)}</div>
        <div>{duplicatedCount}</div>
      </div>
      <div>
        <div>{t(TRANSLATE_KEY.failRecords)}</div>
        <CustomDataGrid
          pageSize={IMPORT_RESULT_DIALOG_TABLE_PAGE_SIZE}
          rows={content}
          rowCount={count}
          columns={columns}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          isLoading={false}
        />
      </div>
      <div>
        <div>{t(TRANSLATE_KEY.downloadLogFile)}</div>
        <button
          onClick={() =>
            downloadFiles(IMPORT_ADDRESS_LOG_FILENAME, transformData({ mode: 'export', data }))
          }
        >
          {tc('download')}
        </button>
      </div>
    </div>
  );
}

export default ImportLogTable;

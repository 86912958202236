import { uniqueId } from 'lodash';
import { ReactNode } from 'react';

import { usePermission } from '../../../../hooks';
import CustomColorButton from '../../../Button/CustomColorButton';

function ButtonSection({
  buttonConfigArr,
}: {
  buttonConfigArr: { node: ReactNode; onClick: () => void; width: string; permission?: string }[];
}) {
  const { hasPermission } = usePermission();

  return (
    <div style={{ display: 'flex', gap: '12px', maxWidth: '45%' }}>
      {buttonConfigArr.map(
        ({ width, node, onClick, permission }) =>
          (!permission || hasPermission(permission)) && (
            <CustomColorButton
              key={uniqueId('button')}
              sx={{ height: '32px', width, lineHeight: 1 }}
              variant="outlined"
              color={{ text: '#ffffff', border: '#474747' }}
              node={node}
              onClick={onClick}
            />
          )
      )}
    </div>
  );
}

export default ButtonSection;

//version no
export const versionNumber = 'V0.0.2';

export interface FreeObj {
  [code: string]: string;
}

export const inputCharacterMaxLength = 256;

export const logoutCodes = ['-7', '-8'];

export const enumLanguage = {
  en: 'en',
  zh_cn: 'zh_cn',
} as const;

export const enumTimezone = {
  // TODO: [pmp] disable timezone choices by now
  timezone8: '+8',
  timezone0: '+0',
  timezone1: '+1',
  timezone2: '+2',
  timezone3: '+3',
  timezone4: '+4',
  timezone5: '+5',
  timezone6: '+6',
  timezone7: '+7',
  timezone9: '+9',
  timezone10: '+10',
  timezone11: '+11',
  timezone12: '+12',
  timezone13: '-11',
  timezone14: '-10',
  timezone15: '-9',
  timezone16: '-8',
  timezone17: '-7',
  timezone18: '-6',
  timezone19: '-5',
  timezone20: '-4',
  timezone21: '-3',
  timezone22: '-2',
  timezone23: '-1',
  // beijing:"8",
} as const;

export const EnumHours24HoursFormat = {
  '00:00': '00:00:00',
  '01:00': '01:00:00',
  '02:00': '02:00:00',
  '03:00': '03:00:00',
  '04:00': '04:00:00',
  '05:00': '05:00:00',
  '06:00': '06:00:00',
  '07:00': '07:00:00',
  '08:00': '08:00:00',
  '09:00': '09:00:00',
  '10:00': '10:00:00',
  '11:00': '11:00:00',
  '12:00': '12:00:00',
  '13:00': '13:00:00',
  '14:00': '14:00:00',
  '15:00': '15:00:00',
  '16:00': '16:00:00',
  '17:00': '17:00:00',
  '18:00': '18:00:00',
  '19:00': '19:00:00',
  '20:00': '20:00:00',
  '21:00': '21:00:00',
  '22:00': '22:00:00',
  '23:00': '23:00:00',
} as const;

export const EnumBoolean = {
  Yes: 'yes',
  No: 'no',
} as const;

export const enumPortalUserYubiKeyStatus = {
  Unbound: '-1',
  Pending: '0',
  Bound: '1',
} as const;

export const defaultIntervalPendingOperationsCount = '30'; //second

export const csvRecordPageSize = 500;

export const csvRecordDivisor = 100000;

export const tableConfig = {
  pageSize: 20,
  rowsPerPageOptions: [20],
  defaultWidth: 100,
  addressWidth: 250,
  dateWidth: 150,
  freeTextWidth: 200,
  copyableFields: [
    'address',
    'from_address',
    'to_address',
    'gether_address',
    'invoker_address',
    'hot_wallet_address',
    'wallet_address',
    'Receiving Address',
    'Invoker Address',
    'Sending Address',
    'Wallet Address',
    'tx_hash',
    'transaction_hash',
    'sending_address',
    'receiving_address',
    'customer_number',
    'customer_no',
    'customerNumber',
    'application_no',
    'application_number',
    'applicationNumber',
    'customer_id',
    'customerId',
    'client_id',
    'clientId',
    'transactionHash',
    'txHash',
    'sendingAddress',
    'receivingAddress',
    'order_id',
    'batch_order_id',
    'repayment_order_id',
    'credit_adjustment_order_id',
    'orderId',
    'fromAddress',
    'toAddress',
    'referenceNumber',
    'distributorAgentId',
    'pfhNumber',
  ],
  dateFields: [
    'created_date',
    'date_operated',
    'last_modified_date',
    'date_last_modified',
    'date_approved',
    'wallet_settlement_date',
    'trans_date',
    'approved_date',
    'date_created',
    'date_created',
    'trans_date',
    'mark_date',
    'date_received',
    'date_last_callback_attempt',
    'creation_time',
    'operation_time',
    'mark_date_from',
    'mark_date_to',
    'last_modified_time',
  ],
  freeTextFields: ['client_id', 'wallet_name', 'amount', 'adjustment_amount'],
};

export const dataGridDefaults = {
  autoHeight: true,
  disableColumnFilter: true,
  disableSelectionOnClick: true,
  headerHeight: 45,
  rowHeight: 40,
  paginationMode: 'server',
  pageSize: tableConfig.pageSize,
  rowsPerPageOptions: tableConfig.rowsPerPageOptions,
} as const;

import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import APIs from '../../../api';
import useSearchBar from '../../../components/Layout/SearchBar/hooks';
import SearchBarTemplate from '../../../components/Layout/SearchBarTemplate';
import { getAddressGroupIconInfo } from '../../../helper';
import { useTranslation } from '../../../hooks';
import useLoading from '../../../hooks/useLoading';
import useRefreshPage from '../../../hooks/useRefreshPage';
import AddressGroupRow from './components/AddressGroupRow';
import { DialogCreateNewGroup, DialogEditGroup } from './components/Dialogs';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from './constants';
import { AddressGroupData } from './types';

function AddressGroup() {
  const [searchParams] = useSearchParams();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const redirectSearchInput = searchParams.get('search');

  const [searchField, setSearchField] = useState(redirectSearchInput || '');

  const [dialogMode, setDialogMode] = useState<'create' | 'edit' | 'idle'>('idle');
  const [editGroupData, setEditGroupData] = useState<AddressGroupData>();
  const [dbGroupData, setDbGroupData] = useState<AddressGroupData[]>();
  const { showLoading, hideLoading } = useLoading();
  const { refreshDependency, getNewRefreshDependency } = useRefreshPage();

  useEffect(() => {
    showLoading('addressGroup');
    APIs.addressManagement.addressGroup.getAllAddressGroup().then((res) => {
      hideLoading('addressGroup');
      if (!res) {
        return;
      }

      setDbGroupData(
        res.map(({ groupName, icon, id }) => {
          const iconObj = getAddressGroupIconInfo(icon);

          return { id, name: groupName, ...iconObj };
        })
      );
    });
  }, [refreshDependency]);

  const onCreateNewGroup = () => {
    setDialogMode('create');
  };

  const { getSearchResult, displayDataArr, getNoItemText } = useSearchBar({
    mode: 'includes',
    dbDataArr: dbGroupData,
    searchInput: searchField,
    transformDataToSearchTargetFns: [({ name }) => name],
  });

  const onSearch = () => {
    getSearchResult();
  };

  const onEditGroup = (data: AddressGroupData) => {
    setDialogMode('edit');
    setEditGroupData(data);
  };
  const onDialogClose = () => {
    setDialogMode('idle');
  };

  const noItemText = getNoItemText('Address Group');

  return (
    <SearchBarTemplate
      label={tc('ph_search_bar', { fieldName: t(TRANSLATE_KEY.group) })}
      onSearch={onSearch}
      input={{
        value: searchField,
        setValue: (value) => setSearchField(value),
      }}
      buttonConfigArr={[
        {
          onClick: onCreateNewGroup,
          width: '142px',
          node: t(TRANSLATE_KEY.createNewGroup),
          permission: InspectionWalletFeatureCode.AddressManagement.AddressGroup.CreateNewGroup,
        },
      ]}
      noItemText={noItemText}
    >
      {dialogMode === 'create' && (
        <DialogCreateNewGroup onClose={onDialogClose} onRefresh={getNewRefreshDependency} />
      )}

      {dialogMode === 'edit' && (
        <DialogEditGroup
          onClose={onDialogClose}
          data={editGroupData}
          onRefresh={getNewRefreshDependency}
        />
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {displayDataArr.map((data) => (
          <AddressGroupRow
            key={uniqueId(data.name)}
            {...data}
            onEditGroup={() => onEditGroup(data)}
          />
        ))}
      </div>
    </SearchBarTemplate>
  );
}

export default AddressGroup;

import { Dispatch, SetStateAction } from 'react';

import { MpTextField } from '../../../../../../components';
import FieldWithLabel from '../../../../../../components/FieldWithLabel';
import { useTranslation } from '../../../../../../hooks';
import { TRANSLATE_PREFIX } from '../../../constants';
import { TRANSLATE_KEY } from '../constants';
import AddressGroupSelectionField from './AddressGroupSelectionField';
import NetworkSelectionField from './NetworkSelectionField';

export type AddOrEditAddressFieldState = {
  chainCode: string;
  addressGroupName: string;
  address: string;
  displayName: string;
};

export const initialAddOrEditAddressFields: AddOrEditAddressFieldState = {
  chainCode: '',
  address: '',
  displayName: '',
  addressGroupName: '',
};

function Content({
  fields,
  setFields,
  isEdit,
}: {
  fields: AddOrEditAddressFieldState;
  setFields: Dispatch<SetStateAction<AddOrEditAddressFieldState>>;
  isEdit?: boolean;
}) {
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <NetworkSelectionField fields={fields} setFields={setFields} disabled={isEdit} />

      <FieldWithLabel
        label={t(TRANSLATE_KEY.address)}
        fieldComponent={
          <MpTextField
            label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.address) })}
            value={fields.address}
            onChange={(e) => setFields((prev) => ({ ...prev, address: e.target.value }))}
            disabled={isEdit}
          />
        }
      />

      <AddressGroupSelectionField fields={fields} setFields={setFields} />

      <FieldWithLabel
        label={t(TRANSLATE_KEY.displayName)}
        fieldComponent={
          <MpTextField
            label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.displayName) })}
            value={fields.displayName}
            onChange={(e) => setFields((prev) => ({ ...prev, displayName: e.target.value }))}
          />
        }
      />
    </div>
  );
}

export default Content;

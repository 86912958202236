import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as RedirectIcon } from '../../../../../../assets/icons/AddressListOperation/redirect-arrow.svg';
import UnderlinedButton from '../../../../../../components/Button/UnderlinedButton';
import FieldWithLabel from '../../../../../../components/FieldWithLabel';
import GeneralSelection from '../../../../../../components/Selection/GeneralSelection';
import { useTranslation } from '../../../../../../hooks';
import { NetworkInfo, selectAsset } from '../../../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../../../reducer/hooks';
import { TRANSLATE_PREFIX } from '../../../constants';
import { AddOrEditAddressFieldState } from '../components/Content';
import { TRANSLATE_KEY } from '../constants';

function NetworkSelectionField({
  fields,
  setFields,
  disabled,
}: {
  fields: AddOrEditAddressFieldState;
  setFields: Dispatch<SetStateAction<AddOrEditAddressFieldState>>;
  disabled?: boolean;
}) {
  const { merchantSupportedAssetList, clientSupportedAssetChainCodeMapping } =
    useAppSelector(selectAsset);
  const navigate = useNavigate();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const displayObj = merchantSupportedAssetList.reduce(
    (acc: Record<string, NetworkInfo>, { chainCode }) => {
      if (clientSupportedAssetChainCodeMapping[chainCode]) {
        return { ...acc, [chainCode]: clientSupportedAssetChainCodeMapping[chainCode] };
      }

      return {
        ...acc,
        [chainCode]: {
          chainId: '',
          chainType: -9999,
          assetNameList: [],
        },
      };
    },
    {}
  );
  return (
    <FieldWithLabel
      infoNode={
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>{t(TRANSLATE_KEY.networkNoticeText)}</div>
          <UnderlinedButton
            color="#FFD100"
            fontSize="10px"
            onClick={() => {
              navigate('/asset');
            }}
            node={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: 'initial',
                  gap: '4px',
                }}
              >
                <div>{t(TRANSLATE_KEY.asset)}</div>
                <RedirectIcon />
              </div>
            }
          />
        </div>
      }
      label={t(TRANSLATE_KEY.network)}
      fieldComponent={
        <GeneralSelection
          mode={'single'}
          label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.network) })}
          enumData={displayObj}
          value={fields.chainCode}
          onChange={(value) => setFields((prev) => ({ ...prev, chainCode: value }))}
          displayNodeFn={(chainCode) => ({
            displayNode: (
              <div
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: '25% 75%',
                  padding: '12px 0',
                }}
              >
                <div>{chainCode}</div>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {displayObj[chainCode] && displayObj[chainCode].assetNameList.length
                    ? displayObj[chainCode].assetNameList.join(', ')
                    : t(TRANSLATE_KEY.notSupportedAssetNotice)}
                </div>
              </div>
            ),
            searchTarget: [chainCode, ...displayObj[chainCode].assetNameList],
          })}
          disabledItemList={Object.keys(displayObj).filter(
            (key) => !displayObj[key].assetNameList.length
          )}
          clearSelect={() => {}}
          isNoSorting
          isGroupingDisabledItems
          searchMode="includes"
          disabled={disabled}
        />
      }
    />
  );
}

export default NetworkSelectionField;

import ListItemText from '@mui/material/ListItemText';
import MuiMenuItem from '@mui/material/MenuItem';
import { SxProps } from '@mui/system';

import { usePermission } from '../../hooks';
import { COLOR_THEME } from '../../style/colorTheme';
import { hexToRGB, importantStyle } from '../../utils';
import { Box } from '../MuiGenerals';

interface IMenuItemProps {
  title: string;
  permission?: string;
  onClick?: () => void;
  isActive: boolean;
  isLargeItem?: boolean;
  icon?: JSX.Element;
  sx?: SxProps;
}

const MenuItem = (props: IMenuItemProps) => {
  const { hasPermission } = usePermission();

  const { title, permission, onClick, isActive, isLargeItem, icon, sx } = props;

  let isHavePermission = true;

  if (permission) {
    isHavePermission = hasPermission(permission);
  }

  if (!isHavePermission) return <></>;

  return (
    <Box
      sx={{
        // fallback
        fontSize: isLargeItem ? importantStyle('1rem') : importantStyle('12px'),
        paddingLeft: isLargeItem ? '10px' : undefined,
        color: `rgba(${hexToRGB(COLOR_THEME.Menu.Secondary.Text)}, 0.7)`,

        li: {
          backgroundColor: 'transparent !important',
        },

        // default
        '.MuiMenuItem-root .MuiTypography-root ': {
          fontSize: isLargeItem ? importantStyle('1rem') : importantStyle('12px'),
          color: `rgba(${hexToRGB(COLOR_THEME.Menu.Secondary.Text)}, 0.7)`,
          lineHeight: 'initial',
        },

        // active
        '.Mui-selected .MuiTypography-root': {
          fontSize: isLargeItem ? importantStyle('1rem') : importantStyle('12.5px'),
          color: importantStyle(COLOR_THEME.Menu.Primary.Text),
          fontWeight: importantStyle('bold'),
        },

        // hover
        'div:hover': {
          color: COLOR_THEME.Menu.Secondary.Text,
        },
        ...sx,
      }}
    >
      <MuiMenuItem selected={isActive} onClick={onClick}>
        {icon}
        <ListItemText sx={{ marginLeft: icon ? '8px' : undefined }}>{title}</ListItemText>
      </MuiMenuItem>
    </Box>
  );
};

export default MenuItem;

import { Button } from '../MuiGenerals';
import { useTranslation } from '../../hooks';

type Ilabel =
  | 'search'
  | 'reset'
  | 'export'
  | 'save'
  | 'edit'
  | 'approve'
  | 'reject'
  | 'confirm'
  | 'cancel';

export default function GeneralBtn(p: {
  label: Ilabel;
  onClick: () => void;
  isHidden?: boolean;
  color?: 'primary' | 'secondary' | 'info' | 'error' | 'warning';
  variant?: 'contained' | 'outlined';
  rest?: any;
}) {
  const { tc } = useTranslation();

  const additionProps = {
    reset: { color: 'secondary', variant: 'contained' },
    export: { variant: 'outlined' },
  }[p.label as string];

  const defaultProps = {
    color: 'primary',
    variant: 'contained',
    ...additionProps,
  };

  const color = p.color ?? defaultProps.color;
  const variant = p.variant ?? defaultProps.variant;

  if (p.isHidden) return null;

  return (
    <Button onClick={p.onClick} color={color} variant={variant} {...p.rest}>
      {tc(p.label as any)}
    </Button>
  );
}

import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import { PAGE_SIZE } from '../../../constants/config';
import axios from '../../axiosInstance';
import { ApiConfig, IlistResult } from '../../types';
import { AddressBalanceGetAllRes } from './types';

const { addressManagement } = EPt;

const getAll = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: { ...rest, offset: page * pageSize, limit: pageSize },
    ...config,
  };
  return await axios.get<any, IlistResult<AddressBalanceGetAllRes> | undefined>(
    addressManagement.addressBalanceGetAll,
    input
  );
};
const getExport = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: { ...rest, offset: page * pageSize, limit: pageSize },
    ...config,
  };
  return await axios.get<any, IlistResult<AddressBalanceGetAllRes> | undefined>(
    addressManagement.addressBalanceExport,
    input
  );
};

export default { getAll, getExport };

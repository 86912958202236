import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../reducer/hooks';
import { login, selectProfile } from '../../reducer/profileSlice';
import { store } from '../../reducer/store';
import {
  readAccessToken,
  setClientDateTimeDiff,
  writeTokens
} from '../../utils/';

const dispatch = store.dispatch;
const Redirect = () => {
  const [isInit, setIsInit] = useState(false);

  const profile = useAppSelector(selectProfile);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { accessToken, refreshToken, features } = locationState;

  const authToken = accessToken;
  const paramsRefreshToken = refreshToken || '';

  const noToken = !authToken || authToken.length === 0;

  const localAccessToken = readAccessToken();
  const isHaveAccessToken = localAccessToken && localAccessToken.length > 0;

  const writeTokenAndLoginInfo = (info: any) => {
    const { accessToken, refreshToken, features, appUuid } = info;
    writeTokens({ accessToken, refreshToken });

    const featureArr = Object.keys(features)
      .map((f) => f.toLowerCase())
      .sort();

    const jwt = jwt_decode<any>(accessToken);
    const { email, exp, iat } = jwt;

    setClientDateTimeDiff(iat);

    const loginParams = {
      appName: 'AWMS',
      featureList: featureArr,
      email,
      appUuid,
      expSecond: exp,
      jwtDetails: jwt,
    };

    dispatch(login(loginParams));
    setIsInit(true);
  };

  useEffect(() => {
    async function fetch() {
      if (noToken) {
        return;
      }

      try {
        const accessToken = authToken;

        const jwt = jwt_decode<any>(accessToken);
        const { email, appUuid } = jwt;

        if (isHaveAccessToken) {
          const isDiffUser = email !== profile.email;
          const isDiffMerchant = appUuid !== profile.appUuid;

          if (isDiffUser || isDiffMerchant) {
            return writeTokenAndLoginInfo({
              appUuid: appUuid || '',
              accessToken: authToken,
              refreshToken: paramsRefreshToken,
              features,
            });
          }

          setIsInit(true);
        } else {
          // fresh login
          writeTokenAndLoginInfo({
            appUuid: appUuid || '',
            accessToken: authToken,
            refreshToken: paramsRefreshToken,
            features,
          });
        }
      } catch (err) {
        console.log('redirect error ', (err as any).message, err);
        navigate('/login');
      }
    }

    fetch();
  }, []);

  useEffect(() => {
    if (localAccessToken) {
      if (isInit) {
        // APIs.query.asset.getMerchantSupportedAsset().then((res) => {
        //   console.log('redirect success');
        //   if (!res) {
        //     navigate('/login');

        //     return;
        //   }

        //   dispatch(setMerchantSupportedAssetList(res));

        //   APIs.query.asset.getClientSupportedAsset().then((res) => {
        //     if (!res) {
        //       navigate('/login');

        //       return;
        //     }

        //     if (!res.length) {
        //       dispatch(setShouldShowFirstLoginPage(true));
        //       navigate('/asset');
        //     }
        //   });
        // });

        navigate('/');
      }
    }
  }, [localAccessToken, isInit]);

  if (noToken) return null;

  return <>redirecting</>;
};
export default Redirect;

import { Direction } from '@wallet-manager/node-types/dist/src/postgres/const';

import { TransactionDetailsRes } from '../../../../../../../api/report/transaction/types';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/icons/TransactionDetails/copy.svg';
import CopyableText from '../../../../../../../components/CopyableText';
import { useTranslation } from '../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../../reducer/mediaSlice';
import { amountDivideDecimals } from '../../../../../../../utils';
import { TRANSLATE_PREFIX } from '../../../../constants';
import LabeledDataContainer from '../../components/LabeledDataContainer';
import { TRANSLATE_KEY } from '../constants';

function AddressInformation({ data }: { data: TransactionDetailsRes }) {
  const { t, tc, te } = useTranslation(TRANSLATE_PREFIX);
  const isMobile = useAppSelector(selectIsMobileView);

  const toDisplayAmount = (amount: string) => amountDivideDecimals(amount, data.asset.decimal);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ fontSize: '14px', fontWeight: '700' }}>
        {t(TRANSLATE_KEY.addressInformation)}
      </div>
      <div style={{ backgroundColor: '#333333', padding: '12px 24px' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
            fontSize: '10px',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ opacity: 0.5 }}>{t(TRANSLATE_KEY.address)}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: isMobile ? undefined : 'flex-end',
            }}
          >
            <CopyableText text={data.walletAddress} iconWithTooltip={<CopyIcon />} />
          </div>
        </div>

        <hr style={{ opacity: 0.2, borderBottomWidth: '0px', margin: '12px 0px' }} />

        <LabeledDataContainer
          configArr={[
            {
              label: TRANSLATE_KEY.direction,
              data: te('enumDirection', Direction[data.direction]),
              isColored: true,
            },
            {
              label: TRANSLATE_KEY.postBalanceOfWalletAddress,
              data: toDisplayAmount(data.postBalance),
            },
            { label: TRANSLATE_KEY.displayName, data: data.inspectionWallet.walletName },
            {
              label: TRANSLATE_KEY.clientId,
              data: data.inspectionWallet.clientId,
            },
            {
              label: TRANSLATE_KEY.walletTag,
              data: data.walletTag || tc('not_available'),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default AddressInformation;

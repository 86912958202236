import { uniqueId } from 'lodash';
import { ReactNode } from 'react';

import { usePermission } from '../../../../hooks';
import CustomColorButton from '../../../Button/CustomColorButton';

function ButtonSection({
  buttonConfigArr,
}: {
  buttonConfigArr: {
    node: ReactNode;
    onClick: () => void;
    width: string;
    permission?: string;
    isNoPadding?: boolean;
  }[];
}) {
  const { hasPermission } = usePermission();

  return (
    <div style={{ display: 'flex', gap: '12px', maxWidth: '100%' }}>
      {buttonConfigArr.map(
        ({ width, node, onClick, permission, isNoPadding }) =>
          (!permission || hasPermission(permission)) && (
            <CustomColorButton
              key={uniqueId('button')}
              sx={{
                height: '32px',
                width,
                fontSize: '14px',
                lineHeight: 1,
                padding: isNoPadding ? 0 : undefined,
              }}
              variant="outlined"
              color={{ text: '#ffffff', border: '#474747' }}
              node={node}
              onClick={onClick}
            />
          )
      )}
    </div>
  );
}

export default ButtonSection;

export const TRANSLATE_KEY = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  settlementTime: 'settlement_time',
  transactionHash: 'transaction_hash',
  address: 'address',
  displayName: 'display_name',
  network: 'network',
  token: 'token',
  addressGroup: 'address_group',
  direction: 'direction',
  feeRecord: 'fee_record',
  riskLevel: 'risk_level',
  checkPoint: 'check_point',
  details: 'details',
  transactionTime: 'transaction_time',
  status: 'status',
  amount: 'amount',
  sendingAddress: 'sending_address',
  receivingAddress: 'receiving_address',
  minerFee: 'miner_fee',
  postBalance: 'post_balance',
  checkpointId: 'checkpoint_id',
  certifiedWithOnChainBalance: 'certified_with_on_chain_balance',
};

export const TRANSLATE_PREFIX = 'transaction';

export const OMIT_KEYS = { export: ['rawData'], table: [] };

export const EXPORT_FILE_NAME = 'transaction';

import './styles/index.css';

import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import APIs from '../../../api';
import CustomDataGrid from '../../../components/Layout/CustomDataGrid';
import Filter from '../../../components/Layout/Filter';
import PageTopBar from '../../../components/Layout/PageTopBar';
import { removeEmptyStringApiParams } from '../../../helper';
import { usePermission, useTranslation } from '../../../hooks';
import useGetNetworkInfo from '../../../hooks/useGetNetworkInfo';
import useLoading from '../../../hooks/useLoading';
import useRefreshPage from '../../../hooks/useRefreshPage';
import { selectAddressGroup } from '../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import { downloadFiles } from '../../../utils';
import DialogAddAddress from './components/Dialogs/AddAddress';
import { AddOrEditAddressFieldState } from './components/Dialogs/components/Content';
import DialogEditAddress from './components/Dialogs/EditAddress';
import DialogImportAddressLog from './components/Dialogs/ImportAddressLog';
import ImportCsvButton from './components/ImportCsvButton';
import {
  CSV_TEMPLATE_DATA,
  CSV_TEMPLATE_FILENAME,
  TRANSLATE_KEY,
  TRANSLATE_PREFIX,
} from './constants';
import useFilter from './hooks/useFilter';
import useImportCsv from './hooks/useImportCsv';
import useTableColumns from './hooks/useTableColumns';
import { useTransformData } from './hooks/useTransformData';
import { ApiParamsState } from './types';

function AddressList() {
  const isMobile = useAppSelector(selectIsMobileView);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { t } = useTranslation(TRANSLATE_PREFIX);
  const { fieldArr, fields, onResetFields } = useFilter();
  const [editDialogData, setEditDialogData] = useState<AddOrEditAddressFieldState>();

  const { addressGroupIdMapping, addressGroupNameMapping } = useAppSelector(selectAddressGroup);
  const { getChainCode, merchantSupportedAssetChainCodeMapping } = useGetNetworkInfo();

  const initialApiParams = useMemo(() => getApiParams(), []);

  const [apiParams, setApiParams] = useState<ApiParamsState>(getApiParams());
  const [page, setPage] = useState(0);
  const { hasPermission } = usePermission();

  const [dialogMode, setDialogMode] = useState<'idle' | 'addAddress' | 'editAddress'>('idle');

  const { refreshDependency, getNewRefreshDependency } = useRefreshPage();

  const { onFileChange, isImportResultDialogOpen, importResultLogData, onImportResultDialogClose } =
    useImportCsv({ getNewRefreshDependency });

  const queryRes = useQuery({
    queryKey: [TRANSLATE_PREFIX, refreshDependency, apiParams, page],
    queryFn: () => {
      showLoading(TRANSLATE_PREFIX);

      const removedEmptyStringParams = removeEmptyStringApiParams(apiParams);

      return APIs.addressManagement.addressList.getAll({ ...removedEmptyStringParams, page });
    },
    onSettled: () => {
      hideLoading(TRANSLATE_PREFIX);
    },
  });

  const { rows = [], count = 0 } = queryRes.data || {};

  const { columns } = useTableColumns({ onEditAddress });

  const { transformData } = useTransformData();

  const content = transformData({ data: rows, mode: 'table' });

  function getApiParams(): ApiParamsState {
    const {
      address: walletAddress,
      // chainCodes,
      chainCode,
      displayName: walletName,
      addressGroupNames,
    } = fields;

    const walletGroupId = addressGroupNames.map(
      (addressGroupName) => addressGroupNameMapping[addressGroupName].id
    );

    const { chainId = '', chainType = '' } =
      merchantSupportedAssetChainCodeMapping[chainCode] || {};

    return {
      walletGroupId,
      walletAddress,
      walletName,
      chainId: chainId,
      chainType: String(chainType),
    };
  }

  const onApply = () => {
    const apiParams = getApiParams();

    setApiParams(apiParams);

    getNewRefreshDependency();

    setPage(0);
  };

  const onClear = () => {
    onResetFields();
    setApiParams(initialApiParams);
  };

  const onDialogClose = () => setDialogMode('idle');

  function onEditAddress(editDialogData: AddOrEditAddressFieldState) {
    setDialogMode('editAddress');
    setEditDialogData(editDialogData);
  }

  const onDownloadTemplate = () => {
    downloadFiles(CSV_TEMPLATE_FILENAME, CSV_TEMPLATE_DATA, {
      needDate: false,
    });
  };

  const topBarBtnConfig = [
    {
      width: '125px',
      node: t(TRANSLATE_KEY.addAddress),
      onClick: () => setDialogMode('addAddress'),
      permission: InspectionWalletFeatureCode.AddressManagement.AddressList.AddAddress,
    },
    {
      width: '110px',
      node: t(TRANSLATE_KEY.template),
      onClick: onDownloadTemplate,
      permission: InspectionWalletFeatureCode.AddressManagement.AddressList.ImportExcel,
    },
    {
      width: '100px',
      node: <ImportCsvButton onFileChange={onFileChange} />,
      onClick: () => {},
      permission: InspectionWalletFeatureCode.AddressManagement.AddressList.ImportExcel,
      isNoPadding: true,
    },
  ];

  const getIsFiltering = () => {
    return Object.values(apiParams).some((value) => (Array.isArray(value) ? value.length : value));
  };

  const isFiltering = getIsFiltering();

  return (
    <div style={{ padding: isMobile ? '0 1rem' : undefined }}>
      {dialogMode === 'addAddress' && (
        <DialogAddAddress onClose={onDialogClose} onRefresh={getNewRefreshDependency} />
      )}
      {dialogMode === 'editAddress' && (
        <DialogEditAddress
          onClose={onDialogClose}
          onRefresh={getNewRefreshDependency}
          data={editDialogData}
        />
      )}
      {isImportResultDialogOpen && (
        <DialogImportAddressLog onClose={onImportResultDialogClose} data={importResultLogData} />
      )}

      <PageTopBar buttonConfigArr={topBarBtnConfig} />

      <Filter isFiltering={isFiltering} fieldArr={fieldArr} onApply={onApply} onClear={onClear} />
      <CustomDataGrid
        rows={content}
        rowCount={count}
        columns={columns}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        isLoading={isLoading}
      />
    </div>
  );
}

export default AddressList;

import { Tooltip } from '../../../components/MuiGenerals';
import { selectAddressGroup } from '../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../reducer/hooks';
import { getAddressGroupIcon } from '../../../views/AddressManagement/AddressGroup/helpers';

function AddressGroupCell({ id }: { id: string }) {
  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);

  const targetAddressGroup = Object.entries(addressGroupNameMapping).find(
    ([key, value]) => value.id === id
  );

  const name = targetAddressGroup?.[0];
  const { shape = '', colorCode = '' } = targetAddressGroup?.[1] || {};

  const Icon = getAddressGroupIcon({ shape, colorCode, style: { width: '16px', height: '16px' } });

  return (
    <Tooltip title={name}>
      <div style={{ maxWidth: '100%' }}>
        <div
          style={{
            backgroundColor: '#222222',
            height: '24px',
            borderRadius: '18px',
            border: '#474747 solid 1px',
            padding: '2px 8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'cneter' }}>{Icon}</div>
          <div
            style={{
              lineHeight: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </div>
        </div>
      </div>
    </Tooltip>
  );
}

export default AddressGroupCell;

import { useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../api';
import { setAddressGroupMappings } from '../reducer/addressGroupSlice';
import { useAppDispatch } from '../reducer/hooks';

export const useGetAddressGroup = ({ shouldFetch }: { shouldFetch: boolean }) => {
  const dispatch = useAppDispatch();

  const [isFetchingAddressGroup, setIsFetchingAddressGroup] = useState(true);

  useQuery({
    enabled: shouldFetch,
    queryKey: 'getAddressGroup',
    queryFn: APIs.query.addressGroup.getAddressGroup,
    staleTime: 60 * 60 * 12,
    onError: () => setIsFetchingAddressGroup(false),
    // onError: () => dispatch(setIsFetchingSupportedAsset(false)),
    onSuccess: async (data) => {
      if (!data) {
        setIsFetchingAddressGroup(false);
        return;
      }

      dispatch(setAddressGroupMappings(data));
    },
  });

  // useQuery({
  //   enabled: shouldFetch,
  //   queryKey: 'clientSupportedAsset',
  //   queryFn: APIs.query.asset.getClientSupportedAsset,
  //   staleTime: 60 * 60 * 12,
  //   onSuccess: (data) => {
  //     dispatch(setClientSupportedAssetList(data));
  //   },
  // });

  return { isFetchingAddressGroup };
};

import { cond } from 'lodash';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { Tooltip } from '../components/MuiGenerals';
import { COLOR_THEME } from '../style/colorTheme';
import { copyToClipboard } from '../utils';
import { tableConfig } from '../utils/constant';
import useAlerting from './useAlerting';
import usePermission from './usePermission';
import useTranslation, { Iprefix } from './useTranslation';

const sx = {
  cell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  copyableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: COLOR_THEME.CustomSx.ComponentHelper.AddressCell.Text,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export function useGenTableColumns({
  translatePrefix,
  columnConfigArr,
}: {
  translatePrefix: Iprefix;
  columnConfigArr: ({ translateKey: string; permissionKey?: string | string[] } & Partial<
    GridColDef<any, any, any>
  >)[];
}) {
  const { t, tc } = useTranslation(translatePrefix);
  const { hasPermission, hasPermissionMultipleEither } = usePermission();
  const { alerting } = useAlerting();
  const isDateField = (field: string) => {
    const nestedTranslateKeyArr = field.split('.');
    const lastTranslateKey = nestedTranslateKeyArr[nestedTranslateKeyArr.length - 1];

    return tableConfig.dateFields.includes(lastTranslateKey);
  };

  const defaultCase = () => true;
  const isCopyable = (field: string) => {
    const nestedTranslateKeyArr = field.split('.');
    const lastTranslateKey = nestedTranslateKeyArr[nestedTranslateKeyArr.length - 1];

    return tableConfig.copyableFields.includes(lastTranslateKey);
  };
  const isFreeTextField = (field: string) => {
    const nestedTranslateKeyArr = field.split('.');
    const lastTranslateKey = nestedTranslateKeyArr[nestedTranslateKeyArr.length - 1];

    return tableConfig.freeTextFields.includes(lastTranslateKey);
  };

  const renderCell = (params: any) => {
    const { value = '', field = '' } = params;
    const isBoolean = (v: any) => typeof v === 'boolean';
    const content = cond([
      [(v: any) => v === '0', () => '0'],
      [(v: any) => v === 0, () => 0],
      [(v: any) => !v, () => ''],
      [isBoolean, (v: any) => (v ? 'yes' : 'no')],
      [defaultCase, (v: any) => v],
    ])(value);
    return (
      <Tooltip title={content}>
        <span
          style={isCopyable(field) ? sx.copyableCell : sx.cell}
          className="csutable-cell-trucate"
          onClick={(e) => cellOnClick(e, field, value)}
        >
          {content}
        </span>
      </Tooltip>
    );
  };

  const cellOnClick = (e: any, field: string, value: string) => {
    if (isCopyable(field)) {
      copyToClipboard(value);
      alerting('success', tc(`copied`));
    }
  };

  const columns: GridColDef[] = columnConfigArr
    .filter(
      ({ permissionKey }) =>
        !permissionKey ||
        (Array.isArray(permissionKey)
          ? hasPermissionMultipleEither(permissionKey)
          : hasPermission(permissionKey))
    )
    .map(({ translateKey, ...rest }) => {
      const minWidth = cond([
        [isCopyable, () => tableConfig.addressWidth],
        [isDateField, () => tableConfig.dateWidth],
        [isFreeTextField, () => tableConfig.freeTextWidth],
        [defaultCase, () => tableConfig.defaultWidth],
      ])(translateKey);

      return {
        field: translateKey,
        headerName: t(translateKey),
        flex: 1,
        minWidth,
        renderCell,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        ...rest,
      };
    });

  return { columns };
}

export const renderColorText = (color: string) => (params: GridRenderCellParams<any, any, any>) => {
  return (
    <Tooltip title={params.value}>
      <div style={{ color }}>{params.value}</div>
    </Tooltip>
  );
};

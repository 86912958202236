import { useState } from 'react';

import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import PageTopBar from '../../../components/Layout/PageTopBar';
import { useAlerting, useTranslation } from '../../../hooks';
import useGetNetworkInfo from '../../../hooks/useGetNetworkInfo';
import useLoading from '../../../hooks/useLoading';
import { selectAddressGroup } from '../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import { useZusTranslatePrefixStore } from '../../../zustand/store';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from './constants';
import CustomPeriodicTab from './CustomPeriodicTab';
import DailyTab from './DailyTab';
import SummaryTab from './SummaryTab';
import { ExportBtnConfig, ReportTab } from './types';

function Report() {
  const isMobile = useAppSelector(selectIsMobileView);
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);
  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);
  const { merchantSupportedAssetChainCodeMapping, getAssetDecimal } = useGetNetworkInfo();
  const [selectedTab, setSelectedTab] = useState(ReportTab.Daily);

  const [exportBtnConfig, setExportBtnConfig] = useState<ExportBtnConfig>({
    onClick: () => {},
    permission: '',
  });

  const { alerting } = useAlerting();

  const topBarTabConfig = [
    {
      isSelected: selectedTab === ReportTab.Daily,
      node: t(TRANSLATE_KEY.daily),
      onClick: () => {
        setSelectedTab(ReportTab.Daily);
      },
      width: '133px',
      permission: InspectionWalletFeatureCode.Report.Report.Daily.prefix,
    },
    {
      isSelected: selectedTab === ReportTab.CustomPeriodic,
      node: t(TRANSLATE_KEY.customPeriodic),
      onClick: () => {
        setSelectedTab(ReportTab.CustomPeriodic);
      },
      width: '133px',
      permission: InspectionWalletFeatureCode.Report.Report.CustomPeriodic.prefix,
    },
    {
      isSelected: selectedTab === ReportTab.Summary,
      node: t(TRANSLATE_KEY.summary),
      onClick: () => {
        setSelectedTab(ReportTab.Summary);
      },
      width: '133px',
      permission: InspectionWalletFeatureCode.Report.Report.Summary.prefix,
    },
  ];

  const topBarBtnConfig = [
    {
      width: '125px',
      node: tc('export'),
      ...exportBtnConfig,
    },
  ];

  return (
    <div style={{ padding: isMobile ? '0 1rem' : undefined }}>
      <PageTopBar tabConfigArr={topBarTabConfig} buttonConfigArr={topBarBtnConfig} />
      {selectedTab === ReportTab.Daily && <DailyTab setExportBtnConfig={setExportBtnConfig} />}
      {selectedTab === ReportTab.CustomPeriodic && (
        <CustomPeriodicTab setExportBtnConfig={setExportBtnConfig} />
      )}
      {selectedTab === ReportTab.Summary && <SummaryTab setExportBtnConfig={setExportBtnConfig} />}
    </div>
  );
}

export default Report;

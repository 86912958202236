export const TRANSLATE_KEY = {
  addAddress: 'add_address',
  import: 'import',
  template: 'template',

  operation: 'operation',
  displayName: 'display_name',
  addressGroup: 'address_group',
  address: 'address',
  network: 'network',
  addAddressDate: 'add_address_date',

  editAddress: 'edit_address',
  addressBalance: 'address_balance',
  report: 'report',

  importAddressFormatErrorMessage: 'import_address_format_error_message',
  importAddressNetworkErrorMessage: 'import_address_network_error_message',
  importAddressAddressGroupErrorMessage: 'import_address_address_group_error_message',
} as const;

export const TRANSLATE_PREFIX = 'addressList';

export const CSV_TEMPLATE_FILENAME = 'import_address_template';
export const IMPORT_ADDRESS_LOG_FILENAME = 'import_address_log';

export const IMPORT_RESULT_DIALOG_TABLE_PAGE_SIZE = 10;

export const CSV_TEMPLATE_DATA = [
  { display_name: null, address_group: null, address: null, network: null },
  {
    display_name: 'Example_1',
    address_group: 'example_123',
    address: 'xxx',
    network: 'EXAMPLE',
  },
  {
    display_name: 'Bitcoin',
    address_group: 'group_example',
    address: 'xxx',
    network: 'BTC',
  },
  {
    display_name: 'Ethereum',
    address_group: 'group_example',
    address: 'xxx',
    network: 'ETH',
  },
  {
    display_name: 'Tron',
    address_group: 'group_example',
    address: 'xxx',
    network: 'TRX',
  },
  {
    display_name: 'Dogecoin',
    address_group: 'group_example',
    address: 'xxx',
    network: 'DOGE',
  },
  {
    display_name: 'Ripple',
    address_group: 'group_example',
    address: 'xxx',
    network: 'XRP',
  },
  {
    display_name: 'Binance Smart Chain',
    address_group: 'group_example',
    address: 'xxx',
    network: 'BSC',
  },
  {
    display_name: 'Polygon',
    address_group: 'group_example',
    address: 'xxx',
    network: 'MATIC',
  },
  {
    display_name: 'Litecoin',
    address_group: 'group_example',
    address: 'xxx',
    network: 'LTC',
  },
  {
    display_name: 'Cardano',
    address_group: 'group_example',
    address: 'xxx',
    network: 'ADA',
  },
  {
    display_name: 'Bitcoin Cash',
    address_group: 'group_example',
    address: 'xxx',
    network: 'BCH',
  },
];

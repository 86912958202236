import CustomColorButton from '../../../../components/Button/CustomColorButton';
import { useTranslation } from '../../../../hooks';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';
import { getAddressGroupIcon } from '../helpers';
import { AddressGroupData } from '../types';

function AddressGroupRow({
  name,
  shape,
  colorCode,
  id,
  onEditGroup,
}: AddressGroupData & {
  onEditGroup: () => void;
}) {
  const { t } = useTranslation(TRANSLATE_PREFIX);

  const onViewBalance = () => {
    window.open(`/addressBalance?addressGroupName=${name}`);
  };
  const onViewTransaction = () => {
    window.open(`/transaction?addressGroupName=${name}`);
  };

  const addressGroupIcon = getAddressGroupIcon({
    shape,
    colorCode,
    style: { width: '24px', height: '24px' },
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        gap: '8px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {addressGroupIcon}

        <div style={{ color: '#ffffff', fontWeight: 700, fontSize: '14px' }}>{name}</div>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <CustomColorButton
          sx={{
            height: '32px',
            lineHeight: 1,
          }}
          onClick={onViewBalance}
          color={{ border: '#474747', text: '#ffffff' }}
          node={t(TRANSLATE_KEY.viewBalance)}
          variant={'outlined'}
        />
        <CustomColorButton
          sx={{
            height: '32px',
            lineHeight: 1,
          }}
          onClick={onViewTransaction}
          color={{ border: '#474747', text: '#ffffff' }}
          node={t(TRANSLATE_KEY.viewTransaction)}
          variant={'outlined'}
        />
        <CustomColorButton
          sx={{
            height: '32px',
            lineHeight: 1,
          }}
          onClick={onEditGroup}
          color={{ border: '#474747', text: '#ffffff' }}
          node={t(TRANSLATE_KEY.editGroup)}
          variant={'outlined'}
        />
      </div>
    </div>
  );
}

export default AddressGroupRow;

import GeneralSelection from '../../../../components/Selection/GeneralSelection';
import { useTranslation } from '../../../../hooks';
import { selectAsset } from '../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function TokenSelection({
  chainCode,
  label,
  ...props
}: { chainCode: string; label?: string } & (
  | {
      mode: 'single';
      value: string;
      onChange: (value: string) => void;
      clearSelect: () => void;
    }
  | {
      mode: 'multiple';
      value: string[];
      onChange: (value: string[]) => void;
    }
)) {
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);

  return (
    <GeneralSelection
      label={label || tc('phSelection', { fieldName: t(TRANSLATE_KEY.token) })}
      enumData={
        clientSupportedAssetChainCodeMapping[chainCode]?.assetNameList.reduce(
          (acc, cur) => ({ ...acc, [cur]: cur }),
          {}
        ) || {}
      }
      nameFn={(name) => name}
      isNoSorting
      {...props}
    />
  );
}

export default TokenSelection;

import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import { PAGE_SIZE } from '../../../constants/config';
import axios from '../../axiosInstance';
import { ApiConfig, IlistResult } from '../../types';
import { AddressListGetAllRes } from './types';

const { addressManagement } = EPt;

const getAll = async (params?: Record<string, any>, config?: ApiConfig) => {
  const { page = 0, pageSize = PAGE_SIZE, ...rest } = params || {};

  const input = {
    params: { ...rest, offset: page * pageSize, limit: pageSize },
    ...config,
  };
  return await axios.get<any, IlistResult<AddressListGetAllRes> | undefined>(
    addressManagement.addressListGetAll,
    input
  );
};
const createAddress = async (
  params: {
    chainType: number;
    chainId: string;
    walletAddress: string;
    walletName: string;
    walletGroupId: string;
  },
  config?: ApiConfig
) => {
  return await axios.post<any, any>(addressManagement.addressListCreate, params, config);
};
const updateAddress = async (
  params: {
    chainType: number;
    chainId: string;
    walletAddress: string;
    walletName: string;
    walletGroupId: string;
  },
  config?: ApiConfig
) => {
  return await axios.post<any, any>(addressManagement.addressListUpdate, params, config);
};
const deleteAddress = async (
  params: {
    chainType: number;
    chainId: string;
    walletAddress: string;
  },
  config?: ApiConfig
) => {
  return await axios.post<any, any>(addressManagement.addressListDelete, params, config);
};

export default { getAll, createAddress, updateAddress, deleteAddress };

import { Dispatch, SetStateAction } from 'react';

import { ReactComponent as FilterArrowIcon } from '../../../../../../assets/icons/Filter/filter-button-arrow.svg';
import { ReactComponent as FilterIcon } from '../../../../../../assets/icons/Filter/filter-button.svg';
import CustomColorButton from '../../../../../Button/CustomColorButton';

function CollapseButton({
  isFiltering,
  isCollapsed,
  setIsCollapsed,
}: {
  isFiltering: boolean;
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <CustomColorButton
      sx={{ width: '119px', height: '32px' }}
      variant="outlined"
      node={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <FilterIcon fill={isFiltering ? '#EDA30E' : '#ffffff'} />
            <div style={{ color: isFiltering ? '#EDA30E' : '#ffffff' }}>Filter</div>
          </div>
          <FilterArrowIcon
            style={{
              transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s',
            }}
          />
        </div>
      }
      color={{ text: '#ffffff', border: '#474747' }}
      onClick={() => {
        setIsCollapsed((prev) => !prev);
      }}
    />
  );
}

export default CollapseButton;

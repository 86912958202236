import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as RedirectIcon } from '../../../../../../assets/icons/AddressListOperation/redirect-arrow.svg';
import AddressGroupSelection from '../../../../../../common/components/Filter/Selection/AddressGroupSelection';
import UnderlinedButton from '../../../../../../components/Button/UnderlinedButton';
import FieldWithLabel from '../../../../../../components/FieldWithLabel';
import { useTranslation } from '../../../../../../hooks';
import { TRANSLATE_PREFIX } from '../../../constants';
import { AddOrEditAddressFieldState } from '../components/Content';
import { TRANSLATE_KEY } from '../constants';

function AddressGroupSelectionField({
  fields,
  setFields,
}: {
  fields: AddOrEditAddressFieldState;
  setFields: Dispatch<SetStateAction<AddOrEditAddressFieldState>>;
}) {
  const navigate = useNavigate();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  return (
    <FieldWithLabel
      infoNode={
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>{t(TRANSLATE_KEY.addressGroupNoticeText)}</div>
          <UnderlinedButton
            color="#FFD100"
            fontSize="10px"
            onClick={() => {
              navigate('/addressGroup');
            }}
            node={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: 'initial',
                  gap: '4px',
                }}
              >
                <div>{t(TRANSLATE_KEY.addressGroup)}</div>
                <RedirectIcon />
              </div>
            }
          />
        </div>
      }
      label={t(TRANSLATE_KEY.addressGroup)}
      fieldComponent={
        <AddressGroupSelection
          mode={'single'}
          value={fields.addressGroupName}
          onChange={(value) =>
            setFields((prev) => ({
              ...prev,
              addressGroupName: value,
            }))
          }
          clearSelect={() => {}}
        />
      }
    />
  );
}

export default AddressGroupSelectionField;

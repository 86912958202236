import { ReactNode, useEffect, useState } from 'react';

import Collapse from '@mui/material/Collapse';

import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import FieldWithLabel from '../../FieldWithLabel';
import ButtonGroup from './components/ButtonGroup';

function Filter({
  isFiltering,
  fieldArr,
  onApply,
  onClear,
  isDefaultExpanded = false,
}: {
  isFiltering: boolean;
  fieldArr: { name: string; component: ReactNode }[];
  onApply: () => void;
  onClear: () => void;
  isDefaultExpanded?: boolean;
}) {
  const [isCollapsed, setIsCollapsed] = useState(!isDefaultExpanded);
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);

  useEffect(() => {
    if (!isCollapsed) {
      return;
    }

    setIsAnimationEnabled(true);
  }, [isCollapsed]);

  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <ButtonGroup
        isFiltering={isFiltering}
        isCollapsed={isCollapsed}
        isAnimationEnabled={isAnimationEnabled}
        setIsCollapsed={setIsCollapsed}
        onApply={onApply}
        onClear={onClear}
      />

      <Collapse in={!isCollapsed}>
        <div
          style={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : 'minmax(0, 1fr) minmax(0, 1fr)',
            columnGap: '40px',
            rowGap: '24px',
            marginTop: '8px',
          }}
        >
          {fieldArr.map(({ name, component }) => (
            <FieldWithLabel key={'filter-' + name} label={name} fieldComponent={component} />
          ))}
        </div>
      </Collapse>

      {
        <hr
          className={
            isAnimationEnabled
              ? `filter-divide-line ${isCollapsed ? 'collapsed' : 'expanded'}`
              : undefined
          }
          style={{
            width: '100%',
            margin: '24px 0',
            opacity: 0.2,
            display: isCollapsed ? 'none' : 'initial',
            borderBottomWidth: '0px',
          }}
        />
      }
    </div>
  );
}

export default Filter;

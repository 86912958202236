import EPt from '@wallet-manager/node-types/dist/src/access/APIEndpoints/Inspection';

import axios from '../axiosInstance';
import { ApiConfig } from '../types';

const { query } = EPt;

const getAddressGroup = async (config?: ApiConfig) => {
  const input = {
    ...config,
  };
  return await axios.get<any, any>(query.addressGroup, input);
};

export default { getAddressGroup };

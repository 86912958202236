import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAddressGroupIconInfo } from '../helper';
import { RootState } from './store';

type AddressGroupApiRes = {
  id: string;
  merchantId: string;
  clientId: string;
  groupName: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  icon: string;
};

export type AddressGroupNameMappingInfo = { id: string; shape: string; colorCode: string };
export type AddressGroupIdMappingInfo = { name: string; shape: string; colorCode: string };

type AddressGroupNameMapping = Record<string, AddressGroupNameMappingInfo>;
type AddressGroupIdMapping = Record<string, AddressGroupIdMappingInfo>;

type AddressGroupState = {
  addressGroupNameMapping: AddressGroupNameMapping;
  addressGroupIdMapping: AddressGroupIdMapping;
};

const initialState: AddressGroupState = {
  addressGroupNameMapping: {},
  addressGroupIdMapping: {},
};

export const addressGroupSlice = createSlice({
  name: 'addressGroup',
  initialState,
  reducers: {
    setAddressGroupMappings: (state, { payload }: PayloadAction<AddressGroupApiRes[]>) => {
      const nameMapping = payload.reduce(
        (acc: AddressGroupNameMapping, { groupName, icon, id }) => {
          const iconInfo = getAddressGroupIconInfo(icon);

          return { ...acc, [groupName]: { ...iconInfo, id } };
        },
        {}
      );

      const idMapping = payload.reduce(
        (acc: AddressGroupIdMapping, { groupName: name, icon, id }) => {
          const iconInfo = getAddressGroupIconInfo(icon);

          return { ...acc, [id]: { ...iconInfo, name } };
        },
        {}
      );

      state.addressGroupNameMapping = nameMapping;
      state.addressGroupIdMapping = idMapping;
    },
    upsertAddressGroup: (
      state,
      { payload }: PayloadAction<AddressGroupNameMappingInfo & AddressGroupIdMappingInfo>
    ) => {
      const { name, id, ...rest } = payload;

      const removedTargetAddressGroupNameMapping = Object.fromEntries(
        Object.entries(state.addressGroupNameMapping).filter(([_key, val]) => val.id !== id)
      );

      state.addressGroupNameMapping = {
        ...removedTargetAddressGroupNameMapping,
        [payload.name]: { id, ...rest },
      };
      state.addressGroupIdMapping = {
        ...state.addressGroupIdMapping,
        [payload.id]: { name, ...rest },
      };
    },

    deleteAddressGroup: (state, { payload }: PayloadAction<{ name: string; id: string }>) => {
      delete state.addressGroupNameMapping[payload.name];
      delete state.addressGroupNameMapping[payload.id];
    },
  },
});

export const { setAddressGroupMappings, upsertAddressGroup, deleteAddressGroup } =
  addressGroupSlice.actions;

export const selectAddressGroup = (state: RootState) => state.addressGroup;

export default addressGroupSlice.reducer;

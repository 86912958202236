import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../reducer/hooks';
import {
  addLoadingKey,
  removeLoadingKey,
  selectLoadingKeys
} from '../reducer/loadingSlice';

// Control on/off loading
// UI: src/components/LoadingModal

const useLoading = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const loadingKeys = useAppSelector(selectLoadingKeys);

  // console.log('222 loadingKeys :', loadingKeys);

  const showLoading = (key: string) => {
    setIsLoading(true);
    dispatch(addLoadingKey({ key }));
  };

  const hideLoading = (key: string) => {
    setIsLoading(false);
    dispatch(removeLoadingKey({ key }));
  };

  return { isLoading, showLoading, hideLoading };
};

export default useLoading;

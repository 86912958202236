import { useEffect } from 'react';

import { useAlerting, useTranslation } from '../../hooks';

export default function NoPermissionElement() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation();

  useEffect(() => alerting('error', tc('alert_no_permission')), []);

  return <></>;
}

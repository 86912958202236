import './index.css';

import React from 'react';

import { COLOR_THEME } from '../../../style/colorTheme';
import ToggleButton from './components/ToggleButton';

interface IProps {
  children: React.ReactNode;
  isCollapse: boolean;
  onCollapseClick?: () => void;
  isMobileView?: boolean;
  isFirstClickMobileMenu?: boolean;
}

const SideMenu = ({
  children,
  isCollapse,
  onCollapseClick,
  isMobileView,
  isFirstClickMobileMenu,
}: IProps) => {
  const addClassNameIfIsCollapse = (className: string) => {
    return isCollapse ? className : '';
  };

  const mobileContainerClass = isFirstClickMobileMenu
    ? 'mobile-initial-container'
    : isCollapse
    ? 'slide-out'
    : 'slide-in';

  return (
    <div
      className={
        isMobileView
          ? mobileContainerClass
          : `side-menu-container ${addClassNameIfIsCollapse('instant-collapse')}`
      }
      style={{ position: 'relative' }}
    >
      <div
        className={`side-menu ${addClassNameIfIsCollapse('collapsed')}`}
        style={{
          background: COLOR_THEME.Menu.Background,
        }}
      >
        {!isMobileView && (
          <ToggleButton onCollapseClick={onCollapseClick} isCollapse={isCollapse} />
        )}

        <div
          className={`content ${addClassNameIfIsCollapse('fade-out')}`}
          style={{
            background: COLOR_THEME.Menu.Background,
            transitionDelay: isCollapse ? '0s' : '0.3s',
          }}
        >
          <div className="divide-line-top" />
          {children}
          <div className="divide-line-bottom" />
        </div>
      </div>
    </div>
  );
};

export default SideMenu;

import { CSSProperties } from 'react';

import { styled } from '@mui/material';

import { useTranslation } from '../../../hooks';
import { importantStyle } from '../../../utils';
import { Button, DialogActions } from '../../MuiGenerals';

const StyledDialogActions = styled(DialogActions)(
  () => `
    padding: 12px 0;
    boxShadow: '0px -2px 8px 0px #00000026';
  `
);

interface ActionSectionProps {
  actionButtons?: React.ReactNode;
  isConfirmHidden?: boolean;
  isCancelHidden?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  sx?: CSSProperties;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const ActionSection = (props: ActionSectionProps) => {
  const {
    actionButtons,
    isConfirmHidden,
    isCancelHidden,
    onConfirm,
    onCancel,
    sx,
    cancelButtonText,
    confirmButtonText,
  } = props;

  const { tc } = useTranslation();

  const cancelBtnText = cancelButtonText || tc('cancel');
  const confirmBtnText = confirmButtonText || tc('confirm');

  return (
    <StyledDialogActions
      sx={
        sx
          ? {
              button: {
                marginLeft: importantStyle(0),
              },
              ...sx,
            }
          : {
              display: 'flex',
              justifyItems: 'flex-end',
              gap: '12px',
              paddingRight: '12px',
              button: {
                marginLeft: importantStyle(0),
              },
            }
      }
    >
      {actionButtons}
      {!isConfirmHidden && (
        <Button
          className={'inheritColor'}
          color={'primary'}
          variant={'contained'}
          children={confirmBtnText}
          onClick={onConfirm}
        />
      )}
      {!isCancelHidden && (
        <Button
          className={'inheritColor'}
          color={'secondary'}
          variant={'contained'}
          // children={tc('cancel')}
          children={cancelBtnText}
          onClick={onCancel}
        />
      )}
    </StyledDialogActions>
  );
};

export default ActionSection;

import React, { ReactNode } from 'react';

import { ButtonProps } from '@mui/material';

import { importantStyle } from '../../utils';
import { Button } from '../MuiGenerals';

function CustomColorButton(
  props: {
    node: ReactNode;
    onClick: (
      event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
    ) => Promise<any> | any;
  } & Omit<ButtonProps, 'color'> &
    (
      | { variant: 'outlined'; color: { border: string; text: string; background?: string } }
      | { variant: 'contained'; color: { background: string; text: string } }
    )
) {
  const { node, onClick, variant, color, ...rest } = props;

  const untypedStyle = rest.sx as any;

  return (
    <Button
      {...rest}
      variant={variant || 'contained'}
      onClick={onClick}
      sx={{
        borderRadius: '6px',
        opacity: rest.disabled ? 0.5 : 1,
        backgroundColor: color.background && importantStyle(color.background),
        ':hover': {
          backgroundColor: color.background && importantStyle(color.background),
          filter: variant === 'contained' ? 'brightness(85%)' : undefined,
          ...untypedStyle?.[':hover'],
        },
        color: importantStyle(color.text),
        border: variant === 'outlined' ? `1px solid ${color.border}` : undefined,
        padding: '0 12px',
        textTransform: 'initial',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        ...rest.sx,
      }}
    >
      {node}
    </Button>
  );
}

export default CustomColorButton;

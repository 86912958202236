import './styles/index.css';

import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import APIs from '../../../api';
import { TransactionDetailsRes } from '../../../api/report/transaction/types';
import CustomDataGrid from '../../../components/Layout/CustomDataGrid';
import Filter from '../../../components/Layout/Filter';
import PageTopBar from '../../../components/Layout/PageTopBar';
import { removeEmptyStringApiParams } from '../../../helper';
import { useAlerting, useTranslation } from '../../../hooks';
import useGetNetworkInfo from '../../../hooks/useGetNetworkInfo';
import useLoading from '../../../hooks/useLoading';
import useRefreshPage from '../../../hooks/useRefreshPage';
import { selectAddressGroup } from '../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import { downloadFiles, getFullApiResponse } from '../../../utils';
import { EnumBoolean } from '../../../utils/constant';
import DialogDetails from './components/Dialogs/Details';
import { EXPORT_FILE_NAME, OMIT_KEYS, TRANSLATE_PREFIX } from './constants';
import useFilter from './hooks/useFilter';
import useTable from './hooks/useTableColumns';
import { useTransformData } from './hooks/useTransformData';
import { ApiParamsState } from './types';

function Transaction() {
  const isMobile = useAppSelector(selectIsMobileView);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const { fieldArr, fields, onResetFields } = useFilter();
  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);
  const { merchantSupportedAssetChainCodeMapping, getAssetDecimal } = useGetNetworkInfo();

  const initialApiParams = useMemo(() => getApiParams(), []);

  const [apiParams, setApiParams] = useState<ApiParamsState>(initialApiParams);
  const { refreshDependency, getNewRefreshDependency } = useRefreshPage();
  const [page, setPage] = useState(0);

  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [detailsDialogData, setDetailsDialogData] = useState<TransactionDetailsRes>();

  const { alerting } = useAlerting();

  const { columns } = useTable({ onDetailsClick });

  const { transformData } = useTransformData();

  const queryRes = useQuery({
    queryKey: [TRANSLATE_PREFIX, apiParams, page, refreshDependency],
    queryFn: () => {
      showLoading(TRANSLATE_PREFIX);

      const removedEmptyStringParams = removeEmptyStringApiParams(apiParams);

      return APIs.report.transaction.getAll({ ...removedEmptyStringParams, page });
    },
    onSettled: () => {
      hideLoading(TRANSLATE_PREFIX);
    },
  });

  const { rows = [], count = 0 } = queryRes.data || {};

  const content = transformData({ data: rows, mode: 'table' });

  async function onDetailsClick(id: string) {
    const res = await APIs.report.transaction.getDetails({ id });

    if (!res) {
      return;
    }

    setDetailsDialogData(res);

    setIsDetailsDialogOpen(true);
  }

  const onDetailsDialogClose = () => {
    setIsDetailsDialogOpen(false);
  };

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const removedEmptyStringParams = removeEmptyStringApiParams(apiParams);

    const apiFn = (page: number, pageSize: number, signal: any) =>
      APIs.report.transaction.getExport(
        { ...removedEmptyStringParams, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = OMIT_KEYS.export;

    const res = transformData({ mode: 'export', data: rawRes, omitKeys });
    const config = {};
    downloadFiles(EXPORT_FILE_NAME, res, config);
  };

  function getApiParams(): ApiParamsState {
    const {
      address: walletAddress,
      // chainCodes,
      chainCode,
      displayName: walletName,
      tokens: assetName,
      addressGroupNames,
      transactionHash,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      settlementDateFrom,
      settlementDateTo,
      feeRecord,
      checkPoint,
      riskLevel,
      direction,
    } = fields;

    const walletGroupId = addressGroupNames.map(
      (addressGroupName) => addressGroupNameMapping[addressGroupName].id
    );

    const { chainId = '', chainType = '' } =
      merchantSupportedAssetChainCodeMapping[chainCode] || {};

    return {
      walletGroupId,
      walletAddress,
      walletName,
      chainId: chainId,
      chainType: String(chainType),
      assetName,
      txHash: transactionHash,
      isFee: feeRecord === EnumBoolean.Yes ? true : feeRecord === EnumBoolean.No ? false : '',
      riskLevel,
      direction,
      // checkPointCertified: checkPoint, // TODO add checkpoint filter back
      createdDateFrom,
      createdDateTo,
      dateLastModifiedFrom: lastModifiedDateFrom,
      dateLastModifiedTo: lastModifiedDateTo,
      walletSettlementDateFrom: settlementDateFrom,
      walletSettlementDateTo: settlementDateTo,
    };
  }

  const onApply = () => {
    const apiParams = getApiParams();

    setApiParams(apiParams);

    getNewRefreshDependency();

    setPage(0);
  };

  const onClear = () => {
    onResetFields();
    setApiParams(initialApiParams);
  };

  const getIsFiltering = () => {
    return Object.values(apiParams).some((value) => (Array.isArray(value) ? value.length : value));
  };

  const isFiltering = getIsFiltering();

  return (
    <div style={{ padding: isMobile ? '0 1rem' : undefined }}>
      {isDetailsDialogOpen && (
        <DialogDetails data={detailsDialogData} onClose={onDetailsDialogClose} />
      )}
      <PageTopBar
        buttonConfigArr={[
          {
            width: '125px',
            node: tc('export'),
            onClick: onExport,
            permission: InspectionWalletFeatureCode.Report.Transaction.Export,
          },
        ]}
      />

      <Filter isFiltering={isFiltering} fieldArr={fieldArr} onApply={onApply} onClear={onClear} />
      <CustomDataGrid
        rows={content}
        rowCount={count}
        columns={columns}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Transaction;

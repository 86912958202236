import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTranslation } from '../../../../hooks';
import { TRANSLATE_KEY } from '../constants';

function useSearchBar<T extends Record<string, any>>({
  dbDataArr,
  searchInput,
  transformDataToSearchTargetFns,
  mode,
  sortingComparisonFn,
}: {
  dbDataArr: Array<T> | undefined;
  searchInput: string;
  transformDataToSearchTargetFns: Array<(data: T) => string | string[]>;
  mode: 'exact' | 'partial' | 'includes';
  sortingComparisonFn?: (a: T, b: T) => number;
}) {
  const { t } = useTranslation('searchBar');
  const [searchParams, setSearchParams] = useSearchParams();

  const redirectSearchInput = searchParams.get('search');

  const filteredResult = dbDataArr?.filter((data) =>
    transformDataToSearchTargetFns.some((transformDataToSearchTargetFn) => {
      const searchTarget = transformDataToSearchTargetFn(data);
      const searchInputInLowerCase = searchInput.toLowerCase();

      const comparisonFn =
        mode === 'partial'
          ? (searchTarget: string) =>
              searchTarget.toLowerCase().substring(0, searchInputInLowerCase.length) ===
              searchInputInLowerCase
          : mode === 'includes'
          ? (searchTarget: string) => searchTarget.toLowerCase().includes(searchInputInLowerCase)
          : (searchTarget: string) =>
              !searchInputInLowerCase.length ||
              searchTarget.toLowerCase() === searchInputInLowerCase;

      return Array.isArray(searchTarget)
        ? searchTarget.some((key) => comparisonFn(key))
        : comparisonFn(searchTarget);
    })
  );

  const searchResult = sortingComparisonFn
    ? filteredResult?.sort(sortingComparisonFn)
    : filteredResult;

  const [displayDataArr, setDisplayDataArr] = useState<Array<T> | undefined>(searchResult);

  useEffect(() => {
    setDisplayDataArr(searchResult);
  }, [dbDataArr]);

  useEffect(() => {
    if (!redirectSearchInput) {
      return;
    }

    setSearchParams({});
  }, [searchInput]);

  const getSearchResult = () => {
    setDisplayDataArr(searchResult);
  };

  const getNoItemText = (itemName: string) => {
    if (!dbDataArr || !displayDataArr) {
      return undefined;
    }

    const noDataInDb = !dbDataArr.length ? t(TRANSLATE_KEY.noDbData, { itemName }) : undefined;

    const noRelevantData = !displayDataArr.length
      ? t(TRANSLATE_KEY.noSearchResult, { itemName })
      : undefined;

    return noDataInDb || noRelevantData;
  };

  return { displayDataArr: displayDataArr || [], getSearchResult, getNoItemText };
}

export default useSearchBar;

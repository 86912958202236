import { useTranslation } from '../../../../../../hooks';
import { SHAPE_OBJ, TRANSLATE_PREFIX } from '../../../constants';
import { TRANSLATE_KEY } from '../constants';

function ColorPicker({
  selectedShape,
  onSelectShape,
}: {
  selectedShape: string;
  onSelectShape: (shape: string) => void;
}) {
  const { t } = useTranslation(TRANSLATE_PREFIX);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <div>{t(TRANSLATE_KEY.chooseShape)}</div>
      <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '32px', rowGap: '16px' }}>
        {Object.entries(SHAPE_OBJ).map(([title, { Icon }]) => {
          const isSelected = title === selectedShape;
          // const strokeOpacity = isSelected ? 1 : 0.4;
          const isWalletIcon = title === 'wallet';

          const fillWalletIconColor = isSelected ? '#FFFFFF' : '#AAAAAA';

          return (
            <Icon
              key={title}
              width={30}
              height={30}
              style={{ cursor: 'pointer' }}
              // strokeOpacity={strokeOpacity}
              stroke={isSelected ? '#FFFFFF' : '#AAAAAA'}
              fill={isWalletIcon ? fillWalletIconColor : undefined}
              // fill={isSpade ? `rgba(255, 255, 255, 0.4)` : undefined}
              onClick={() => {
                onSelectShape(title);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ColorPicker;

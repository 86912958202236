import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import VerificationInput from 'react-verification-input';

import { LoginContext } from '../';
import APIs from '../../../../api';
import { RequestType } from '../../../../api/auth';
import { useAlerting, useTranslation } from '../../../../hooks';
import PageWrapper from '../components/PageWrapper';
import useEmailCodeApi from '../helpers/useEmailCodeApi';
import useEmailVerificationValidation from '../helpers/useEmailVerificationValidation';
import useLoginRedirect from '../helpers/useLoginRedirect';
import { LoginFieldsKeys, PageKeys } from '../types';

interface IProps {}

const EmailVerification = (props: IProps) => {
  const loginContext = useContext(LoginContext);

  const { fields, setFields, setPage, merchantId } = loginContext;

  const { alerting } = useAlerting();
  const { validate } = useEmailVerificationValidation({ fields });
  const { t } = useTranslation('login');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resendCounter, setResendCounter] = useState(60);
  const isRequestingRef = useRef(false);
  const timeIntervalId = useRef<NodeJS.Timer>();

  const { requestEmailVerificationCode, verifyEmailVerificationCode } = useEmailCodeApi();
  const { navigateToRedirect } = useLoginRedirect();

  const onSubmit = async () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('warning', validationResult.allErrors[0]);
      return;
    }

    setIsSubmitting(true);
    const veriftEmailVerificationCodeRes = await verifyEmailVerificationCode(
      fields[LoginFieldsKeys.EmailVerificationCode]
    );

    if (!veriftEmailVerificationCodeRes) return setIsSubmitting(false);

    const loginRes = await APIs.auth.login();

    setIsSubmitting(false);

    if (!loginRes) return;

    navigateToRedirect({
      accessToken: loginRes.accessToken,
      refreshToken: loginRes.refreshToken,
      features: loginRes.features,
    });
  };

  const onRequestEmailCode = async () => {
    isRequestingRef.current = true;

    const res = await requestEmailVerificationCode({
      email: fields[LoginFieldsKeys.Email],
      clientId: fields[LoginFieldsKeys.ClientId],
      merchantId,
      requestType: RequestType.login,
    });

    if (!res) {
      setResendCounter(0);
      isRequestingRef.current = false;
    }
  };

  useEffect(() => {
    if (fields[LoginFieldsKeys.EmailVerificationCode].length === 6 && !isSubmitting) {
      onSubmit();
    }
  }, [fields[LoginFieldsKeys.EmailVerificationCode]]);

  const handleRequestVerificationCode = async () => {
    setResendCounter(60);
  };

  const resendBtnWording = useMemo(() => {
    if (timeIntervalId.current) {
      clearInterval(timeIntervalId.current);
    }

    if (!resendCounter) {
      isRequestingRef.current = false;

      return 'Resend';
    }

    timeIntervalId.current = setInterval(() => {
      setResendCounter((prev) => prev - 1);
    }, 1_000);

    return `Available to resend code in ${resendCounter} second${resendCounter > 1 ? 's' : ''}`;
  }, [resendCounter]);

  useEffect(() => {
    if (resendCounter !== 60 || isRequestingRef.current) {
      return;
    }

    onRequestEmailCode();
  }, [resendCounter]);

  const onSubmitBtnClick = async () => {
    if (!isSubmitting) {
      await onSubmit();
    }
  };

  return (
    <PageWrapper
      submitBtn={{ wording: 'Confirm', onSubmit: onSubmitBtnClick }}
      onBackBtnClick={() => setPage(PageKeys.EnterEmail)}
      hintWording={'Please enter the security code that we have just sent to'}
    >
      <div className="login-field">
        <div className="email-display-container">
          <div>{fields[LoginFieldsKeys.Email]}</div>
        </div>
        <VerificationInput
          classNames={{
            container: 'email-verification-container',
            character: 'email-verification-char',
            characterSelected: 'email-verification-char-selected',
          }}
          placeholder=""
          autoFocus
          value={fields[LoginFieldsKeys.EmailVerificationCode]}
          onChange={(value) =>
            setFields((prev) => ({ ...prev, [LoginFieldsKeys.EmailVerificationCode]: value }))
          }
        />
        <div className="resend-btn-container">
          <button
            type="button"
            className="resend-btn"
            onClick={handleRequestVerificationCode}
            disabled={!!resendCounter}
            style={
              !!resendCounter
                ? {
                    color: 'rgba(255, 255, 255, 0.5)',
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    cursor: 'wait',
                  }
                : undefined
            }
          >
            {resendBtnWording}
          </button>
        </div>
        {/* <EmailVerificationCodeInputWithRequestBtn
          fields={fields}
          setFields={setFields}
          handleRequestVerificationCode={handleRequestVerificationCode}
          requestCodeOnMount={true}
        /> */}
      </div>
    </PageWrapper>
  );
};

export default EmailVerification;

import APIs from '../../../../api';
import CustomColorButton from '../../../../components/Button/CustomColorButton';
import GeneralSelection from '../../../../components/Selection/GeneralSelection';
import { useAlerting, useTranslation } from '../../../../hooks';
import {
  MerchantSupportedAssetListState,
  selectAsset,
  upsertClientSupportedAssetChainCodeMapping
} from '../../../../reducer/assetSlice';
import { useAppDispatch, useAppSelector } from '../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../reducer/mediaSlice';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

export type AssetItem = {
  network: string;
  tokenArr: string[];
};

function AssetSelectionRow(
  props: MerchantSupportedAssetListState & {
    value: string[];
    setValue: (arr: string[]) => void;
    isDisabled: boolean;
  }
) {
  const isMobile = useAppSelector(selectIsMobileView);
  const dispatch = useAppDispatch();

  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);
  const { alerting } = useAlerting();

  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const {
    networkDisplayName,
    chainCode,
    assetList,
    chainId,
    chainType,
    value,
    setValue,
    isDisabled,
  } = props;

  const tokenArr = assetList.map(({ assetName }) => assetName);

  const onConfirm = async () => {
    const res = await APIs.addressManagement.asset.updateClientSupportedAsset({
      chainId,
      chainType,
      assetNameList: value,
    });

    if (!res) {
      if (clientSupportedAssetChainCodeMapping[chainCode]?.assetNameList?.length) {
        setValue(clientSupportedAssetChainCodeMapping[chainCode].assetNameList);
      }
      return;
    }

    alerting(
      'success',
      tc('update_success_message', {
        itemName: t(TRANSLATE_KEY.tokenList),
      })
    );

    dispatch(
      upsertClientSupportedAssetChainCodeMapping({
        chainCode,
        chainId,
        chainType,
        assetNameList: value,
      })
    );
  };

  const getIsConfirmBtnDisabled = () => {
    const dbAssetList = clientSupportedAssetChainCodeMapping[chainCode]?.assetNameList || [];
    const selectedAssetList = value;

    return (
      dbAssetList.every((assetName) => selectedAssetList.includes(assetName)) &&
      selectedAssetList.length === dbAssetList.length
    );
  };

  const isConfirmBtnDisabled = getIsConfirmBtnDisabled();

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        gap: '28px',
      }}
    >
      <div
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          width: '12%',
          minWidth: '60px',
        }}
      >
        {networkDisplayName}
      </div>
      <GeneralSelection
        hasClearAllButton={!isDisabled}
        label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.token) })}
        mode={'multiple'}
        value={value}
        onChange={setValue}
        enumData={tokenArr.reduce(
          (acc: Record<string, string>, cur) => ({ ...acc, [cur]: cur }),
          {}
        )}
        nameFn={(name) => name}
        isNoSorting
        disabledItemList={isDisabled ? tokenArr : undefined}
      />
      {!isDisabled && (
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <CustomColorButton
            sx={{
              height: '32px',
              width: isMobile ? '75px' : '135px',
              fontSize: '14px',
            }}
            onClick={onConfirm}
            color={{ background: '#ffffff', text: '#000000' }}
            node={tc('confirm')}
            variant={'contained'}
            disabled={isConfirmBtnDisabled}
          />
        </div>
      )}
    </div>
  );
}

export default AssetSelectionRow;

import {} from '@wallet-manager/node-types';

import APIs from '../../../../api';
import { RequestType } from '../../../../api/auth';
import { useAlerting } from '../../../../hooks';
import useLoading from '../../../../hooks/useLoading';
import useTranslation from '../../../../hooks/useTranslation';
import { updateVerificationToken } from '../../../../utils';

interface IRequestEmailVerificationCodeProps {
  email: string;
  clientId: string;
  merchantId: string;
  requestType: RequestType;
}

// zh-CN not working
const EnumLocaleMapping: any = {
  en: 'en',
  zh_cn: 'zh-TW',
};

const useEmailCodeApi = () => {
  const { showLoading, hideLoading } = useLoading();
  const { alerting } = useAlerting();

  const { currentLanguage } = useTranslation();

  const requestEmailVerificationCode = async ({
    email,
    clientId,
    merchantId,
    requestType,
  }: IRequestEmailVerificationCodeProps) => {
    // [API Call] request temp token to gain access for request email code
    // showLoading('requestTempToken');
    const requestVerifyTokenRes = await APIs.auth.requestVerifyToken({
      email,
      clientId,
      merchantId,
      requestType,
    });

    // hideLoading('requestTempToken');

    if (!requestVerifyTokenRes) return null;

    const verifyToken = requestVerifyTokenRes.token;
    updateVerificationToken(verifyToken);

    const lang = EnumLocaleMapping[currentLanguage];
    // [API Call] request to send email code
    // showLoading('requestEmailVerificationCode');
    const emailCodeRes = await APIs.auth.requestEmailVerificationCode({
      lang,
    });
    // hideLoading('requestEmailVerificationCode');

    if (!emailCodeRes) return null;

    return emailCodeRes;
  };

  const verifyEmailVerificationCode = async (code: string) => {
    // showLoading('verifyEmailVerificationCode');
    const res = await APIs.auth.verifyEmailVerificationCode({
      code,
    });
    // hideLoading('verifyEmailVerificationCode');

    if (!res) return;

    if (!res.verifyResult) return alerting('warning', 'Incorrect Email Verification Code');

    const verifyToken = res.token;

    updateVerificationToken(verifyToken);

    return res;
  };

  return { requestEmailVerificationCode, verifyEmailVerificationCode };
};

export default useEmailCodeApi;

import { uniqueId } from 'lodash';
import { ReactNode } from 'react';

import { usePermission } from '../../../../hooks';

type TabConfig = {
  isSelected: boolean;
  node: ReactNode;
  onClick: () => void;
  width: string;
  permission?: string;
};

function TabSlider({ tabConfigArr }: { tabConfigArr: TabConfig[] }) {
  const { hasPermission } = usePermission();

  const selectedTab = tabConfigArr?.find(({ isSelected }) => isSelected);

  const notRequirePermissionOrHasPermissionTabArr = tabConfigArr.filter(
    ({ permission }) => !permission || hasPermission(permission)
  );

  const getTabIndicatorLeftPosition = () => {
    if (!notRequirePermissionOrHasPermissionTabArr) {
      return;
    }

    let tabIndicatorLeftPosition = '0px';

    for (const { width, isSelected } of notRequirePermissionOrHasPermissionTabArr) {
      if (isSelected) {
        break;
      }

      tabIndicatorLeftPosition = `${tabIndicatorLeftPosition} + ${width}`;
    }

    return tabIndicatorLeftPosition;
  };

  const tabIndicatorLeftPosition = getTabIndicatorLeftPosition();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        borderRadius: '16px',
        padding: '0 4px',
        backgroundColor: '#333333',
        maxWidth: '100%',
        position: 'relative',
        height: '51px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: selectedTab?.width,
          backgroundColor: '#EDA30E',
          borderRadius: '16px',
          height: '41px',
          left: `calc(${tabIndicatorLeftPosition} + 4px)`,
          transition: '300ms cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      />
      {notRequirePermissionOrHasPermissionTabArr.map(
        ({ width, node, isSelected, onClick }, index) => (
          <div
            style={{
              width,
              borderRadius: '16px',
              opacity: isSelected ? 1 : 0.5,
              color: isSelected ? '#000000' : 'inherit',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              lineHeight: 1,
              fontSize: '12px',
              cursor: isSelected ? 'default' : 'pointer',
            }}
            onClick={onClick}
            key={uniqueId(String(index))}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 2,
                cursor: isSelected ? 'default' : 'pointer',
              }}
            >
              {node}
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default TabSlider;

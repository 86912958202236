import useTranslation, { Iprefix } from './useTranslation';

function useVerifyField(translatePrefix: Iprefix) {
  const { t, tc } = useTranslation(translatePrefix);

  const verifyIsNoChangesMade = <T extends Record<string, string>>({
    prevData,
    curData,
  }: {
    prevData: T | undefined;
    curData: T;
  }) => {
    const isNoChangesMade = Object.entries(curData).every(([key, val]) => prevData?.[key] === val);

    if (isNoChangesMade) {
      return tc('no_changes_made_warning');
    }

    return undefined;
  };

  const verifyHasEmptyField = (
    configArr: { value: string; mode: 'selection' | 'input'; fieldTranslateKey: string }[]
  ) => {
    let alertMessage: string | undefined = undefined;

    for (const config of configArr) {
      if (!config.value) {
        alertMessage = tc(config.mode === 'input' ? 'phInputField' : 'phSelection', {
          fieldName: t(config.fieldTranslateKey),
        });
        break;
      }
    }

    return alertMessage;
  };

  return { verifyHasEmptyField, verifyIsNoChangesMade };
}

export default useVerifyField;

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginContext } from '../';
import APIs from '../../../../api';
import { useAlerting, useTranslation } from '../../../../hooks';
import useLoading from '../../../../hooks/useLoading';
import LoginInput from '../components/LoginInput';
import PageWrapper from '../components/PageWrapper';
import useEnterEmailValidation from '../helpers/useEnterEmailValidation';
import { LoginFieldsKeys, PageKeys } from '../types';

interface IProps {}

const EnterEmail = (props: IProps) => {
  const loginContext = useContext(LoginContext);

  const { fields, setFields, setPage, isLoading, merchantId } = loginContext;

  const { alerting } = useAlerting();
  const { validate } = useEnterEmailValidation({ fields });
  const { t } = useTranslation('login');
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('warning', validationResult.allErrors[0]);
      return;
    }

    // showLoading('getLoginStatus');
    const checkUserRes = await APIs.auth.checkUser({
      email: fields[LoginFieldsKeys.Email],
      clientId: fields[LoginFieldsKeys.ClientId],
      merchantId,
    });
    // hideLoading('getLoginStatus');

    if (!checkUserRes) {
      return alerting('warning', t('emailNotExistError'));
    }

    // if (!res.isEmailExist) {
    //   return alerting('error', t('emailNotExistError'));
    // }

    setPage(PageKeys.EmailVerification);

    // return;

    // if (res.isForcePasswordChange) {
    //   setPage(PageKeys.EmailVerification);
    // } else {
    //   if (res.isDeviceRegistered) {
    //     setPage(PageKeys.EnterPassword);
    //   } else {
    //     setPage(PageKeys.EnterPasswordWithVerify);
    //   }
    // }
  };

  return (
    <PageWrapper
      submitBtn={{ wording: 'Next', onSubmit }}
      hintWording={'Please enter your Company Email and Client ID'}
    >
      <div className="login-field">
        <LoginInput
          label={'Company Email'}
          // label={t('enterEmail.email')}
          value={fields[LoginFieldsKeys.Email]}
          onChange={(e) =>
            setFields((fields) => ({ ...fields, [LoginFieldsKeys.Email]: e.target.value }))
          }
          noIcon
          autoComplete="username"
          showEmailKeyboard
          disabled={isLoading}
        />
      </div>
      {/* Only show when url does not have valid programAgentId */}
      <div className="login-field">
        <LoginInput
          label={'Client ID'}
          // label={t('programAgentId')}
          value={fields[LoginFieldsKeys.ClientId]}
          onChange={(e) =>
            setFields((fields) => ({
              ...fields,
              [LoginFieldsKeys.ClientId]: e.target.value,
            }))
          }
          noIcon
          disabled={isLoading}
        />
      </div>
    </PageWrapper>
  );
};

export default EnterEmail;

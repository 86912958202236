export const TRANSLATE_KEY = {
  displayName: 'dailyTab.display_name',
  addressGroup: 'dailyTab.address_group',
  address: 'dailyTab.address',
  network: 'dailyTab.network',
  token: 'dailyTab.token',
  date: 'dailyTab.date',
  openingBalance: 'dailyTab.opening_balance',
  inAmount: 'dailyTab.in_amount',
  outAmount: 'dailyTab.out_amount',
  txFee: 'dailyTab.tx_fee',
  closingBalance: 'dailyTab.closing_balance',
};

export const TRANSLATE_PREFIX = 'report';

export const OMIT_KEYS = { export: ['rawData'], table: [] };

export const EXPORT_FILE_NAME = 'daily_report';

import { ReactNode } from 'react';

import { ReactComponent as InfoIcon } from '../assets/icons/AddressListOperation/info-circle.svg';
import { importantStyle } from '../utils';
import { Box } from './MuiGenerals';

function FieldWithLabel({
  label,
  fieldComponent,
  infoNode,
}: {
  label: ReactNode;
  fieldComponent: ReactNode;
  infoNode?: ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        '.MuiTextField-root': {
          '.MuiInputLabel-root': {
            left: '12px',
          },
          '.MuiInputBase-root': { input: { padding: importantStyle('12.5px 20px') } },
        },
      }}
    >
      <div>{label}</div>
      {fieldComponent}
      {infoNode && (
        <div
          style={{
            marginTop: '2px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            lineHeight: 'initial',
            fontSize: '10px',
          }}
        >
          <InfoIcon />
          {infoNode}
        </div>
      )}
    </Box>
  );
}

export default FieldWithLabel;

import { CircularProgress } from '@mui/material';
import Stack from '@mui/material/Stack';

import { useTranslation } from '../../hooks';
import { COLOR_THEME } from '../../style/colorTheme';
import { hexToRGB } from '../../utils';

export default function NoRowsOverlay({
  isLoading = false,
  customWording,
}: {
  isLoading?: boolean;
  customWording?: string;
}) {
  const { t } = useTranslation('component');

  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: `rgba(${hexToRGB(COLOR_THEME.MUI.MuiDataGrid.Row.Background)}, 1)`,
        color: COLOR_THEME.Layout.NoRowsOverlay.Text,
      }}
    >
      {isLoading ? (
        <CircularProgress
          sx={{
            color: `${COLOR_THEME.Loading.CircularProgress}`,
          }}
        />
      ) : (
        customWording || t('noRecords')
      )}
    </Stack>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { changePassword } from '../../../api/portalUserProfiles';
import { Box, Button, Container } from '../../../components/MuiGenerals';
import { useAlerting, useTranslation } from '../../../hooks';
import useLoading from '../../../hooks/useLoading';
import { ErrorInfo } from '../../../hooks/useValidation/types';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import { customSx } from '../../../utils/styling';
import { useZusTranslatePrefixStore } from '../../../zustand/store';
import PasswordField from './components/PasswordField';
import useChangePasswordValidation from './useChangePasswordValidation';

interface IProps {}

const ChangePasswordPage: React.FC<IProps> = (props) => {
  const translatePrefix = 'changePassword';
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isValidPasswordLength, setIsValidPasswordLength] = useState(false);
  const [isValidPasswordNumber, setIsValidPasswordNumber] = useState(false);
  const [isValidPasswordSymbol, setIsValidPasswordSymbol] = useState(false);
  const [isValidUpperCase, setIsValidUpperCase] = useState(false);

  const { t, tc } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const redirect = useNavigate();
  const isMobile = useAppSelector(selectIsMobileView);

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  const { validate } = useChangePasswordValidation({
    fields: {
      currentPassword,
      newPassword,
      confirmNewPassword,
    },
  });
  const { showLoading, hideLoading } = useLoading();

  const isFulfilPasswordLengthCheck = () => {
    const validationResult = validate();
    if (
      validationResult.validationResult.newPassword.errorInfo.some(
        (error: ErrorInfo) => error.validatorType === 'lengthLimit'
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isFulfilPasswordNumberCheck = () => {
    const validationResult = validate();
    if (
      validationResult.validationResult.newPassword.errorInfo.some(
        (error: ErrorInfo) => error.validatorType === 'requiredNumberAmount'
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isFulfilPasswordSymbolCheck = () => {
    const validationResult = validate();
    if (
      validationResult.validationResult.newPassword.errorInfo.some(
        (error: ErrorInfo) => error.validatorType === 'requiredSymbol'
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isFulfilUpperCaseCheck = () => {
    const validationResult = validate();
    if (
      validationResult.validationResult.newPassword.errorInfo.some(
        (error: ErrorInfo) => error.validatorType === 'requiredUpperCaseLetter'
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setIsValidPasswordLength(isFulfilPasswordLengthCheck());
    setIsValidPasswordNumber(isFulfilPasswordNumberCheck());
    setIsValidPasswordSymbol(isFulfilPasswordSymbolCheck());
    setIsValidUpperCase(isFulfilUpperCaseCheck());
  }, [newPassword]);

  async function onSubmit() {
    const validationResult = validate();
    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }
    showLoading('changePassword');
    const res = await changePassword({
      oldPassword: currentPassword,
      password: newPassword,
    });
    hideLoading('changePassword');

    if (!res) {
      return;
    }

    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    redirect('/accountSetting');
    alerting('success', t('changedSuccess'));
  }

  return (
    <Box sx={{ ...customSx.layoutBox, marginBottom: '10px', paddingLeft: 0, paddingRight: 0 }}>
      <Container disableGutters maxWidth={false}>
        <div
          style={{
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          }}
        >
          {t('changePassword')}
        </div>
        <div
          style={{
            borderBottom: '1px solid #44506E',
            marginBottom: '1rem',
            marginTop: '1rem',
          }}
        ></div>
        <div
          style={{
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              ...(!isMobile && { width: '50%' }),
              ...(isMobile && { maxWidth: '600px' }),
            }}
          >
            <PasswordField
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              label={t('currentPassword')}
              placeholder={tc('phInputField', { fieldName: t('currentPassword') })}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'flex-end',
              }}
            >
              <PasswordField
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                label={t('newPassword')}
                placeholder={tc('phInputField', { fieldName: t('newPassword') })}
              />
              <div
                style={{
                  fontSize: '0.7rem',
                  color: '#8B95A3',
                  marginTop: '0.2rem',
                  textAlign: 'left',
                  width: isMobile ? '98%' : '60%',
                }}
              >
                <div>
                  <span style={{ color: isValidPasswordLength ? 'green' : '#838DA4' }}>
                    {t('characterLimit')}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: '0.2rem',
                  }}
                >
                  <span style={{ color: isValidUpperCase ? 'green' : '#838DA4' }}>
                    {t('requiredUpperCase')}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: '0.2rem',
                  }}
                >
                  <span style={{ color: isValidPasswordSymbol ? 'green' : '#838DA4' }}>
                    {t('requiredSymbol')}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: '0.2rem',
                  }}
                >
                  <span style={{ color: isValidPasswordNumber ? 'green' : '#838DA4' }}>
                    {t('requiredNumber')}
                  </span>
                </div>
              </div>
            </div>
            <PasswordField
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              label={t('confirmNewPassword')}
              placeholder={tc('phInputField', { fieldName: t('confirmNewPassword') })}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '1rem',
              width: '30%',
            }}
          >
            <Button
              onClick={onSubmit}
              style={{
                marginRight: '1rem',
                flex: 1,
              }}
              variant="contained"
            >
              {t('confirm')}
            </Button>
            <Button
              onClick={() => {
                redirect('/accountSetting');
              }}
              style={{
                flex: 1,
              }}
              variant="contained"
              color="secondary"
            >
              {t('cancel')}
            </Button>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default ChangePasswordPage;

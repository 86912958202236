import { isNumber } from 'lodash';
import { ReactNode, useMemo, useRef, useState } from 'react';

import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { InputAdornment } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { CSSProperties } from '@mui/styled-engine';
import { OverridableComponent } from '@mui/types';

import searchIcon from '../../assets/icons/common/search.png';
import { AllEnums } from '../../helper/getEnumTranslationEntries';
import { useTranslation } from '../../hooks';
import { hexToRGB, importantStyle, sortAlphabetically } from '../../utils';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '../MuiGenerals';
import { MpTextField } from '../TextField';

export type SelectionStyle = {
  formControl?: CSSProperties & {
    label?: CSSProperties;
    '& .MuiInputBase-root'?: CSSProperties & {
      input?: CSSProperties;
      ':focus'?: CSSProperties;

      svg?: CSSProperties;
      '::before'?: CSSProperties;
      '::after'?: CSSProperties;
      '&.Mui-focused'?: CSSProperties & { '& .MuiSelect-select'?: CSSProperties };
      '& .MuiSelect-select'?: CSSProperties & {
        div?: CSSProperties;
        '&.Mui-disabled'?: CSSProperties;
      };
    };
  };
  select?: CSSProperties;
};

const focusedBgColor = importantStyle('#111111');

export default function GeneralSelection({
  enumTranslatePrefix = 'enumConstants',
  ...p
}: {
  label?: string;
  onOpen?: () => void;
  enumData: Record<string, any>;
  enumTranslatePrefix?: AllEnums;
  arrayFn?: (array: string[][]) => string[][];
  nameFn?: (str: string) => string;
  displayNodeFn?: (str: string) => { displayNode: ReactNode; searchTarget: string | string[] };

  sx?: SelectionStyle;
  isNoSorting?: boolean;
  disabled?: boolean;
  disabledItemList?: string[];
  isGroupingDisabledItems?: boolean;
  className?: string;
  arrowIcon?: OverridableComponent<SvgIconTypeMap>;
  searchMode?: 'exact' | 'partial' | 'includes';
} & (
  | {
      mode: 'single';
      value: string;
      onChange: (value: string) => void;
      clearSelect: () => void;
    }
  | {
      mode: 'multiple';
      value: string[];
      onChange: (arr: string[]) => void;
      hasClearAllButton?: boolean;
    }
)) {
  function isSelfFn<T>(arr: T): T {
    return arr;
  }
  const { te, t } = useTranslation('component');
  const selectionRef = useRef<HTMLDivElement>(null);

  const [searchInput, setSearchInput] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const displaySelectionArr = useMemo(() => {
    const defaultArrayFn = p.arrayFn ? p.arrayFn : isSelfFn;

    const enumObjEntries = Object.entries(p.enumData);

    const hasNumberTypeValue = enumObjEntries.some(([_key, value]) => isNumber(value));

    const hasObjectTypeValue = enumObjEntries.some(([_key, value]) => typeof value === 'object');

    const displayEnumArr = hasNumberTypeValue
      ? enumObjEntries
          .filter(([_key, value]) => isNumber(value))
          .map(([key, value]) => [key, String(value)])
      : hasObjectTypeValue
      ? enumObjEntries.map(([key]) => [key, key])
      : enumObjEntries;

    const transformedArray = defaultArrayFn(
      p.isNoSorting ? displayEnumArr : sortAlphabetically(displayEnumArr)
    );

    const selectionArray = p.isGroupingDisabledItems
      ? [
          ...transformedArray.filter(([name]) => !p.disabledItemList?.includes(name)),
          ...transformedArray.filter(
            ([name]) => p.disabledItemList && p.disabledItemList.includes(name)
          ),
        ]
      : transformedArray;

    return selectionArray.filter(([name]) => {
      const searchTarget = p.displayNodeFn
        ? p.displayNodeFn(name).searchTarget
        : p.nameFn
        ? p.nameFn(name)
        : te(enumTranslatePrefix, name);

      const searchInputInLowerCase = searchInput.toLowerCase();

      const comparisonFn =
        p.searchMode === 'partial'
          ? (searchTarget: string) =>
              searchTarget.toLowerCase().substring(0, searchInputInLowerCase.length) ===
              searchInputInLowerCase
          : p.searchMode === 'includes'
          ? (searchTarget: string) => searchTarget.toLowerCase().includes(searchInputInLowerCase)
          : (searchTarget: string) =>
              !searchInputInLowerCase.length ||
              searchTarget.toLowerCase() === searchInputInLowerCase;

      const isSearchResult = Array.isArray(searchTarget)
        ? searchTarget.some((target) => comparisonFn(target))
        : comparisonFn(searchTarget);
      return isSearchResult;
    });
  }, [searchInput, te, enumTranslatePrefix, p]);

  const blurredBgColor = importantStyle(
    p.sx?.formControl?.['& .MuiInputBase-root']?.backgroundColor || '#333333'
  );

  const sx: SelectionStyle = {
    formControl: {
      width: '100%',
      borderRadius: '20px',
      ...p.sx?.formControl,

      label: { left: '12px', ...p.sx?.formControl?.label },
      '& .MuiInputBase-root': {
        backgroundColor: blurredBgColor,
        ':focus': {
          backgroundColor: blurredBgColor,
        },
        '::before': { content: 'none' },
        '::after': { content: 'none' },
        borderRadius: 'inherit',
        // input: { overflow: importantStyle('hidden') },
        border: 'rgba(255, 255, 255, 0.2) 1px solid',

        ...p.sx?.formControl?.['& .MuiInputBase-root'],

        svg: {
          right: '16px',
          opacity: p.disabled ? 0.4 : undefined,
          ...p.sx?.formControl?.['& .MuiInputBase-root']?.svg,
        },

        '&.Mui-focused': {
          backgroundColor: focusedBgColor,
          borderRadius: '6px 6px 0 0',
          '& .MuiSelect-select': {
            ...p.sx?.formControl?.['& .MuiInputBase-root']?.['&.Mui-focused']?.[
              '& .MuiSelect-select'
            ],
          },
          ...p.sx?.formControl?.['& .MuiInputBase-root']?.['&.Mui-focused'],
        },
        '& .MuiSelect-select': {
          borderRadius: 'inherit',
          backgroundColor: 'inherit',
          padding: '12.5px 48px 12.5px 20px',
          div: {
            padding: importantStyle(0),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
          ...p.sx?.formControl?.['& .MuiInputBase-root']?.['& .MuiSelect-select'],
          '&.Mui-disabled': {
            color: `rgba(${hexToRGB('#838da4')}, 0.6)`,
            WebkitTextFillColor: `rgba(${hexToRGB('#838da4')}, 0.6)`,
            ...p.sx?.formControl?.['& .MuiInputBase-root']?.['& .MuiSelect-select']?.[
              '&.Mui-disabled'
            ],
          },
        },
      },
    },
    select: {},
  };

  const onChangeMultiSelect = (e: SelectChangeEvent<any>) => {
    if (p.mode !== 'multiple') {
      return;
    }

    const result = [...(Array.isArray(e.target.value) ? e.target.value : [e.target.value])];

    p.onChange(result);
  };

  const onChangeSingleSelect = (e: SelectChangeEvent<any>) => {
    if (p.mode !== 'single') {
      return;
    }

    p.onChange(e.target.value);
  };

  const onClose = () => {
    setIsOpen(false);
    const focusedDivElement = selectionRef.current?.querySelector('div') as HTMLDivElement;
    setTimeout(() => focusedDivElement.blur(), 150);
  };

  return (
    <FormControl sx={sx.formControl}>
      {p.label && <InputLabel disabled={p.disabled}>{p.label}</InputLabel>}

      <Select
        open={isOpen}
        onSelect={() => setIsOpen(true)}
        autoFocus={false}
        ref={selectionRef}
        IconComponent={p.arrowIcon || ExpandCircleDownOutlinedIcon}
        onClose={onClose}
        name={p.label}
        value={p.value}
        onChange={(p.mode === 'multiple' ? onChangeMultiSelect : onChangeSingleSelect) as any}
        multiple={p.mode === 'multiple'}
        onOpen={() => {
          setIsOpen(true);

          p.onOpen?.();
        }}
        sx={sx.select}
        disabled={p.disabled}
        className={p.className}
        MenuProps={{
          sx: {
            '& .MuiPaper-root': {
              maxHeight: '320px',
              backgroundColor: focusedBgColor,
              transform: isOpen
                ? p.searchMode && selectionRef.current
                  ? importantStyle(`translateY(-${selectionRef.current.clientHeight + 3}px)`)
                  : importantStyle(`translateY(-1px)`)
                : undefined,
              // borderRadius: 'initial',
              border: 'rgba(255, 255, 255, 0.2) 1px solid',
              borderRadius: p.searchMode ? '6px' : '0 0 6px 6px',
              borderTop: p.searchMode ? undefined : 0,
            },
            '& .MuiList-root': {
              backgroundColor: focusedBgColor,

              'li:first-of-type': {
                borderTopLeftRadius: p.searchMode ? '5px' : undefined,
                borderTopRightRadius: p.searchMode ? '5px' : undefined,
              },
              'li:last-of-type': {
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              padding: 0,
              '& .MuiMenuItem-root': {
                backgroundColor: 'inherit',
              },
            },
          },
          disableAutoFocusItem: true,
        }}
      >
        {p.searchMode && (
          <ListSubheader
            sx={{
              lineHeight: 'initial',
              backgroundColor: focusedBgColor,
              ':hover': {
                backgroundColor: focusedBgColor,
                '.MuiInputLabel-root': {
                  color: '#ffffff',
                },
              },
              cursor: 'default',
              padding: '16px 16px 4px',
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <MpTextField
              sx={{
                width: '100%',
                margin: '0px',
              }}
              InputProps={{
                sx: {
                  height: '32px',
                  borderRadius: importantStyle(0),
                  color: '#ffffff',
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    children={
                      <img
                        src={searchIcon}
                        alt="search"
                        style={{ userSelect: 'none', paddingRight: '10px' }}
                      />
                    }
                  />
                ),
              }}
              InputLabelProps={{ sx: { transform: 'translate(12px, 7.2px) scale(1)' } }}
              label={'Type to Search'}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </ListSubheader>
        )}

        {p.mode === 'multiple' && p.hasClearAllButton && (
          <ListSubheader
            sx={{
              lineHeight: 'initial',
              height: 'fit-content',
              padding: 0,
              backgroundColor: focusedBgColor,
            }}
          >
            <Button
              sx={{
                borderRadius: 'initial',
                width: '100%',
                color: 'inherit',
                justifyContent: 'flex-start',
                padding: '6px 16px',
                textTransform: 'initial',
                lineHeight: '1.5',
                fontSize: '0.7857142857142857rem',
                backgroundColor: 'inherit',
                ':hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
              }}
              onClick={() => {
                p.onChange([]);
                onClose();
              }}
            >
              {t('clear_all')}
            </Button>
          </ListSubheader>
        )}

        {displaySelectionArr.map(([name, value], i) => {
          const isDisabledItem = p.disabledItemList?.includes(name);
          return (
            <MenuItem
              key={i}
              value={value}
              onClick={p.mode === 'single' ? p.clearSelect : undefined}
              className={p.className}
              disabled={isDisabledItem}
            >
              {p.displayNodeFn
                ? p.displayNodeFn(name).displayNode
                : p.nameFn
                ? p.nameFn(name)
                : te(enumTranslatePrefix, name)}
            </MenuItem>
          );
        })}
        {searchInput && !displaySelectionArr.length && (
          <MenuItem disabled style={{ opacity: 1, fontSize: '0.8rem' }}>
            {t('no_matching_result')}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

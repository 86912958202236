import en from '../components/i18nProvider/en';
import zh_cn from '../components/i18nProvider/zh_cn';

export type AllEnums =
  | 'enumConstants'
  | 'enumRiskLevel'
  | 'enumMathComparison'
  | 'enumDirection'
  | 'enumTransferTransactionStatus'
  | 'enumImportAddressStatus';
type SingleEnumKeys<T extends AllEnums> = keyof (typeof en)[T];

export type AllEnumKeys =
  | SingleEnumKeys<'enumConstants'>
  | SingleEnumKeys<'enumRiskLevel'>
  | SingleEnumKeys<'enumMathComparison'>
  | SingleEnumKeys<'enumDirection'>
  | SingleEnumKeys<'enumTransferTransactionStatus'>
  | SingleEnumKeys<'enumImportAddressStatus'>;

export const enumTranslateKeys: AllEnums[] = [
  'enumConstants',
  'enumRiskLevel',
  'enumMathComparison',
  'enumDirection',
  'enumTransferTransactionStatus',
  'enumImportAddressStatus',
];

export const getEnumTranslationEntries = (translateEnumObj: typeof en | typeof zh_cn) =>
  Object.entries(translateEnumObj).reduce((acc: [string, any][], [key, val]) => {
    if (enumTranslateKeys.includes(key as AllEnums)) {
      return [...acc, ...Object.entries(val)];
    }

    return acc;
  }, []);

import CustomColorButton from '../../../../components/Button/CustomColorButton';
import { useTranslation } from '../../../../hooks';

function ActionButtons({ onConfirm, onCancel }: { onConfirm?: () => void; onCancel: () => void }) {
  const { tc } = useTranslation();
  return (
    <>
      {onConfirm && (
        <CustomColorButton
          sx={{ height: '36px', width: '160px' }}
          variant="contained"
          color={{ background: '#FFD100', text: '#000000' }}
          node={tc('confirm')}
          onClick={onConfirm}
        />
      )}
      <CustomColorButton
        sx={{ height: '36px', width: '160px' }}
        variant="outlined"
        color={{ background: '#333333', text: '#ffffff', border: '#474747' }}
        node={onConfirm ? tc('cancel') : tc('close')}
        onClick={onCancel}
      />
    </>
  );
}

export default ActionButtons;

import React from 'react';

import { useTranslation } from '../../../../hooks';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function ImportCsvButton({
  onFileChange,
}: {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { t } = useTranslation(TRANSLATE_PREFIX);
  return (
    <>
      <label
        htmlFor={'import_address'}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        {t(TRANSLATE_KEY.import)}
      </label>
      <input id={'import_address'} type="file" accept=".csv" hidden onChange={onFileChange} />
    </>
  );
}

export default ImportCsvButton;

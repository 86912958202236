import { CSSProperties } from 'react';

import { SHAPE_OBJ } from '../constants';

export const getAddressGroupIcon = ({
  shape,
  colorCode,
  style,
}: {
  shape: string;
  colorCode: string;
  style?: CSSProperties;
}) => {
  if (!SHAPE_OBJ[shape]) {
    return <></>;
  }

  const { IconFilled, Icon } = SHAPE_OBJ[shape];

  return IconFilled ? (
    <IconFilled
      style={style}
      stroke={shape === 'suitcase' || shape === 'flag' ? colorCode : undefined}
      fill={colorCode}
    />
  ) : (
    <Icon
      style={style}
      stroke={shape === 'suitcase' || shape === 'flag' ? colorCode : undefined}
      fill={colorCode}
    />
  );
};

import { CSSProperties } from 'react';

import { Checkbox } from './MuiGenerals';

function CheckboxWithLabel({
  id,
  label,
  value,
  onChange,
  style,
}: {
  id: string;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  style?: { container?: CSSProperties; checkbox?: CSSProperties; label?: CSSProperties };
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style?.container }}>
      <Checkbox
        id={id}
        checked={value}
        onChange={(_e, value) => onChange(value)}
        sx={{ padding: '4px', ...style?.checkbox }}
      />
      <label htmlFor={id} style={{ lineHeight: 1.2, userSelect: 'none', ...style?.label }}>
        {label}
      </label>
    </div>
  );
}

export default CheckboxWithLabel;

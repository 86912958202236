import { useTranslation } from '../../../../hooks';
import CustomColorButton from '../../../Button/CustomColorButton';
import { TRANSLATE_KEY } from '../constants';

function SearchButton({ onSearch }: { onSearch: () => void }) {
  const { t } = useTranslation('searchBar');

  return (
    <CustomColorButton
      type={'submit'}
      variant={'contained'}
      onClick={(e) => {
        e.preventDefault();
        onSearch();
      }}
      color={{ background: '#ffffff', text: '#000000' }}
      node={t(TRANSLATE_KEY.search)}
      sx={{
        padding: 0,
        height: '32px',
        minWidth: '73px',
        border: '1px solid #474747',
        borderRadius: 'initial',
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
        boxSizing: 'border-box',
      }}
    />
  );
}

export default SearchButton;

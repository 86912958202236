import { useState } from 'react';

import APIs from '../../../../../../api';
import ActionButtons from '../../../../../../common/components/Dialog/components/ActionButtons';
import { DialogInOne } from '../../../../../../components';
import { useAlerting, useTranslation } from '../../../../../../hooks';
import { upsertAddressGroup } from '../../../../../../reducer/addressGroupSlice';
import { useAppDispatch } from '../../../../../../reducer/hooks';
import { TRANSLATE_PREFIX } from '../../../constants';
import { AddressGroupState } from '../../../types';
import Content, { initialAddressGroupData } from '../components/Content';
import { TRANSLATE_KEY } from '../constants';

function DialogCreateNewGroup({
  onClose,
  onRefresh,
}: {
  onClose: () => void;
  onRefresh: () => void;
}) {
  const [addressGroupData, setAddressGroupData] =
    useState<AddressGroupState>(initialAddressGroupData);
  const { alerting } = useAlerting();
  const dispatch = useAppDispatch();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const onConfirm = async () => {
    const { name: walletGroupName, ...rest } = addressGroupData;

    if (!walletGroupName) {
      return alerting('warning', 'Please enter Group Name');
    }

    const icon = JSON.stringify(rest);

    const res = await APIs.addressManagement.addressGroup.createAddressGroup({
      walletGroupName,
      icon,
    });

    if (!res) {
      return;
    }

    alerting('success', tc('create_success_message', { itemName: t(TRANSLATE_KEY.group) }));

    dispatch(upsertAddressGroup({ ...addressGroupData, id: String(res.walletGroupId) }));

    onRefresh();
    onClose();
  };

  return (
    <DialogInOne
      title={t(TRANSLATE_KEY.createNewGroup)}
      self={{ open: true, onClose }}
      content={
        <Content addressGroupData={addressGroupData} setAddressGroupData={setAddressGroupData} />
      }
      isShowCrossButton
      isBlackBackdrop
      isTitleCentered
      dialogWidth={{ width: '512px' }}
      isCancelHidden
      isConfirmHidden
      actionButtons={<ActionButtons onConfirm={onConfirm} onCancel={onClose} />}
      actionBtnContainerStyling={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        padding: '40px 24px 24px',
      }}
      onConfirm={() => {}}
      onCancel={() => {}}
    />
  );
}

export default DialogCreateNewGroup;

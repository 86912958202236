import { useNavigate } from 'react-router';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { OpSettingComponent } from '../../../assets/icons';
import useTranslation from '../../../hooks/useTranslation';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import { selectProfile } from '../../../reducer/profileSlice';
import { COLOR_THEME } from '../../../style/colorTheme';
import { enumMapping } from '../../../utils';
import { enumTimezone } from '../../../utils/constant';
import { LanguageSelection } from '../../../views/User/AccountSetting';
import { OpIconButtonAdjustable } from '../../Button';
import LogoutButton from '../../Button/LogoutButton';

const Enum = {
  timezones: enumMapping(enumTimezone),
};

const sx: Record<string, any> = {
  topRightBar: {
    position: 'absolute',
    zIndex: 99,
    height: '28px',
    top: '1rem',
    right: '.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '& Button.MuiButton-text': {
      fontSize: '1rem',
    },
  },

  email: {
    color: COLOR_THEME.Layout.LayoutBar.Text,
    fontSize: '1rem',
  },
} as const;

function TopRightBar() {
  const { appName, email, timezone, jwtDetails } = useAppSelector(selectProfile);

  const redirect = useNavigate();

  const { te, tc } = useTranslation('menu');
  const isMobileView = useAppSelector(selectIsMobileView);
  const timezoneDisplay = `GMT${te('enumConstants', Enum.timezones[timezone])}`;

  return (
    <Box sx={sx.topRightBar}>
      {!isMobileView && (
        <>
          <LanguageSelection />

          {/* timezone */}
          <Box
            onClick={() => redirect('/accountSetting')}
            sx={{
              padding: '.3rem .7rem',
              backgroundColor: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Background,
              color: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Text,
              borderRadius: '.5rem',
              border: '1px solid #474747',
              cursor: 'pointer',
            }}
          >
            {timezoneDisplay}
          </Box>

          {/* welcome, (email) */}
          <Typography
            variant="caption"
            sx={{
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ marginRight: '4px', opacity: 0.8 }}>{tc('welcome')},</Box>
            <Box sx={sx.email}>{email}</Box>
          </Typography>
        </>
      )}

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...(isMobileView ? { padding: '0 12px' } : {}),
        }}
      >
        {/* Gear Icon */}
        <Box sx={{ marginLeft: '8px', width: '16px', height: '16px' }}>
          <OpIconButtonAdjustable
            title={''}
            size={'1rem'}
            SvgElement={OpSettingComponent}
            onClick={() => {
              redirect('/accountSetting');
            }}
            sxSVG={{
              fill: COLOR_THEME.Layout.LayoutBar.SecondaryText,
              path: {
                fill: COLOR_THEME.Layout.LayoutBar.SecondaryText,
              },
            }}
          />
        </Box>
      </Box>

      {/* Logout button */}
      <LogoutButton isHide={isMobileView} />
    </Box>
  );
}

export default TopRightBar;

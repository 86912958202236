import { useSearchParams } from 'react-router-dom';

function useGetSerachParams<T extends string>(keys: T[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  const values = keys.reduce(
    (acc, key) => ({ ...acc, [key]: searchParams.get(key) || '' }),
    {} as Record<T, string>
  );

  const clearSearchParams = () => {
    keys.forEach((key) => {
      setSearchParams((prev) => {
        prev.delete(key);
        return prev;
      });
    });
  };

  return { searchParams: values, clearSearchParams };
}

export default useGetSerachParams;

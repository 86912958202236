import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowDropDown } from '@mui/icons-material';

import { IportalUserYubiKeyStatus, Itimezone } from '../../api/types';
import { GeneralBtn } from '../../components';
import LogoutButton from '../../components/Button/LogoutButton';
import { Ilocale } from '../../components/i18nProvider';
import { Box, Button, Container } from '../../components/MuiGenerals';
import GeneralSelection, { SelectionStyle } from '../../components/Selection/GeneralSelection';
import { useTranslation } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../reducer/hooks';
import { selectIsMobileView } from '../../reducer/mediaSlice';
import { selectProfile, setAccountSetting } from '../../reducer/profileSlice';
import { COLOR_THEME } from '../../style/colorTheme';
import { enumMapping, importantStyle } from '../../utils';
import { enumLanguage, enumPortalUserYubiKeyStatus, enumTimezone } from '../../utils/constant';
import { customSx } from '../../utils/styling';
import { useZusTranslatePrefixStore } from '../../zustand/store';

const translatePrefix = 'accountSetting';
const Enum = {
  timezones: enumMapping(enumTimezone),
  yubikeyStatus: enumMapping(enumPortalUserYubiKeyStatus),
};

interface Ifields {
  email: string;
  yubikey_status: IportalUserYubiKeyStatus;
  timezone: Itimezone;
  lang: keyof typeof enumLanguage;
}
const initFields = {
  email: '',
  yubikey_status: enumPortalUserYubiKeyStatus.Unbound,
  timezone: enumTimezone.timezone8,
  lang: enumLanguage.en,
} as const;
const sx = {
  Box: {
    display: 'grid',
    gridTemplateColumns: 'minmax(5rem, max-content) minmax(10rem, max-content)',
    lineHeight: '3rem',
    columnGap: '1rem',
    marginBottom: '1rem',
  },
  EmailPasswordBox: {
    display: 'grid',
    gridTemplateColumns: 'minmax(6rem, max-content) minmax(10rem, max-content)',
    lineHeight: '3rem',
    columnGap: '0.5rem',
    marginBottom: '1rem',
  },
};
export default function AccountSetting() {
  const dispatch = useAppDispatch();
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { email, timezone, lang } = useAppSelector(selectProfile);

  const [fields, setFields] = useState<Ifields>({
    ...initFields,
    email,
    timezone,
    lang,
  });

  const [inView, setInView] = useState(true);

  const { t, te, changeLanguage, currentLanguage } = useTranslation(translatePrefix);
  const redirect = useNavigate();

  const isMobile = useAppSelector(selectIsMobileView);

  const onSelectChange = (type: string) => (value: string) => {
    setFields((fields: any) => ({ ...fields, [type]: value }));
  };
  const onSave = async () => {
    // const res = await api.merchantPortal.profile.upsertUserProfile({
    //   lang: fields.lang,
    //   timezone: fields.timezone,
    // });
    // if (!res) return;
    changeLanguage(fields.lang);
    dispatch(setAccountSetting({ lang: fields.lang, timezone: fields.timezone }));
    setInView(true);
  };

  const onCancel = () => {
    setFields({ ...fields, email, timezone, lang });
    setInView(true);
  };

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  useEffect(() => {
    setFields({ ...fields, lang: currentLanguage as keyof typeof enumLanguage });
  }, [currentLanguage]);

  return (
    <>
      <Box sx={{ ...customSx.layoutBox, marginBottom: '10px' }}>
        <Container disableGutters maxWidth={false}>
          <Box sx={sx.EmailPasswordBox}>
            <span>{t('email')}</span>
            <span>{email}</span>
            <span>{t('password')}</span>
            <span>
              <Button
                onClick={() => {
                  redirect('/changePassword');
                }}
                variant="contained"
                color="secondary"
              >
                {t('changePassword')}
              </Button>
            </span>
          </Box>
        </Container>
      </Box>
      <Box sx={customSx.layoutBox}>
        <Container disableGutters maxWidth={false}>
          <Box sx={sx.Box}>
            {/* <span>{t("yubikey_status")}</span>
          <span>{yubikey_status && te(yubikey_status)}</span> */}
            {inView && (
              <>
                <span>{t('timezone')}</span>
                <span>GMT{te('enumConstants', Enum.timezones[timezone])}</span>
                <span>{t('language')}</span>
                <span>{te('enumConstants', currentLanguage)}</span>
              </>
            )}
            {!inView && (
              <>
                <span>{t('timezone')}</span>
                <GeneralSelection
                  mode="single"
                  sx={{ select: { lineHeight: '0.45em' } }}
                  value={fields.timezone}
                  onChange={onSelectChange('timezone')}
                  enumData={enumTimezone}
                  arrayFn={(arr) => arr.sort((a, b) => Number(a[1]) - Number(b[1]))}
                  nameFn={(name) => `GMT${te('enumConstants', name)}`}
                  clearSelect={() => {}}
                />
                <span>{t('language')}</span>
                <GeneralSelection
                  mode="single"
                  sx={{ select: { lineHeight: '0.45em' } }}
                  value={fields.lang}
                  onChange={onSelectChange('lang')}
                  enumData={{ en: 'en' }}
                  // enumData={enumLanguage}
                  clearSelect={() => {}}
                />
              </>
            )}
          </Box>
          {inView && <GeneralBtn onClick={() => setInView(false)} label="edit" />}
          {!inView && <GeneralBtn onClick={onSave} label="confirm" />}
          {!inView && (
            <GeneralBtn
              onClick={onCancel}
              label="cancel"
              color="warning"
              rest={{ style: { marginLeft: '40px' } }}
            />
          )}
        </Container>
      </Box>

      <div style={{ padding: isMobile ? '32px' : '32px 0' }}>
        <LogoutButton />
      </div>
    </>
  );
}

export function LanguageSelection() {
  const profile = useAppSelector(selectProfile);
  const timezone = profile.timezone;
  const dispatch = useAppDispatch();

  const { currentLanguage, changeLanguage } = useTranslation();

  const onSelectChange = (type: string) => async (value: string) => {
    if (type !== 'lang') return;
    const lang = value as Ilocale;

    changeLanguage(lang);
    dispatch(setAccountSetting({ lang, timezone }));
    // setLocale(lang);
  };
  /**
     
    border-radius: .5rem
    background-color: rgba(0,0,0,0)
     */
  const propSx: SelectionStyle = {
    select: {
      backgroundColor: COLOR_THEME.Views.AccountSetting.LanguageSelection.Background,
    },
    formControl: {
      marginTop: 0,
      marginBottom: 0,
      width: 'initial',
      '& .MuiInputBase-root': {
        svg: {
          right: '4px',
        },
        padding: importantStyle(0),
        backgroundColor: importantStyle(
          COLOR_THEME.Views.AccountSetting.LanguageSelection.Background
        ),
        color: COLOR_THEME.Views.AccountSetting.LanguageSelection.Text,
        borderRadius: '.5rem',

        border: '1px solid #474747',
        height: '100%',
        '&.Mui-focused': {
          borderRadius: '.5rem',
        },
        '& .MuiSelect-select': {
          paddingRight: importantStyle('32px'),
          paddingTop: '.3rem',
          paddingBottom: '.3rem',
        },
      },
    },
  };

  return (
    <GeneralSelection
      mode={'single'}
      value={currentLanguage as keyof typeof enumLanguage}
      onChange={onSelectChange('lang')}
      // enumData={enumLanguage}
      enumData={{ en: 'en' }}
      nameFn={(name) => {
        if (name === 'en') return 'English';
        return '繁中';
      }}
      clearSelect={() => {}}
      sx={propSx}
      className={'languageSelection'}
      arrowIcon={ArrowDropDown}
    />
  );
}

import { CSSProperties, ReactNode, useState } from 'react';

import { useAlerting, useTranslation } from '../hooks';
import { COLOR_THEME } from '../style/colorTheme';
import { copyToClipboard } from '../utils';
import { Box, Tooltip } from './MuiGenerals';

interface Iprops {
  text: string;
  iconWithTooltip?: ReactNode;
  iconWithoutTooltip?: ReactNode;
  sx?: CSSProperties;
  noToolTip?: boolean;
}

export default function CopyableText({
  text,
  iconWithTooltip,
  iconWithoutTooltip,
  sx,
  noToolTip,
}: Iprops) {
  const { tc } = useTranslation();
  const { alerting } = useAlerting();
  const handleCopyText = () => {
    copyToClipboard(text);
    alerting('success', tc('copied'));
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <Tooltip
      title={!noToolTip && text}
      open={isTooltipOpen}
      onClose={() => {
        setIsTooltipOpen(false);
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '2px',
          alignItems: 'center',
          cursor: 'pointer',
          maxWidth: '100%',
        }}
        onClick={handleCopyText}
      >
        <Box
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: COLOR_THEME.CustomSx.ComponentHelper.AddressCell.Text,
            textDecoration: 'underline',
            ...sx,
          }}
          onMouseOver={(e) => {
            setIsTooltipOpen(true);
          }}
        >
          {text}
        </Box>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onMouseOver={(e) => {
            setIsTooltipOpen(true);
          }}
        >
          {iconWithTooltip}
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onMouseOver={(e) => {
            setIsTooltipOpen(false);
          }}
        >
          {iconWithoutTooltip}
        </div>
      </div>
    </Tooltip>
  );
}

export type ApiParamsState = {
  walletAddress: string;
  walletName: string;
  walletGroupIdList: string[];
  assetNameList: string[];
  chainId: string;
  chainType: string;
  balance: string;
  excludeZero: boolean;
  balanceOperator: string;
  settlementDateFrom: string;
  settlementDateTo: string;
};

export type FilterFieldsState = {
  // chainCodes: string[];
  chainCode: string;
  tokens: string[];
  displayName: string;
  address: string;
  addressGroupNames: string[];
  assetBalanceMode: string;
  assetBalanceAmount: string;
  assetBalanceExcludeZero: boolean;
  settlementDateFrom: string;
  settlementDateTo: string;
};

export enum EnumBalanceOperator {
  EqualTo = 'eq',
  LessThan = 'lt',
  LessThanOrEqualTo = 'lte',
  GreaterThan = 'gt',
  GreaterThanOrEqualTo = 'gte',
}

export type SearchParamsField = {
  walletAddress: string;
  walletGroupIdList: string[];
};

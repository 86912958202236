import ActionButtons from '../../../../../../common/components/Dialog/components/ActionButtons';
import { DialogInOne } from '../../../../../../components';
import { useTranslation } from '../../../../../../hooks';
import { TRANSLATE_PREFIX } from '../../../constants';
import { ImportAddressLog } from '../../../hooks/useImportCsv';
import { TRANSLATE_KEY } from '../constants';
import ImportLogTable from './components/ImportLogTable';

function DialogImportAddressLog({
  onClose,
  data,
}: {
  onClose: () => void;
  data: ImportAddressLog[];
}) {
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  return (
    <DialogInOne
      title={t(TRANSLATE_KEY.importAddressResult)}
      self={{ open: true, onClose }}
      content={<ImportLogTable data={data} />}
      isShowCrossButton
      isBlackBackdrop
      isTitleCentered
      dialogWidth={{ width: '80%' }}
      isCancelHidden
      isConfirmHidden
      actionButtons={<ActionButtons onCancel={onClose} />}
      actionBtnContainerStyling={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        padding: '40px 24px 24px',
      }}
      onConfirm={() => {}}
      onCancel={() => {}}
    />
  );
}

export default DialogImportAddressLog;

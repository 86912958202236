import searchIcon from '../../../../assets/icons/common/search.png';

function SearchIcon() {
  return (
    <div
      style={{
        backgroundColor: '#000000',
        width: 'fit-content',
        height: '32px',
        paddingLeft: '12px',
        border: '1px solid #474747',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
        boxSizing: 'border-box',
        borderRightWidth: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
      }}
    >
      <img src={searchIcon} alt="search" />
    </div>
  );
}

export default SearchIcon;

import { ReportCustomPeriodicTabGetAllRes } from '../../../../../api/report/report/types';
import { useListMappingTransform, useTranslation } from '../../../../../hooks';
import useGetNetworkInfo from '../../../../../hooks/useGetNetworkInfo';
import { selectAddressGroup } from '../../../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../../../reducer/hooks';
import { amountDivideDecimals } from '../../../../../utils';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

export const useTransformData = () => {
  const { t, tc, tb } = useTranslation('enumConstants');

  const { getNetworkDisplayName } = useGetNetworkInfo();
  const { addressGroupIdMapping } = useAppSelector(selectAddressGroup);

  const mappingFn = (_mode: 'table' | 'export') => (item: ReportCustomPeriodicTabGetAllRes) => {
    const toDisplayAmount = (amount: string) =>
      amountDivideDecimals(amount, item['asset.decimals']);
    const mapping: Array<[string, unknown]> = [
      ['rawData', item],
      [TRANSLATE_KEY.displayName, item['inspectionWallet.walletName']],
      [
        TRANSLATE_KEY.addressGroup,
        addressGroupIdMapping[item['inspectionWallet.walletGroupId']].name,
      ],
      [TRANSLATE_KEY.address, item.walletAddress],
      [
        TRANSLATE_KEY.network,
        getNetworkDisplayName({ chainId: item.chainId, chainType: item.chainType }),
      ],
      [TRANSLATE_KEY.token, item.assetName],
      [TRANSLATE_KEY.openingBalance, toDisplayAmount(item.openingBalance)],
      [TRANSLATE_KEY.inAmount, toDisplayAmount(item.inAmount)],
      [TRANSLATE_KEY.outAmount, toDisplayAmount(item.outAmount)],
      [TRANSLATE_KEY.txFee, toDisplayAmount(item.transFee)],
      [TRANSLATE_KEY.closingBalance, toDisplayAmount(item.closingBalance)],
    ];

    return mapping;
  };

  const transformData = useListMappingTransform({
    translatePrefix: TRANSLATE_PREFIX,
    mappingFn,
  });

  return { transformData };
};
